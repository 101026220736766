<template>
  <v-container>
    <h2 class="ml-1 mb-3">{{ $t('room.menuLabel') }}</h2>

    <v-row>
      <v-col class="mb-3 text-center text-md-left">
        <v-btn small color="primary" dark :to="{ name: 'customer-room-create' }">
          {{ $t('room.addLabel') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="table.headers"
      :items="filteredRooms"
      loading-text="Загружаем список"
      no-data-text="Записи не найдены"
      sort-by="name"
      calculate-widths
      :loading="loading"
      :hide-default-footer="filteredRooms.length <= 5"
      :footer-props="{
        itemsPerPageText: 'Записей на страницу',
        itemsPerPageAllText: 'Все',
      }"
    >
      <template v-slot:item.name="{ item }">
        <v-chip x-small :color="item.color" class="mr-2" />
        {{ item.name }}
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <v-btn
            text
            small
            color="primary"
            :to="{ name: 'customer-room-edit', params: { id: item.id } }"
          >
            <v-icon small class="mr-2">fas fa-pencil-alt</v-icon>

            <template v-if="!$vuetify.breakpoint.mobile">Изменить</template>
          </v-btn>

          <v-btn text small color="red lighten-3" @click="showDeletionDialog(item)">
            <v-icon small class="mr-1">fas fa-trash-alt</v-icon>

            <template v-if="!$vuetify.breakpoint.mobile">Удалить</template>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-toolbar color="warning lighten-2" class="mb-3 text--secondary" flat>
          <v-toolbar-title>Удалить "{{ deletingRoom.name }}"?</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <p>Данное действие нельзя будет отменить.</p>
          <p>Данные о бронированиях будут сохранены.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="blue darken-1" small text @click="deleteDialog = false"> Закрыть</v-btn>

          <v-spacer></v-spacer>

          <v-btn color="red" small dark @click="deleteRoom(deletingRoom.id)">Удалить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SystemMessageService from '@/packages/system-message/services/system-message.service';
import RoomService from '@/packages/room/services/room.service';
import PlaceService from '@/packages/place/services/place.service';

export default {
  name: 'RoomList',

  data() {
    return {
      places: {},
      rooms: [],

      loading: false,

      table: {
        headers: [
          {
            text: 'Название',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: this.$t('place.label'),
            align: 'start',
            sortable: true,
            value: 'placeName',
          },
          {
            text: 'Посетители',
            align: 'start',
            sortable: true,
            value: 'visitors',
          },
          {
            text: 'Действия',
            align: 'end',
            sortable: false,
            value: 'actions',
          },
        ],
      },

      deleteDialog: false,
      deletingRoom: {},
    };
  },

  computed: {
    filteredRooms() {
      return this.rooms.map((room) => {
        return {
          id: room.id,
          name: room.name,
          color: room.calendar.backgroundColor,
          placeId: room.placeId,
          placeName: this.places[room.placeId].name,
          visitors: room.visitors,
        };
      });
    },
  },

  async mounted() {
    try {
      this.loading = true;
      await this.loadPlaces();
      await this.loadRooms();
    } finally {
      this.loading = false;
    }
  },

  methods: {
    async loadRooms() {
      try {
        this.rooms = await RoomService.fetchRooms();
      } catch (e) {
        SystemMessageService.addNotify(`Ошибка получения данных: ${e}`, 'error');
      }
    },

    async loadPlaces() {
      try {
        const places = await PlaceService.fetchPlaces();

        const placesMap = {};
        places.forEach((place) => {
          placesMap[place.id] = place;
        });

        this.places = placesMap;
      } catch (e) {
        SystemMessageService.addNotify(`Ошибка получения данных: ${e}`, 'error');
      }
    },

    getPlaceName(placeId) {
      if (!this.places[placeId]) {
        return null;
      }

      return this.places[placeId].name;
    },

    showDeletionDialog(room) {
      this.deletingRoom = room;
      this.deleteDialog = true;
    },

    deleteRoom(id) {
      RoomService.deleteRoom(id).then(() => {
        const index = this.rooms.findIndex((room) => room.id === id);

        if (index < 0) {
          return;
        }

        const room = this.rooms[index];
        this.rooms.splice(index, 1);

        SystemMessageService.addNotify(
          this.$t('room.successfulDeleted', { name: room.name }),
          'success'
        );

        this.deletingRoom = {};
        this.deleteDialog = false;
      });
    },
  },
};
</script>

<style scoped></style>
