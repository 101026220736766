<template>
  <v-card>
    <v-card-title>Ваше бронирование</v-card-title>

    <template v-if="forOtherUser">
      <v-card-subtitle class="pt-3 pb-1"> Клиент </v-card-subtitle>

      <v-card-text>
        <v-row dense>
          <v-col cols="6"> Имя </v-col>
          <v-col cols="6"> {{ firstName }} {{ lastName }} </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6"> Номер телефона </v-col>
          <v-col cols="6">
            {{ phoneNumber }}
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6"> E-mail </v-col>
          <v-col cols="6">
            {{ email }}
          </v-col>
        </v-row>
      </v-card-text>
    </template>

    <v-card-text class="pb-0">
      <v-row dense>
        <v-col cols="6">
          {{ $t('room.label') }}
        </v-col>
        <v-col cols="6">
          {{ room.name }}
        </v-col>
      </v-row>

      <template v-if="isHourlyType">
        <v-row dense>
          <v-col cols="6"> Дата </v-col>
          <v-col cols="6">
            {{ getHumanDate(dateTimeFrom) }}
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6"> Время </v-col>
          <v-col cols="6">
            {{ getTime(dateTimeFrom) }}
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6"> Длительность </v-col>
          <v-col cols="6">
            {{ durationInMinutes }}
          </v-col>
        </v-row>

        <v-row v-if="needServiceTime && room.settings.serviceTimeValue > 0" dense>
          <v-col cols="6"> Дополнительное время </v-col>
          <v-col cols="6">
            {{ serviceTimeLabel }}
          </v-col>
        </v-row>
      </template>

      <template v-if="isDailyType">
        <v-row dense>
          <v-col cols="6"> Дата </v-col>
          <v-col cols="6">
            {{ getHumanDate(dateTimeFrom) }}
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6"> Длительность </v-col>
          <v-col cols="6"> Целый день </v-col>
        </v-row>
      </template>

      <template v-if="isManyDaysType">
        <v-row dense>
          <v-col cols="6"> Начало </v-col>
          <v-col cols="6">
            {{ getHumanDate(dateTimeFrom) }}
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6"> Окончание </v-col>
          <v-col cols="6">
            {{ getHumanDate(manyDaysDateTo) }}
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6"> Длительность </v-col>
          <v-col cols="6">
            {{ durationInDays }}
          </v-col>
        </v-row>
      </template>

      <v-row v-if="showVisitors" dense>
        <v-col cols="6"> Количество посетителей </v-col>
        <v-col cols="6">
          {{ visitors }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="6"> Стоимость бронирования </v-col>
        <v-col cols="6">
          <template v-if="useUserSpecifiedCost">
            <span class="text-decoration-line-through pr-1">{{ cost }}</span>
            <strong class="red--text">{{ getHumanCost(userSpecifiedCost) }}</strong>
          </template>

          <template v-else>
            {{ getHumanCost(cost) }}
          </template>
        </v-col>
      </v-row>

      <template v-if="canChangePaymentMethod">
        <v-row dense>
          <v-col cols="6"> Метод оплаты </v-col>
          <v-col cols="6">
            {{ paymentMethodLabel }}
          </v-col>
        </v-row>
      </template>

      <template v-if="canChangePaymentStatus">
        <v-row dense>
          <v-col cols="6"> Статус оплаты </v-col>
          <v-col cols="6">
            <template v-if="isOrderPaid"> Оплачено </template>
            <template v-else> Новое </template>
          </v-col>
        </v-row>
      </template>

      <template v-if="shouldShowAvailableTo">
        <v-row dense>
          <v-col cols="6"> Время оплаты </v-col>
          <v-col cols="6">
            {{ getHumanDateTime(availableTo) }}
          </v-col>
        </v-row>
      </template>
    </v-card-text>

    <v-card-text class="pb-2">
      <v-row dense>
        <v-col cols="12">
          <v-switch
            v-model="formData.isConfirmed"
            dense
            hide-details
            inset
            label="Я проверил(а) дату и время бронирования"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          Продолжая бронирование Вы принимаете условия
          <a href="https://lucestudio.ru/lucestudio-OF" target="_blank">договора-оферты</a>.
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-if="timeIsNotAvailable">
      <v-row dense>
        <v-col cols="12">
          <v-alert
            border="left"
            class="mt-5"
            color="warning"
            colored-border
            dense
            elevation="2"
            transition="slide-y-transition"
            type="warning"
          >
            К сожалению, данное время больше недоступно для бронирования
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn color="blue darken-1" small text @click="back"> Изменить </v-btn>

      <v-spacer />

      <v-btn
        :disabled="isCreateButtonDisabled"
        :loading="isLoading"
        color="primary"
        small
        @click="confirm"
      >
        <template v-if="!isEditing"> Забронировать </template>

        <template v-else> Сохранить </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DateHelper from '@/common/helpers/date.helper';
import TimeHelper from '@/common/helpers/time.helper';
import CostHelper from '@/common/helpers/cost.helper';
import CostTypeEnum from '@/packages/order/enums/cost-type.enum';
import StringHelper from '@/common/helpers/string.helper';
import PermissionMixin from '@/packages/permission/mixins/permission.mixin';
import OrderPaymentStatusEnum from '@/packages/order/enums/order-payment-status.enum';
import PaymentMethodEnum from '@/packages/payment/enums/payment-method.enum';
import { DateTime } from 'luxon';

export default {
  name: 'OrderPreview',

  mixins: [PermissionMixin],

  props: {
    room: {
      type: Object,
      required: true,
    },

    costType: {
      type: String,
      required: true,
    },

    dateTimeFrom: {
      type: Object,
      required: true,
    },

    dateTimeTo: {
      type: Object,
      required: true,
    },

    visitors: {
      type: Number,
      required: true,
    },

    needServiceTime: {
      type: Boolean,
      required: true,
    },

    cost: {
      type: Number,
      required: true,
    },

    forOtherUser: {
      type: Boolean,
      required: false,
      default: false,
    },

    phoneNumber: {
      type: String,
      required: false,
      default: '',
    },

    email: {
      type: String,
      required: false,
      default: '',
    },

    firstName: {
      type: String,
      required: false,
      default: '',
    },

    lastName: {
      type: String,
      required: false,
      default: '',
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Object,
      default: () => {},
    },

    order: {
      type: [Object, null],
      required: false,
      default: null,
    },

    paymentMethod: {
      type: String,
      required: false,
      default: PaymentMethodEnum.CARD,
    },

    paymentStatus: {
      type: String,
      required: false,
      default: OrderPaymentStatusEnum.NEW,
    },

    useUserSpecifiedCost: {
      type: Boolean,
      required: false,
      default: false,
    },

    userSpecifiedCost: {
      type: Number,
      required: false,
      default: 0,
    },

    useAvailableTo: {
      type: Boolean,
      required: false,
      default: false,
    },

    availableTo: {
      type: [Object, null],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      formData: {
        isConfirmed: false,
      },
    };
  },

  computed: {
    showVisitors() {
      return this.room.visitors > 1;
    },

    durationInMinutes() {
      const minutes = this.dateTimeTo.diff(this.dateTimeFrom, 'minutes').minutes;

      return TimeHelper.humanTimeFromMinutes(minutes);
    },

    durationInDays() {
      const days = Math.ceil(this.dateTimeTo.diff(this.dateTimeFrom, 'days').days);

      const label = StringHelper.declOfNum(days, ['день', 'дня', 'дней']);

      return `${days} ${label}`;
    },

    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },

    timeIsNotAvailable() {
      return this.hasErrors && Object.prototype.hasOwnProperty.call(this.errors, 'dateTo');
    },

    isCreateButtonDisabled() {
      return !this.formData.isConfirmed || this.hasErrors;
    },

    isHourlyType() {
      return this.costType === CostTypeEnum.TYPE_HOURLY;
    },

    isDailyType() {
      return this.costType === CostTypeEnum.TYPE_DAILY;
    },

    isManyDaysType() {
      return this.costType === CostTypeEnum.TYPE_MANY_DAYS;
    },

    serviceTimeLabel() {
      if (this.room.settings.serviceTimeValue === 60) {
        return '1 час';
      }

      return `${this.room.settings.serviceTimeValue} минут`;
    },

    manyDaysDateTo() {
      if (this.dateTimeTo.hour === 0) {
        return this.dateTimeTo.minus({ days: 1 }).endOf('day');
      }

      return this.dateTimeTo;
    },

    isEditing() {
      return this.order && this.order.id;
    },

    isRoomChanged() {
      return this.isEditing && this.room.id !== this.order.room.id;
    },

    canChangePaymentMethod() {
      return this.isUserCanChangePaymentMethod;
    },

    canChangePaymentStatus() {
      return this.isUserCanChangePaymentStatus;
    },

    isOrderPaid() {
      return this.paymentStatus === OrderPaymentStatusEnum.PAID;
    },

    shouldShowAvailableTo() {
      return (
        this.isUserCanSetAvailableTo &&
        this.useAvailableTo &&
        this.availableTo &&
        this.availableTo.isLuxonDateTime
      );
    },

    paymentMethodLabel() {
      switch (this.paymentMethod) {
        case PaymentMethodEnum.CARD:
          return 'Карта';
        case PaymentMethodEnum.CASH:
          return 'Наличные';
        case PaymentMethodEnum.TRANSFER:
          return 'Перевод';
        default:
          return '';
      }
    },
  },

  methods: {
    getHumanDate(dateTime) {
      return DateHelper.humanDate(dateTime);
    },

    getHumanDateTime(dateTime) {
      return DateHelper.humanDate(dateTime, DateTime.DATETIME_MED);
    },

    getTime(dateTime) {
      return dateTime.toFormat('HH:mm');
    },

    back() {
      this.$emit('back');
    },

    confirm() {
      this.$emit('confirm');
    },

    getHumanCost(cost) {
      return CostHelper.humanCost(cost);
    },
  },
};
</script>

<style scoped></style>
