import StringHelper from '@/common/helpers/string.helper';
import { DateTime } from 'luxon';
import DateTimeConstants from '@/common/constants/datetime.constants';

function padTime(value) {
  return value < 10 ? `0${value}` : value;
}

function parseTime(time) {
  const array = time.split(':');
  const hour = parseInt(array[0], 10);
  const minute = parseInt(array[1], 10);

  return { hour, minute };
}

function timeToInt(time) {
  if (!time) {
    return time;
  }

  return parseInt(time.replace(':', ''), 10);
}

function sortPeriods(periods) {
  const sorted = [...periods];

  sorted.sort((a, b) => {
    if (!a.start || !b.start) {
      return 1;
    }

    const startA = timeToInt(a.start);
    const startB = timeToInt(b.start);

    if (startA < startB) {
      return -1;
    }

    if (startA > startB) {
      return 1;
    }

    return 0;
  });

  return sorted;
}

function timeFromDuration(minutes) {
  const minutesInHour = DateTimeConstants.MINUTES_IN_HOUR;

  const hours = padTime(Math.floor(minutes / minutesInHour));
  const minutesDiff = padTime(minutes - hours * minutesInHour);

  return `${hours}:${minutesDiff}`;
}

function createStartDateTime(time) {
  const startTime = parseTime(time);

  return DateTime.local()
    .set({
      hours: startTime.hour,
      minutes: startTime.minute,
    })
    .startOf('minute');
}

function createEndDateTime(time) {
  const endTime = parseTime(time);

  let end = DateTime.local()
    .set({
      hours: endTime.hour,
      minutes: endTime.minute,
    })
    .startOf('minute');

  if (time === '00:00') {
    end = DateTime.local().plus({ days: 1 }).startOf('day');
  }

  return end;
}

/**
 * @param {{
 *    start: string,
 *    end: string,
 *  }} config
 * @returns {[]}
 */
function generateForTimePicker(config) {
  let startDateTime = createStartDateTime(config.start);
  const endDateTime = createEndDateTime(config.end);

  const times = [];

  do {
    times.push(startDateTime.toFormat('HH:mm'));

    startDateTime = startDateTime.plus({ minutes: DateTimeConstants.MINUTES_IN_STEP });
  } while (startDateTime <= endDateTime);

  return times;
}

function humanTimeFromMinutes(minutes) {
  const hours = Math.floor(minutes / DateTimeConstants.MINUTES_IN_HOUR);
  const minutesDiff = minutes - hours * DateTimeConstants.MINUTES_IN_HOUR;

  const items = [];

  if (hours > 0) {
    items.push(`${hours} ${StringHelper.declOfNum(hours, ['час', 'часа', 'часов'])}`);
  }

  if (minutesDiff > 0) {
    items.push(`${minutesDiff} минут`);
  }

  return items.join(' ');
}

function generateDurations(startTime, endTime, minutesInStep, minimalTime) {
  const startDateTime = createStartDateTime(startTime);
  const endDateTime = createEndDateTime(endTime);

  minutesInStep = minutesInStep || DateTimeConstants.MINUTES_IN_STEP;
  minimalTime = minimalTime || DateTimeConstants.MINUTES_IN_HOUR;

  const ticks = endDateTime.diff(startDateTime, ['minutes']).minutes / minutesInStep;

  const durations = [];

  for (let i = 0; i < ticks; i += 1) {
    const minutes = (i + 1) * minutesInStep;
    if (minutes < minimalTime) {
      // eslint-disable-next-line
      continue;
    }

    durations.push({
      value: minutes,
      label: humanTimeFromMinutes(minutes),
    });
  }

  return durations;
}

/**
 *
 * @param start {DateTime}
 * @param end {DateTime}
 * @param timeStep {number}
 * @returns {string[]}
 */
function generateStartTimeItems(start, end, timeStep) {
  const items = [];

  do {
    items.push(start.toFormat('HH:mm'));

    start = start.plus({ minutes: timeStep });
  } while (start <= end);

  return items;
}

/**
 *
 * @param start {DateTime}
 * @param end {DateTime}
 * @param minimalOrderTime {number}
 * @param orderStepTime {number}
 */
function generateDurationItems(start, end, minimalOrderTime, orderStepTime) {
  const diffInMinutes = end.diff(start, 'minutes').minutes;
  const ticks = Math.ceil(diffInMinutes / orderStepTime);

  const durations = [];

  for (let i = 0; i < ticks; i += 1) {
    const minutes = (i + 1) * orderStepTime;
    if (minutes < minimalOrderTime) {
      // eslint-disable-next-line
      continue;
    }

    if (start.plus({ minutes }) > end) {
      break;
    }

    durations.push({
      value: minutes,
      label: humanTimeFromMinutes(minutes),
    });
  }

  return durations;
}

/**
 * @param dateTime {string}
 * @param isEndTime {boolean}
 * @returns {number}
 */
function dateTimeToInt(dateTime, isEndTime = false) {
  const time = parseInt(dateTime.replace(':', ''), 10);

  if (time === 0 && isEndTime) {
    return 2400;
  }

  return time;
}

export default {
  padTime,
  sortPeriods,
  timeToInt,
  timeFromDuration,
  generateForTimePicker,
  humanTimeFromMinutes,
  parseTime,
  generateDurations,
  generateStartTimeItems,
  generateDurationItems,
  dateTimeToInt,
};
