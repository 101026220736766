import CostTypeEnum from '@/packages/order/enums/cost-type.enum';
import DateTimeConstants from '@/common/constants/datetime.constants';
import TimeHelper from '@/common/helpers/time.helper';

const EVERYDAY_INDEX = 0;

/**
 * @param date {DateTime}
 * @param costGroups {{
 *    costType: string,
 *    day: number,
 *    timeFrom?: string,
 *    timeTo?: string,
 *    values: {
 *      amount: number,
 *      amountType: string,
 *      cost: number,
 *      starts: number
 *    }[]
 * }[]}
 * @returns {{
 *    costType: string,
 *    day: number,
 *    timeFrom?: string,
 *    timeTo?: string,
 *    values: {
 *      amount: number,
 *      amountType: string,
 *      cost: number,
 *      starts: number
 *    }[]
 * } | null}
 */
function searchGroup(date, costGroups) {
  const periodTime = TimeHelper.dateTimeToInt(date.toFormat('Hmm'));

  const foundGroup = costGroups.find((group) => {
    const timeFrom = TimeHelper.dateTimeToInt(group.timeFrom);
    const timeTo = TimeHelper.dateTimeToInt(group.timeTo, true);

    return timeFrom <= periodTime && periodTime < timeTo;
  });

  return foundGroup || null;
}

/**
 *
 * @param dateFrom {DateTime}
 * @param dateTo {DateTime}
 * @param costGroups {{
 *    id: number,
 *    costType: string,
 *    day: number,
 *    timeFrom?: string,
 *    timeTo?: string,
 *    values: {
 *      amount: number,
 *      amountType: string,
 *      cost: number,
 *      starts: number
 *    }[]
 * }[]}
 * @param startTimeStep {number}
 * @returns {number}
 */
function calculateHourlyCost(dateFrom, dateTo, costGroups, startTimeStep) {
  const dayIndex = parseInt(dateFrom.toFormat('c'), 10);
  // 8 - будни, 9 - выходные
  const weekDayIndex = dateFrom.weekday < 6 ? 8 : 9;

  const groupsByDayMap = {};
  const groupsByIdMap = {};

  costGroups.forEach((group) => {
    if (!Array.isArray(groupsByDayMap[group.day])) {
      groupsByDayMap[group.day] = [];
    }

    groupsByDayMap[group.day].push(group);
    groupsByIdMap[group.id] = group;
  });

  const groupTimes = {};

  do {
    let periodCostGroup = null;

    if (Array.isArray(groupsByDayMap[dayIndex])) {
      periodCostGroup = searchGroup(dateFrom, groupsByDayMap[dayIndex]);
    }

    if (periodCostGroup === null && Array.isArray(groupsByDayMap[weekDayIndex])) {
      periodCostGroup = searchGroup(dateFrom, groupsByDayMap[weekDayIndex]);
    }

    if (periodCostGroup === null && Array.isArray(groupsByDayMap[EVERYDAY_INDEX])) {
      periodCostGroup = searchGroup(dateFrom, groupsByDayMap[EVERYDAY_INDEX]);
    }

    if (periodCostGroup && periodCostGroup.values) {
      if (!groupTimes[periodCostGroup.id]) {
        groupTimes[periodCostGroup.id] = 0;
      }

      groupTimes[periodCostGroup.id] += startTimeStep;
    }

    dateFrom = dateFrom.plus({ minutes: startTimeStep });
  } while (dateTo.diff(dateFrom).as('minutes') > 0);

  let cost = 0;

  Object.keys(groupTimes).forEach((groupId) => {
    const group = groupsByIdMap[groupId];
    const minutesInPeriod = groupTimes[groupId];

    const sortedValues = [...group.values].sort((a, b) => a.starts - b.starts);

    let groupCost = 0;

    sortedValues.forEach((value) => {
      if (value.starts <= minutesInPeriod) {
        groupCost = value.cost;
      }
    });

    cost += groupCost * (minutesInPeriod / DateTimeConstants.MINUTES_IN_HOUR);
  });

  return cost;
}

/**
 *
 * @param date {DateTime}
 * @param costGroups {{
 *    costType: string,
 *    day: number,
 *    timeFrom?: string,
 *    timeTo?: string,
 *    values: {
 *      amount: number,
 *      amountType: string,
 *      cost: number,
 *      starts: number
 *    }[]
 * }[]}
 * @returns {number}
 */
function calculateDailyCost(date, costGroups) {
  const costMap = {};
  costGroups.forEach((group) => {
    costMap[group.day] = group.values[0].cost;
  });

  const dayIndex = parseInt(date.toFormat('c'), 10);
  // 8 - будни, 9 - выходные
  const weekDayIndex = date.weekday < 6 ? 8 : 9;

  if (costMap[dayIndex] && Number.isInteger(costMap[dayIndex])) {
    return costMap[dayIndex];
  }

  if (costMap[weekDayIndex] && Number.isInteger(costMap[weekDayIndex])) {
    return costMap[weekDayIndex];
  }

  if (costMap[EVERYDAY_INDEX] && Number.isInteger(costMap[EVERYDAY_INDEX])) {
    return costMap[EVERYDAY_INDEX];
  }

  return 0;
}

/**
 *
 * @param dateFrom {DateTime}
 * @param dateTo {DateTime}
 * @param costValues {{
 *      amount: number,
 *      amountType: string,
 *      cost: number,
 *      starts: number
 *    }[]}
 */
function calculateManyDaysCost(dateFrom, dateTo, costValues) {
  const daysInPeriod = Math.ceil(dateTo.diff(dateFrom, 'days').days);
  const sortedValues = [...costValues].sort((a, b) => a.starts - b.starts);

  let cost = 0;

  sortedValues.forEach((value) => {
    if (value.starts <= daysInPeriod) {
      cost = value.cost * daysInPeriod;
    }
  });

  return cost;
}

/**
 *
 * @param dateFrom {DateTime}
 * @param dateTo {DateTime}
 * @param costGroups {{
 *    costType: string,
 *    day: number,
 *    timeFrom?: string,
 *    timeTo?: string,
 *    values: {
 *      amount: number,
 *      amountType: string,
 *      cost: number,
 *      starts: number
 *    }[]
 * }[]}
 * @param costType {string}
 * @param startTimeStep {number}
 * @returns {number}
 */
function calculateCost(dateFrom, dateTo, costGroups, costType, startTimeStep) {
  const defaultCost = 0;

  if (costType === CostTypeEnum.TYPE_HOURLY) {
    const hourlyCosts = costGroups.filter((group) => group.costType === CostTypeEnum.TYPE_HOURLY);

    if (hourlyCosts.length === 0) {
      return defaultCost;
    }

    return calculateHourlyCost(dateFrom, dateTo, hourlyCosts, startTimeStep);
  }

  if (costType === CostTypeEnum.TYPE_DAILY) {
    const dailyCosts = costGroups.filter((group) => group.costType === CostTypeEnum.TYPE_DAILY);

    if (dailyCosts.length === 0) {
      return defaultCost;
    }

    return calculateDailyCost(dateFrom, dailyCosts);
  }

  if (costType === CostTypeEnum.TYPE_MANY_DAYS) {
    const manyDaysCosts = costGroups.find(
      (group) => group.costType === CostTypeEnum.TYPE_MANY_DAYS
    );

    if (!manyDaysCosts || !manyDaysCosts.values) {
      return defaultCost;
    }

    return calculateManyDaysCost(dateFrom, dateTo, manyDaysCosts.values);
  }

  return defaultCost;
}

export default { calculateCost };
