import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '@/common/views/home.view';

import userProfileRoutes from '@/packages/user/routes';
import placeRoutes from '@/packages/place/routes';
import roomRoutes from '@/packages/room/routes';
import orderRoutes from '@/packages/order/routes';
import verifyEmailRoutes from '@/packages/verify-email/routes';
import userAccountRoutes from '@/packages/user-account/routes';
import PaymentRoutes from '@/packages/payment/routes';
import LocalizationRoutes from '@/packages/localization/routes';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'main',
    component: HomeView,
  },

  ...userProfileRoutes,

  ...placeRoutes,

  ...roomRoutes,

  ...orderRoutes,

  ...verifyEmailRoutes,

  ...userAccountRoutes,

  ...PaymentRoutes,

  ...LocalizationRoutes,

  /*
  {
    path: '/calendar/create',
    name: 'calendar.create',
    component: CalendarCreate,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue'),
  },
  */
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
