<template>
  <room-form
    v-if="room.id"
    :room-id="room.id"
    :place-id="room.placeId"
    :is-active="room.isActive"
    :name="room.name"
    :visitors="room.visitors"
    :costs="room.costs"
    :url="room.url"
    :color="room.calendar.backgroundColor"
    :minimal-order-time="room.settings.minimalOrderTime"
    :order-step-time="room.settings.orderStepTime"
    :allow-daily-order="room.settings.allowDailyOrder"
    :allow-many-days-order="room.settings.allowManyDaysOrder"
    :start-time-step="room.settings.startTimeStep"
    :service-time-enabled="room.settings.serviceTimeEnabled"
    :service-time-value="room.settings.serviceTimeValue"
    :service-time-free="room.settings.serviceTimeFree"
    :picture="room.picture"
  />
</template>

<script>
import RoomForm from '@/packages/room/components/form/room-form';
import RoomService from '@/packages/room/services/room.service';
import SystemMessageService from '@/packages/system-message/services/system-message.service';

export default {
  name: 'RoomEdit',

  components: { RoomForm },

  data() {
    return {
      room: {},
    };
  },

  mounted() {
    this.loadRoom();
  },

  methods: {
    loadRoom() {
      RoomService.fetchRoom(this.$route.params.id)
        .then((response) => {
          this.room = response.room;
        })
        .catch((error) => {
          SystemMessageService.addNotify(`Ошибка получения данных: ${error}`, 'error');
        });
    },
  },
};
</script>

<style scoped></style>
