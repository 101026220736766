import axios from '@/plugins/axios';
import store from '@/store';

/**
 * @param userId
 * @return {Promise<{payments: {}[], balance: {amount: number}}>}
 */
function fetchUserPayments(userId) {
  return axios.get(`/user/${userId}/user-account`).then((response) => response.data);
}

/**
 * @return {Promise<{payments: {}[], balance: {amount: number}}>}
 */
function fetchPayments() {
  return axios.get('/user-account').then((response) => response.data);
}

/**
 * @return {Promise<{amount: number}>}
 */
function fetchBalance() {
  return axios.get('/me/balance').then((response) => {
    return response.data.balance;
  });
}

function storeBalance(balance) {
  store.dispatch('userAccount/setBalance', balance);
}

function isEnabled() {
  return store.getters.isPaymentEnabled;
}

/**
 * @return {Promise<{paymentUrl: string}>}
 * TODO: Перенести в payment
 */
function createPayment(amount, entityType = null, entityId = null) {
  const params = {
    amount,
    fromSite: true,
  };

  if (entityType) {
    params.entityType = entityType;
  }

  if (entityId) {
    params.entityId = entityId;
  }

  return axios.post('/payment', params).then((response) => {
    return response.data;
  });
}

export default {
  fetchBalance,
  fetchPayments,
  storeBalance,
  fetchUserPayments,
  isEnabled,
  createPayment,
};
