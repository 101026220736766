<template>
  <div v-if="isReady">
    <order-filter
      :date-from="dateFrom"
      :date-to="dateTo"
      :search-placeholder="searchPlaceholder"
      @search="(v) => (filter.searchString = v)"
      @change-dates="changeDates"
    />

    <orders-table
      :items="filteredOrders"
      :loading="isLoading"
      :show-user="showUser"
      @pay-order="openPayModal"
      @view-order="$emit('order-view', $event)"
    />

    <payment-modal
      v-if="payOrderState.isActive"
      :order-id="payOrderState.orderId"
      :cost="payOrderState.cost"
      :available-to="payOrderState.availableTo"
      @close="hidePayOrderModal"
      @paid="orderPaid"
    />
  </div>
</template>

<script>
import OrderFilter from '@/packages/order/components/table/filter';
import { DateTime } from 'luxon';
import OrdersTable from '@/packages/order/components/table/table';
import StringHelper from '@/common/helpers/string.helper';
import UserPermissionService from '@/packages/user/services/user-permission.service';
import * as Permissions from '@/packages/user/permissions';
import OrderService from '@/packages/order/services/order.service';
import SystemMessageService from '@/packages/system-message/services/system-message.service';
import OrderStatusEnum from '@/packages/order/enums/order-status.enum';
import PaymentModal from '@/packages/order/components/view/payment-modal';
import PayOrderMixin from '@/packages/order/mixins/pay-order.mixin';
import PaymentStatusEnum from '@/packages/payment/enums/payment-status.enum';
import OrderActionsMixin from '@/packages/order/mixins/order-actions.mixin';
import EventBus from '@/common/lib/event-bus';

export default {
  name: 'OrderTable',

  components: { PaymentModal, OrdersTable, OrderFilter },

  mixins: [PayOrderMixin, OrderActionsMixin],

  props: {
    userId: {
      type: [String, null],
      required: false,
      default: null,
    },

    showUser: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isReady: false,
      isLoading: false,

      dateFrom: '',
      dateTo: '',

      orders: [],

      filter: {
        menu: false,
        dates: [],
        searchString: '',
      },
    };
  },

  computed: {
    isAdmin() {
      return UserPermissionService.can(Permissions.CUSTOMERS_MANAGE_ROOMS);
    },

    searchPlaceholder() {
      if (this.isAdmin) {
        return 'Поиск по номеру, клиенту';
      }

      return 'Номер бронирования';
    },

    filteredOrders() {
      if (!this.filter.searchString || this.filter.searchString.length === 0) {
        return this.orders;
      }

      return this.orders.filter((order) => {
        const searchString = [order.publicId];

        if (order.user) {
          searchString.push(order.user.firstName);
          searchString.push(order.user.lastName);
          searchString.push(order.user.email);
          searchString.push(order.user.phone);
          searchString.push(StringHelper.humanPhone(order.user.phone));
        }

        return searchString
          .join('|')
          .toLowerCase()
          .match(StringHelper.escapeRegExp(this.filter.searchString.toLowerCase()));
      });
    },
  },

  mounted() {
    this.initDates();

    this.isReady = true;

    this.loadOrders();

    this.subscribeToOrdersUpdatedEvent();
  },

  destroyed() {
    this.unsubscribeFromOrdersUpdatedEvent();
  },

  methods: {
    initDates() {
      let start = DateTime.local();
      let end = start.plus({ days: 7 });

      if (this.$route.hash) {
        const url = new URLSearchParams(this.$route.hash.slice(1));

        if (url.has('from') && url.get('from').match(/\d{4}-\d{2}-\d{2}/)) {
          start = DateTime.fromISO(url.get('from'));
        }

        if (url.has('to') && url.get('to').match(/\d{4}-\d{2}-\d{2}/)) {
          end = DateTime.fromISO(url.get('to'));
        }

        if (start > end) {
          [start, end] = [end, start];
        }
      }

      this.dateFrom = start.toISODate();
      this.dateTo = end.toISODate();
    },

    changeDates(dates) {
      const start = dates[0];
      const end = dates[1] || start;

      let startDate = start;
      let endDate = end;

      if (DateTime.fromISO(start) > DateTime.fromISO(end)) {
        startDate = end;
        endDate = start;
      }

      this.dateFrom = startDate;
      this.dateTo = endDate;

      this.$router.push({
        hash: `from=${startDate}&to=${endDate}`,
      });

      this.loadOrders();
    },

    loadOrders() {
      this.isLoading = true;

      const params = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      };

      if (this.userId !== null) {
        params.userId = this.userId;
      }

      OrderService.fetchOrders(params)
        .then((orders) => {
          this.orders = orders;
        })
        .catch((e) => {
          SystemMessageService.addNotify(`Ошибка получения бронирований: ${e}`, 'error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    orderPaid() {
      const index = this.orders.findIndex((order) => order.id === this.payOrderState.orderId);

      if (index >= 0) {
        const order = this.orders[index];
        order.status.uid = OrderStatusEnum.APPROVED;
        order.payment.status.uid = PaymentStatusEnum.PAID;

        this.orders.splice(index, 1, order);
      }

      // SystemMessageService.addNotify(`Бронирование оплачено`, 'success');
    },

    openPayModal(orderId) {
      const order = this.orders.find((o) => o.id === orderId);

      this.showPayOrderModal({
        orderId,
        cost: order.cost,
        availableTo: order.availableTo,
      });
    },

    subscribeToOrdersUpdatedEvent() {
      EventBus.$on('orders-updated', () => {
        this.loadOrders();
      });
    },

    unsubscribeFromOrdersUpdatedEvent() {
      EventBus.$off('orders-updated');
    },
  },
};
</script>

<style scoped></style>
