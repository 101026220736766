export default {
  namespaced: true,

  state: {
    methods: [],
  },

  actions: {
    clear({ commit }) {
      commit('clear');
    },

    setMethods({ commit }, methods) {
      commit('setMethods', methods);
    },
  },

  mutations: {
    setMethods(state, methods) {
      state.methods = methods;
    },

    clear(state) {
      state.methods = [];
    },
  },
};
