import ListView from '@/packages/room/views/list.view';
import CreateView from '@/packages/room/views/create.view';
import EditView from '@/packages/room/views/edit.view';

const routes = [
  {
    path: '/customer/room',
    name: 'customer-room',
    component: ListView,
  },
  {
    path: '/customer/room/new',
    name: 'customer-room-create',
    component: CreateView,
  },
  {
    path: '/customer/room/edit/:id',
    name: 'customer-room-edit',
    component: EditView,
  },
];

export default routes;
