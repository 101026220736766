export default {
  namespaced: true,

  state: {
    message: {},
  },

  actions: {
    setMessage({ commit }, message) {
      commit('setMessage', message);
    },
  },

  mutations: {
    setMessage(state, message) {
      state.message = message;
    },
  },

  getters: {
    message: (state) => state.message,
  },
};
