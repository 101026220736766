<template>
  <v-dialog :value="true" max-width="600px">
    <v-card>
      <v-card-title> Оплата бронирования </v-card-title>

      <v-card-text>
        <order-payment
          v-if="false"
          :order-id="orderId"
          :cost="cost"
          :available-to="availableTo"
          @close="$emit('close')"
          @paid="setStatusToPaid"
        />
      </v-card-text>

      <v-card-actions v-if="isPaid">
        <v-spacer />

        <v-btn color="blue darken-1" small text @click="$emit('close')"> Закрыть </v-btn>

        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OrderPayment from '@/packages/order/components/form/payment';

export default {
  name: 'PaymentModal',

  components: { OrderPayment },

  props: {
    cost: {
      type: Number,
      required: true,
    },

    availableTo: {
      type: String,
      required: false,
      default: null,
    },

    orderId: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isPaid: false,
    };
  },

  methods: {
    setStatusToPaid() {
      this.isPaid = true;
      this.$emit('paid');
    },
  },
};
</script>

<style scoped></style>
