export default {
  namespaced: true,

  state: {
    id: null,
    customerId: null,
    isEnabled: false,
    appUrl: null,
    apiUrl: null,
    name: null,
  },

  actions: {
    clear({ commit }) {
      commit('clear');
    },

    setApp({ commit }, appInfo) {
      commit('setApp', appInfo);
    },
  },

  mutations: {
    setApp(state, app) {
      state.id = app.id;
      state.customerId = app.customerId;
      state.isEnabled = app.isEnabled;
      state.appUrl = app.appUrl;
      state.apiUrl = app.apiUrl;
      state.name = app.name;
    },

    clear(state) {
      state.id = null;
      state.customerId = null;
      state.isEnabled = false;
      state.appUrl = null;
      state.apiUrl = null;
      state.name = null;
    },
  },
};
