import axios from '@/plugins/axios';

function fetchRooms() {
  return axios.get('/room').then((response) => {
    return response.data.rooms;
  });
}

function createRoom(formData) {
  return axios.post('/room', formData).then((response) => {
    return response.data;
  });
}

function deleteRoom(id) {
  return axios.delete(`/room/${id}`);
}

function fetchRoom(id) {
  return axios.get(`/room/${id}`).then((response) => response.data);
}

function fetchWidgetRoom(id) {
  return axios.get(`/widget/room/${id}`).then((response) => response.data.rooms);
}

function fetchWidgetRooms() {
  return axios.get(`/widget/room`).then((response) => response.data.rooms);
}

function updateRoom(id, formData) {
  return axios.patch(`/room/${id}`, formData).then((response) => {
    return response.data;
  });
}

export default {
  fetchRooms,
  createRoom,
  deleteRoom,
  fetchRoom,
  fetchWidgetRoom,
  fetchWidgetRooms,
  updateRoom,
};
