<template>
  <div>
    <user-account-summary :balance="user.balance" show-refill-button />

    <user-account-table
      :items="items"
      :is-loading="paymentState.isLoading"
      @view-order="showOrderView($event)"
    />

    <!--
    <order-view
      v-if="orderViewState.isActive"
      :order="orderViewState.order"
      @cancel="hideOrderView"
      @order-approve="showApproveOrderForm"
      @order-cancel="showCancelOrderModal"
      @order-prolong="showProlongOrderModal"
      @order-edit="showEditOrderForm"
    />

    <order-edit
      v-if="orderEditState.isActive"
      :order="orderEditState.order"
      @cancel="hideEditOrderForm"
      @updated="hideEditOrderForm"
    />

    <order-cancel
      v-if="orderCancelState.isActive"
      :order="orderCancelState.order"
      @cancel="hideCancelOrderModal"
      @canceled="hideCancelOrderModal"
    />
    -->
  </div>
</template>

<script>
import CurrentUserService from '@/packages/user/services/current-user.service';
import UserAccountService from '@/packages/user-account/services/user-account.service';
import { mapGetters } from 'vuex';
import UserAccountSummary from '@/packages/user-account/components/list/summary';
import UserAccountTable from '@/packages/user-account/components/list/table';
import OrderActionsMixin from '@/packages/order/mixins/order-actions.mixin';
// import OrderView from '@/packages/order/components/view';
// import OrderEdit from '@/packages/order/components/edit';
// import OrderCancel from '@/packages/order/components/cancel';

export default {
  name: 'UserAccountList',

  components: { /* OrderCancel, OrderEdit, OrderView, */ UserAccountTable, UserAccountSummary },

  mixins: [OrderActionsMixin],

  data() {
    return {
      items: [],

      paymentState: {
        isLoading: false,
      },
    };
  },

  computed: {
    ...mapGetters(['user']),
  },

  mounted() {
    this.loadPayments();
  },

  methods: {
    loadPayments() {
      this.paymentState.isLoading = true;

      UserAccountService.fetchPayments()
        .then((response) => {
          this.items = response.payments;
          CurrentUserService.storeBalance(response.balance.amount);
        })
        .finally(() => {
          this.paymentState.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
