import StringHelper from '@/common/helpers/string.helper';

function humanBalance(balance) {
  balance = Math.trunc(balance);

  const label = StringHelper.declOfNum(balance, ['бонус', 'бонуса', 'бонусов']);

  return `${balance.toLocaleString()} ${label}`;
}

export default { humanBalance };
