<template>
  <v-progress-circular :size="size" :width="width" :color="color" indeterminate />
</template>

<script>
export default {
  name: 'Loader',

  props: {
    size: {
      type: Number,
      required: false,
      default: 24,
    },

    width: {
      type: Number,
      required: false,
      default: 2,
    },

    color: {
      type: String,
      required: false,
      default: 'primary',
    },
  },
};
</script>

<style scoped></style>
