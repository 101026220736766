<template>
  <v-container>
    <h2 class="ml-1 mb-3">Клиенты</h2>

    <v-text-field v-model="search" placeholder="Поиск по имени, телефону, E-mail" solo>
      <template v-slot:prepend-inner>
        <v-icon small class="pr-2">fas fa-search</v-icon>
      </template>
    </v-text-field>

    <v-data-table
      :headers="table.headers"
      :items="filteredUsers"
      :loading="isLoading"
      loading-text="Загружаем список клиентов"
      no-data-text="Клиенты не найдены"
      sort-by="registeredAt"
      sort-desc
      :footer-props="{
        itemsPerPageText: 'Клиентов на страницу',
        itemsPerPageAllText: 'Все',
      }"
    >
      <template v-slot:item.name="{ item }">
        <router-link :to="{ name: 'user-view', params: { id: item.id } }">
          {{ item.lastName }} {{ item.firstName }}
        </router-link>
      </template>

      <template v-slot:item.contacts="{ item }">
        <v-icon small class="pr-2">fas fa-phone-volume</v-icon>

        <a :href="`tel:+${item.phone}`" title="Позвонить">{{ humanPhoneNumber(item.phone) }}</a>
      </template>

      <template v-slot:item.registeredAt="{ item }">
        {{ getRegistrationDate(item.registeredAt) }}
        <span class="text--secondary">{{ getRegistrationTime(item.registeredAt) }}</span>
      </template>

      <template v-slot:item.orders.sum="{ item }">
        {{ getHumanCost(item.orders.sum) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';
import UserService from '@/packages/user/services/user.service';
import StringHelper from '@/common/helpers/string.helper';
import DateHelper from '@/common/helpers/date.helper';
import CostHelper from '@/common/helpers/cost.helper';
import SystemMessageService from '@/packages/system-message/services/system-message.service';

export default {
  name: 'UsersList',

  data() {
    return {
      isLoading: true,

      search: '',

      users: [],

      table: {
        headers: [
          {
            text: 'Клиент',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Контакты',
            align: 'start',
            sortable: false,
            value: 'contacts',
          },
          {
            text: 'Регистрация',
            align: 'start',
            sortable: true,
            value: 'registeredAt',
          },
          {
            text: 'Бронирования',
            align: 'start',
            sortable: true,
            value: 'orders.count',
          },
          {
            text: 'Доход',
            align: 'start',
            sortable: true,
            value: 'orders.sum',
          },
        ],
      },
    };
  },

  computed: {
    filteredUsers() {
      if (this.search.length > 0) {
        return this.users.filter((user) => {
          const searchString = [
            user.firstName,
            user.lastName,
            user.email,
            user.phone,
            this.humanPhoneNumber(user.phone),
          ]
            .join('|')
            .toLowerCase();

          return searchString.match(StringHelper.escapeRegExp(this.search.toLowerCase()));
        });
      }

      return this.users;
    },
  },

  mounted() {
    this.isLoading = true;
    UserService.fetchUsers()
      .then((users) => {
        this.users = users.map((user) => {
          user.name = `${user.lastName} ${user.firstName}`;
          return user;
        });
      })
      .catch((error) => {
        SystemMessageService.addNotify(`Ошибка загрузки клиентов: ${error}`, 'error');
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  methods: {
    humanPhoneNumber(phone) {
      return StringHelper.humanPhone(phone);
    },

    parseDate(date) {
      return DateTime.fromSQL(date, { zone: 'utc' }).toLocal();
    },

    getRegistrationDate(date) {
      return DateHelper.humanDate(this.parseDate(date));
    },

    getRegistrationTime(date) {
      return this.parseDate(date).toFormat('HH:mm');
    },

    getHumanCost(cost) {
      return CostHelper.humanCost(cost);
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
