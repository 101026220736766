<template>
  <room-create />
</template>

<script>
import RoomCreate from '@/packages/room/components/create/index.component';

export default {
  name: 'RoomCreateView',

  components: { RoomCreate },
};
</script>
