<template>
  <place-list />
</template>

<script>
import PlaceList from '@/packages/place/components/list/index';

export default {
  name: 'ListView',

  components: { PlaceList },
};
</script>

<style scoped></style>
