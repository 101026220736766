<template>
  <router-view />
</template>

<script>
export default {
  name: 'BlankLayout',
};
</script>

<style scoped></style>
