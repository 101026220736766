function statusColor(uid) {
  switch (uid) {
    case 'APPROVED':
      return '#35BC7C';
    case 'CANCELED':
      return '#E85F66';
    default:
      return '#de9b1e';
  }
}

function statusIcon(uid) {
  switch (uid) {
    case 'APPROVED':
      return 'mdi-check-circle-outline';
    case 'CANCELED':
      return 'mdi-close-circle-outline';
    default:
      return 'mdi-progress-alert';
  }
}

function statusText(uid) {
  switch (uid) {
    case 'APPROVED':
      return 'Подтверждено';
    case 'CANCELED':
      return 'Отменено';
    default:
      return 'Ожидает подтверждения';
  }
}

export default { statusColor, statusIcon, statusText };
