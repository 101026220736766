<template>
  <div>
    <v-row dense>
      <v-col :cols="cols">
        <v-dialog ref="dialog" v-model="selectDateModal" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="datePickerText" label="Дата" readonly v-bind="attrs" v-on="on" />
          </template>

          <v-date-picker
            v-model="formData.date"
            locale="ru"
            class="pb-2 fasti-date-picker"
            :first-day-of-week="1"
            :min="minimalDate"
            :title-date-format="() => datePickerText"
            @change="changeDate"
          />
        </v-dialog>
      </v-col>

      <v-col cols="6">
        <v-select
          v-model="formData.time"
          label="Время"
          :items="startTimeItems"
          menu-props="auto"
          @change="changeTime"
        />
      </v-col>

      <v-col cols="6">
        <v-select
          v-model="formData.duration"
          label="Длительность"
          :items="durations"
          item-value="value"
          item-text="label"
          menu-props="auto"
          :disabled="durations.length === 1"
          @change="changeDuration"
        />
      </v-col>

      <v-col v-if="showVisitors" :cols="cols">
        <v-select
          v-model="formData.visitors"
          label="Посетители"
          :items="visitorsItems"
          :disabled="visitorsItems.length <= 1"
          menu-props="auto"
          @change="changeVisitors"
        />
      </v-col>
    </v-row>

    <template v-if="isServiceTimeEnabled">
      <v-row dense class="mb-0">
        <v-col cols="12" class="pa-0 ma-0">
          <v-switch
            v-model="formData.needServiceTime"
            dense
            inset
            flat
            class="pa-0 ma-0"
            @change="emitChanges"
          >
            <template v-slot:label>
              <small>Мне необходимо время, чтобы собраться.</small>
            </template>
          </v-switch>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row v-if="formData.needServiceTime" dense class="mb-2">
          <v-col cols="12">
            <small>
              Мы добавляем {{ serviceTimeLabel }} к времени Вашего бронирования, чтобы Вы могли не
              спеша доделать все свои дела.

              <strong v-if="isServiceTimeFree" class="success--text darken-3">
                Это бесплатно для Вас.
              </strong>

              <strong v-if="!isServiceTimeFree" class="red--text darken-3">
                Данное время оплачивается дополнительно согласно тарифу, итоговую сумму к оплате
                можно увидеть ниже.
              </strong>
            </small>
          </v-col>
        </v-row>
      </v-expand-transition>
    </template>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import TimeHelper from '@/common/helpers/time.helper';
import DateHelper from '@/common/helpers/date.helper';
import SelectedPlaceMixin from '@/packages/order/mixins/selected-place.mixin';

export default {
  name: 'HourlyOrder',

  mixins: [SelectedPlaceMixin],

  props: {
    minimalDateTime: {
      type: Object,
      required: true,
    },

    room: {
      type: Object,
      required: true,
    },

    dateTimeFrom: {
      type: Object,
      required: true,
    },

    dateTimeTo: {
      type: Object,
      required: true,
    },

    visitors: {
      type: Number,
      required: false,
      default: 1,
    },

    needServiceTime: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      isReady: false,

      formData: {
        date: '',
        time: '',
        duration: 60,

        dateTimeFrom: null,
        dateTimeTo: null,
        visitors: 1,
        needServiceTime: true,
      },

      minimalDate: '',

      selectDateModal: false,
    };
  },

  computed: {
    cols() {
      if (!this.showVisitors) {
        return 12;
      }

      return 6;
    },

    placeCurrentDateTime() {
      return DateTime.local().setZone(this.placeTimezone).startOf('minute');
    },

    placeStartDateTime() {
      const time = TimeHelper.parseTime(this.placeTimeFrom);

      return this.placeCurrentDateTime.set({
        hour: time.hour,
        minute: time.minute,
      });
    },

    placeEndDateTime() {
      if (this.placeTimeTo === '00:00') {
        return this.placeCurrentDateTime.plus({ days: 1 }).startOf('day');
      }

      const time = TimeHelper.parseTime(this.placeTimeTo);

      return this.placeCurrentDateTime.set({
        hour: time.hour,
        minute: time.minute,
      });
    },

    datePickerText() {
      return DateHelper.humanDate(DateTime.fromISO(this.formData.date), {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },

    startTimeItems() {
      /**
       * @type {DateTime}
       */
      let start = this.minimalDateTime;

      if (this.formData.date !== this.minimalDateTime.toISODate()) {
        start = this.placeStartDateTime;
      }

      /**
       * @type {DateTime}
       */
      let end = this.placeEndDateTime.minus({ minutes: this.room.settings.minimalOrderTime });

      if (this.isServiceTimeEnabled && this.formData.needServiceTime) {
        end = end.minus({ minutes: this.room.settings.serviceTimeValue });
      }

      end = end.set({
        year: start.year,
        month: start.month,
        day: start.day,
      });

      return TimeHelper.generateStartTimeItems(start, end, this.room.settings.startTimeStep);
    },

    durations() {
      if (!this.isReady) {
        return [];
      }

      const start = this.formData.dateTimeFrom;

      let end = this.formData.dateTimeFrom;
      if (this.placeTimeTo === '00:00') {
        end = start.plus({ days: 1 }).startOf('day');
      } else {
        const endTime = TimeHelper.parseTime(this.placeTimeTo);
        end = end.set({
          hours: endTime.hour,
          minutes: endTime.minute,
        });
      }

      if (this.isServiceTimeEnabled && this.formData.needServiceTime) {
        end = end.minus({ minutes: this.room.settings.serviceTimeValue });
      }

      return TimeHelper.generateDurationItems(
        start,
        end,
        this.room.settings.minimalOrderTime,
        this.room.settings.orderStepTime
      );
    },

    visitorsItems() {
      const visitors = this.room.visitors || 1;

      return new Array(visitors).fill(1).map((_value, index) => index + 1);
    },

    showVisitors() {
      return this.visitorsItems.length > 1;
    },

    isServiceTimeEnabled() {
      return this.room.settings.serviceTimeEnabled && this.room.settings.serviceTimeValue > 0;
    },

    serviceTimeLabel() {
      if (this.room.settings.serviceTimeValue === 60) {
        return '1 час';
      }

      return `${this.room.settings.serviceTimeValue} минут`;
    },

    isServiceTimeFree() {
      return this.room.settings.serviceTimeFree;
    },
  },

  watch: {
    room() {
      this.changeRoom();
    },

    minimalDateTime() {
      this.changeMinimalDateTime();
    },
  },

  mounted() {
    this.initDefaults();

    this.isReady = true;
  },

  methods: {
    initDefaults() {
      // Минимальное время для календаря
      this.minimalDate = this.minimalDateTime.toISODate();

      this.formData.dateTimeFrom = this.dateTimeFrom;
      this.formData.dateTimeTo = this.dateTimeTo;
      this.formData.visitors = this.visitors;
      this.formData.needServiceTime = this.needServiceTime;

      // Вспомогательные данные
      this.formData.date = this.formData.dateTimeFrom.toISODate();
      this.formData.time = this.formData.dateTimeFrom.toFormat('HH:mm');
      this.formData.duration = this.formData.dateTimeTo.diff(
        this.formData.dateTimeFrom,
        'minutes'
      ).minutes;
    },

    changeMinimalDateTime() {
      // Минимальное время для календаря
      this.minimalDate = this.minimalDateTime.toISODate();

      if (this.formData.dateTimeFrom < this.minimalDateTime) {
        this.formData.dateTimeFrom = this.minimalDateTime;
        this.formData.dateTimeTo = this.formData.dateTimeFrom.plus({
          minutes: this.formData.duration,
        });

        this.formData.date = this.formData.dateTimeFrom.toISODate();
        this.formData.time = this.formData.dateTimeFrom.toFormat('HH:mm');

        this.emitChanges();
      }
    },

    changeRoom() {
      let needEmitChanges = false;
      let needUpdateDates = false;

      const visitorsItemsLength = this.visitorsItems.length;
      if (this.formData.visitors > this.visitorsItems[visitorsItemsLength - 1]) {
        this.formData.visitors = this.visitorsItems[visitorsItemsLength - 1];
        needEmitChanges = true;
      } else if (this.formData.visitors < this.visitorsItems[0]) {
        this.formData.visitors = this.visitorsItems[0];
        needEmitChanges = true;
      }

      if (this.startTimeItems.findIndex((time) => time === this.formData.time) < 0) {
        // TODO: Возможно, стоит поправить
        const selectedTime = TimeHelper.dateTimeToInt(this.formData.time);

        const times = this.startTimeItems.filter(
          (time) => TimeHelper.dateTimeToInt(time) <= selectedTime
        );

        if (times.length > 0) {
          this.formData.time = times[times.length - 1];
          needEmitChanges = true;
          this.updateDates();
        } else {
          this.formData.time = this.startTimeItems[0];
          needEmitChanges = true;
          this.updateDates();
        }
      }

      const durationsLength = this.durations.length;
      if (this.formData.duration > this.durations[durationsLength - 1].value) {
        this.formData.duration = this.durations[durationsLength - 1].value;
        needEmitChanges = true;
        needUpdateDates = true;
      } else if (this.formData.duration < this.durations[0].value) {
        this.formData.duration = this.durations[0].value;
        needEmitChanges = true;
        needUpdateDates = true;
      }

      if (needUpdateDates) {
        this.updateDates();
      }

      if (needEmitChanges) {
        this.emitChanges();
      }
    },

    changeDate() {
      const timeIndex = this.startTimeItems.findIndex((time) => time === this.formData.time);

      if (timeIndex < 0) {
        this.formData.time = this.startTimeItems[0];
      }

      this.selectDateModal = false;

      this.updateDates();
      this.checkDurations();
      this.emitChanges();
    },

    changeTime() {
      this.updateDates();
      this.checkDurations();
      this.emitChanges();
    },

    checkDurations() {
      const index = this.durations.findIndex(
        (duration) => duration.value === this.formData.duration
      );

      if (index < 0) {
        this.formData.duration = this.durations[this.durations.length - 1].value;
      }

      this.updateDates();
    },

    changeDuration() {
      this.updateDates();
      this.emitChanges();
    },

    updateDates() {
      this.formData.dateTimeFrom = DateTime.fromFormat(
        `${this.formData.date} ${this.formData.time}`,
        'y-LL-dd HH:mm',
        {
          zone: this.placeTimezone,
        }
      );

      this.formData.dateTimeTo = this.formData.dateTimeFrom.plus({
        minutes: this.formData.duration,
      });
    },

    changeVisitors() {
      this.emitChanges();
    },

    emitChanges() {
      const formData = {
        dateTimeFrom: this.formData.dateTimeFrom,
        dateTimeTo: this.formData.dateTimeTo,
        visitors: this.formData.visitors,
        needServiceTime: this.formData.needServiceTime,
      };

      this.$emit('change', formData);
    },
  },
};
</script>

<style scoped></style>
