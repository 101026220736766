function getStatusByUid(uid) {
  switch (uid) {
    case 'PROCESSING':
      return 'Оплачивается';
    case 'PAID':
      return 'Оплачено';
    default:
      return 'Не оплачено';
  }
}

function getStatusColorByUid(uid) {
  switch (uid) {
    case 'PROCESSING':
      return '#de9b1e';
    case 'PAID':
      return '#35BC7C';
    default:
      return '#E85F66';
  }
}

export default { getStatusByUid, getStatusColorByUid };
