import ListView from '@/packages/order/views/orders-list';
import CreateView from '@/packages/order/views/create-order';
// import EditView from '@/packages/order/views/edit-order';

const routes = [
  {
    path: '/orders',
    name: 'order',
    component: ListView,
  },
  {
    path: '/orders/new',
    name: 'order-new',
    component: CreateView,
  },
  /*
  {
    path: '/orders/:id/move',
    name: 'order-edit',
    component: EditView,
  },
   */
];

export default routes;
