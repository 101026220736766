import axios from '@/plugins/axios';
import DateTimeConstants from '@/common/constants/datetime.constants';

function fetchPlaces() {
  return axios.get('/place').then((response) => response.data.places);
}

function fetchActivePlaces() {
  return axios.get('/widget/place').then((response) => response.data.places);
}

function create(formData) {
  return axios.post('/place', formData).then((response) => {
    return response.data;
  });
}

function update(id, formData) {
  return axios.patch(`/place/${id}`, formData).then((response) => {
    return response.data;
  });
}

function deletePlace(id) {
  return axios.delete(`/place/${id}`);
}

function fetchPlace(id) {
  return axios.get(`/place/${id}`).then((response) => response.data);
}

function fetchRoomsAvailability(placeId, dateFrom, dateTo, orderId) {
  const params = {
    dateFrom: dateFrom.toUTC().toFormat(DateTimeConstants.API_DATETIME_FORMAT),
    dateTo: dateTo.toUTC().toFormat(DateTimeConstants.API_DATETIME_FORMAT),
  };

  if (orderId) {
    params.orderId = orderId;
  }

  return axios
    .get(`/place/${placeId}/room-available`, {
      params,
    })
    .then((response) => response.data.rooms);
}

export default {
  create,
  fetchPlaces,
  fetchActivePlaces,
  deletePlace,
  fetchPlace,
  update,
  fetchRoomsAvailability,
};
