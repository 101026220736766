<template>
  <v-container v-if="isReady" class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card v-if="isConfirmed" class="elevation-12">
          <v-toolbar color="green" dark flat>
            <v-toolbar-title>{{ appName }}</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            Адрес <strong>{{ email }}</strong> подтверждён.
          </v-card-text>
        </v-card>

        <v-card v-if="!isConfirmed" class="elevation-12">
          <v-toolbar color="red" dark flat>
            <v-toolbar-title>{{ appName }}</v-toolbar-title>
          </v-toolbar>

          <v-card-title>Ошибка</v-card-title>

          <v-card-text>
            Неправильный адрес <strong>{{ email }}</strong> или код <strong>{{ code }}</strong
            >.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VerifyEmailService from '@/packages/verify-email/verify-email.service';
import { email, maxLength, minLength, numeric, required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  name: 'VerifyEmail',

  data() {
    return {
      isReady: false,

      isConfirmed: false,

      email: null,
      code: null,
    };
  },

  computed: {
    ...mapGetters(['appName']),
  },

  validations() {
    return {
      email: { required, email },
      code: { required, numeric, minLength: minLength(6), maxLength: maxLength(6) },
    };
  },

  mounted() {
    this.email = this.$route.query.email;
    this.code = this.$route.query.code;

    this.$v.$touch();

    if (!this.$v.$invalid) {
      VerifyEmailService.confirmEmail(this.email, this.code)
        .then(() => {
          this.isConfirmed = true;
        })
        .catch(() => {
          this.isConfirmed = false;
        })
        .finally(() => {
          this.isReady = true;
        });
    } else {
      this.isReady = true;
    }
  },
};
</script>

<style scoped></style>
