<template>
  <v-card tile flat>
    <v-card-title v-if="title" class="headline">{{ title }}</v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field label="Телефон" :value="phoneNumber" disabled />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model.trim="$v.form.email.$model"
            label="E-mail"
            persistent-hint
            :error-messages="emailErrors"
            @blur="$v.form.email.$touch()"
            @input="$v.form.email.$touch()"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            v-model.trim="$v.form.firstName.$model"
            label="Имя"
            persistent-hint
            :error-messages="firstNameErrors"
            @blur="$v.form.firstName.$touch()"
            @input="$v.form.firstName.$touch()"
          />
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <v-text-field
            v-model.trim="$v.form.lastName.$model"
            label="Фамилия"
            persistent-hint
            :error-messages="lastNameErrors"
            @blur="$v.form.lastName.$touch()"
            @input="$v.form.lastName.$touch()"
          />
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <v-text-field v-model.trim="form.middleName" label="Отчество" />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="primary" :loading="isLoading" :disabled="$v.$invalid" @click="saveProfile">
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import UserProfileService from '@/packages/user/services/user-profile.service';
import SystemMessageService from '@/packages/system-message/services/system-message.service';

export default {
  name: 'ProfileForm',

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },

    email: {
      type: String,
      required: false,
      default: '',
    },

    firstName: {
      type: String,
      required: false,
      default: '',
    },

    lastName: {
      type: String,
      required: false,
      default: '',
    },

    middleName: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      isLoading: false,

      form: {
        email: '',
        firstName: '',
        lastName: '',
        middleName: '',
      },

      apiErrors: {
        email: [],
        firstName: [],
        lastName: [],
        middleName: [],
      },
    };
  },

  computed: {
    phoneNumber() {
      return this.$store.state.user.phone;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;

      if (!this.$v.form.email.required) {
        errors.push('Введите e-mail');
      }

      if (!this.$v.form.email.email) {
        errors.push('Неправильный e-mail');
      }

      this.apiErrors.email.forEach((error) => {
        errors.push(error);
      });

      return errors;
    },

    firstNameErrors() {
      const errors = [];
      if (!this.$v.form.firstName.$dirty) return errors;

      if (!this.$v.form.firstName.required) {
        errors.push('Введите имя');
      }

      this.apiErrors.firstName.forEach((error) => {
        errors.push(error);
      });

      return errors;
    },

    lastNameErrors() {
      const errors = [];
      if (!this.$v.form.lastName.$dirty) return errors;

      if (!this.$v.form.lastName.required) {
        errors.push('Введите фамилию');
      }

      this.apiErrors.lastName.forEach((error) => {
        errors.push(error);
      });

      return errors;
    },
  },

  validations: {
    form: {
      email: {
        required,
        email,
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
    },
  },

  mounted() {
    this.form.email = this.$props.email;
    this.form.firstName = this.$props.firstName;
    this.form.lastName = this.$props.lastName;
    this.form.middleName = this.$props.middleName;
  },

  methods: {
    saveProfile() {
      this.clearApiErrors();
      this.isLoading = true;
      UserProfileService.save(this.form)
        .then(() => {
          SystemMessageService.addNotify('Профиль сохранён', 'success');
        })
        .catch((error) => {
          this.setApiErrors(error);

          let message;
          let code = 0;

          if (error.response && error.response.data) {
            message = error.response.data.message || 'Unknown';
          }

          if (error.response && error.response.status) {
            code = error.response.status;
          }

          SystemMessageService.addNotify(
            `Ошибка сохранения профиля: ${message} (${code})`,
            'error'
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    setApiErrors(error) {
      if (error.response && error.response.status && error.response.status === 422) {
        this.apiErrors.email = error.response.data.errors.email || [];
        this.apiErrors.firstName = error.response.data.errors.firstName || [];
        this.apiErrors.lastName = error.response.data.errors.lastName || [];
        this.apiErrors.middleName = error.response.data.errors.middleName || [];
      }
    },

    clearApiErrors() {
      this.apiErrors.phoneNumber = [];
      this.apiErrors.code = [];
    },
  },
};
</script>

<style scoped></style>
