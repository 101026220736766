import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import Vue from 'vue';
import store from '@/store';
import AccessTokenService from '@/common/services/access-token.service';

function init() {
  if (!window.Pusher) {
    window.Pusher = Pusher;
  }

  const config = {
    broadcaster: 'pusher',
    key: process.env.VUE_APP_WS_KEY,
    wsHost: process.env.VUE_APP_WS_HOST,
    wsPort: process.env.VUE_APP_WS_PORT,
    wssPort: process.env.VUE_APP_WS_PORT,
    forceTLS: false,
    encrypted: true,
    enabledTransports: ['ws', 'wss'],
    disableStats: true,
  };

  if (AccessTokenService.hasAccessToken()) {
    let apiUrl = store.getters.apiUrl;

    if (apiUrl.charAt(apiUrl.length - 1) !== '/') {
      apiUrl = `${apiUrl}/`;
    }

    config.authEndpoint = `${apiUrl}broadcasting/auth`;
    config.auth = {
      headers: {
        Authorization: `Bearer ${AccessTokenService.getAccessToken()}`,
      },
    };
  }

  Vue.prototype.$echo = new Echo(config);
}

export default { init };
