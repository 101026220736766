<template>
  <v-container>
    <v-tabs v-model="selectedTab" center-active show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.key">
        {{ tab.label }}
      </v-tab>

      <v-tab-item>
        <user-info-card :user-id="userId" />
      </v-tab-item>

      <v-tab-item>
        <user-orders :user-id="userId" />
      </v-tab-item>

      <template v-if="isUserAccountEnabled">
        <v-tab-item>
          <user-account :user-id="userId" />
        </v-tab-item>

        <v-tab-item>
          <payments-list :user-id="userId" />
        </v-tab-item>
      </template>
    </v-tabs>
  </v-container>
</template>

<script>
import UserInfoCard from '@/packages/user/components/info/user-info-card';
import UserAccount from '@/packages/user/components/info/user-account';
import UserOrders from '@/packages/user/components/info/user-orders';
import PaymentsList from '@/packages/payment/components/list';
import UserAccountService from '@/packages/user-account/services/user-account.service';

export default {
  name: 'UserInfo',

  components: { PaymentsList, UserOrders, UserAccount, UserInfoCard },

  props: {
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedTab: 0,
    };
  },

  computed: {
    tabs() {
      let tabs = [
        {
          key: 'summary',
          label: 'Клиент',
        },
        {
          key: 'orders',
          label: 'Бронирования',
        },
      ];

      if (this.isUserAccountEnabled) {
        tabs = tabs.concat([
          {
            key: 'userAccount',
            label: 'Счёт',
          },
          {
            key: 'payments',
            label: 'Платежи',
          },
        ]);
      }

      return tabs;
    },

    isUserAccountEnabled() {
      return UserAccountService.isEnabled();
    },
  },
};
</script>

<style scoped></style>
