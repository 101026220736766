<template>
  <place-edit />
</template>

<script>
import PlaceEdit from '@/packages/place/components/edit/index.component';

export default {
  name: 'EditView',

  components: { PlaceEdit },
};
</script>

<style scoped></style>
