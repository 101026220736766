import store from '@/store';

let timeoutId;

function storeMessage(message) {
  store.dispatch('notify/setMessage', message);
}

function clearNotify() {
  storeMessage({});
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
}

function addNotify(text, type) {
  const timeout = 5000;

  const message = {
    text,
    type,
    timeout,
  };

  storeMessage(message);

  timeoutId = setTimeout(() => {
    clearNotify();
  }, timeout);
}

export default { addNotify, clearNotify };
