<template>
  <div>
    <OrderView
      v-if="orderViewState.isActive"
      :order="orderViewState.order"
      @cancel="hideOrderView"
    />

    <OrderEdit
      v-if="orderEditState.isActive"
      :order="orderEditState.order"
      @cancel="hideEditOrderModal"
      @updated="hideEditOrderModalAndReloadOrders"
    />

    <OrderCancel
      v-if="orderCancelState.isActive"
      :order="orderCancelState.order"
      @cancel="hideCancelOrderModal"
      @canceled="hideCancelOrderModalAndReloadOrders"
    />

    <OrderApprove
      v-if="orderApproveState.isActive"
      :order="orderApproveState.order"
      @cancel="hideApproveOrderModal"
      @approved="hideApproveOrderModalAndReloadOrders"
    />

    <OrderProlong
      v-if="orderProlongState.isActive"
      :order="orderProlongState.order"
      @cancel="hideProlongOrderModal"
      @prolonged="hideProlongOrderModalAndReloadOrders"
    />

    <OrderPay
      v-if="orderPayState.isActive"
      :order="orderPayState.order"
      @cancel="hidePayOrderModal"
      @paid="hidePayOrderModalAndReloadOrders"
    />
  </div>
</template>

<script>
import OrderView from '@/packages/order/components/view';
import OrderCancel from '@/packages/order/components/cancel';
import OrderActionsMixin from '@/packages/order/mixins/order-actions.mixin';
import OrderApprove from '@/packages/order/components/approve';
import OrderProlong from '@/packages/order/components/prolong';
import OrderPay from '@/packages/order/components/pay';
import OrderEdit from '@/packages/order/components/edit';

export default {
  name: 'OrderActions',

  components: { OrderEdit, OrderPay, OrderProlong, OrderApprove, OrderCancel, OrderView },

  mixins: [OrderActionsMixin],
};
</script>

<style scoped></style>
