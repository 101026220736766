<template>
  <room-form />
</template>

<script>
import RoomForm from '@/packages/room/components/form/room-form';

export default {
  name: 'RoomCreate',
  components: { RoomForm },
};
</script>

<style scoped></style>
