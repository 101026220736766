<template>
  <v-row v-if="statuses.length > 1" dense>
    <v-col cols="12">
      <v-btn
        v-for="status in statuses"
        :key="status.key"
        small
        :color="status.color"
        dark
        class="mr-2 mb-2"
        :outlined="!isStatusSelected(status.key)"
        @click="toggleStatus(status.key)"
      >
        {{ status.label }} ({{ status.counter }})
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import PaymentStatusMixin from '@/packages/payment/mixins/payment-status.mixin';

export default {
  name: 'StatusFilter',

  mixins: [PaymentStatusMixin],

  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },

    selectedStatuses: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    statuses() {
      const statuses = {};

      this.items.forEach((item) => {
        if (!statuses[item.localStatus.uid]) {
          statuses[item.localStatus.uid] = {
            key: item.localStatus.uid,
            label: this.paymentStatusOptions[item.localStatus.uid].label,
            color: this.paymentStatusOptions[item.localStatus.uid].color,
            counter: 0,
          };
        }

        statuses[item.localStatus.uid].counter += 1;
      });

      return Object.values(statuses);
    },
  },

  methods: {
    toggleStatus(status) {
      const index = this.getStatusIndex(status);

      if (index >= 0) {
        this.selectedStatuses.splice(index, 1);
      } else {
        this.selectedStatuses.push(status);
      }

      this.$emit('select', this.selectedStatuses);
    },

    getStatusIndex(status) {
      return this.selectedStatuses.findIndex((key) => key === status);
    },

    isStatusSelected(status) {
      return this.selectedStatuses.length === 0 || this.getStatusIndex(status) !== -1;
    },
  },
};
</script>

<style scoped></style>
