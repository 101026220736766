<template>
  <order-list />
</template>

<script>
import OrderList from '@/packages/order/components/list/index';

export default {
  name: 'OrdersListView',

  components: { OrderList },
};
</script>

<style scoped></style>
