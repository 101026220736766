<template>
  <v-container>
    <h2 class="ml-1 mb-3">История операций</h2>

    <user-account-list />
  </v-container>
</template>

<script>
import UserAccountList from '@/packages/user-account/components/list';

export default {
  name: 'UserAccountView',
  components: { UserAccountList },
};
</script>

<style scoped></style>
