import axios from '@/plugins/axios';
import CookieService from '@/common/services/cookie.service';
import store from '@/store';
import CookieConstants from '@/packages/auth/constants/cookie.constants';
import md5 from 'md5';

function requestCode(phoneNumber) {
  return axios
    .post('/sms/get-code', {
      phone: phoneNumber,
    })
    .then((response) => {
      return response.data;
    });
}

function login(phoneNumber, password) {
  return axios
    .post('/auth/login', {
      phone: phoneNumber,
      password: md5(password),
    })
    .then((response) => {
      const data = response.data;

      store.dispatch('user/setLoggedIn', true);
      store.dispatch('user/setUserInfo', response.data.user);
      store.dispatch('userPermissions/setPermissions', response.data.permissions);
      CookieService.set(CookieConstants.COOKIE_TOKEN_NAME, data.accessToken);

      return data;
    });
}

function getUserInfo() {
  return axios
    .get('/me')
    .then((response) => {
      store.dispatch('user/setLoggedIn', true);
      store.dispatch('user/setUserInfo', response.data.user);
      store.dispatch('userPermissions/setPermissions', response.data.permissions);
      return response.data;
    })
    .catch((error) => {
      CookieService.clear(CookieConstants.COOKIE_TOKEN_NAME);
      throw error;
    });
}

function logout() {
  store.dispatch('user/clear');
  CookieService.clear(CookieConstants.COOKIE_TOKEN_NAME);
}

export default { requestCode, login, getUserInfo, logout };
