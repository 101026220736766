<template>
  <v-row dense>
    <v-col cols="12" sm="8">
      <v-row dense>
        <v-col cols="5" sm="4">
          {{ $t('place.label') }}
        </v-col>
        <v-col cols="7" sm="8">
          {{ placeName }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="5" sm="4">
          {{ $t('room.label') }}
        </v-col>
        <v-col cols="7" sm="8">
          {{ roomName }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="5" sm="4"> Дата </v-col>
        <v-col cols="7" sm="8">
          {{ date }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="5" sm="4"> Время </v-col>
        <v-col cols="7" sm="8">
          {{ time }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="5" sm="4"> Длительность </v-col>
        <v-col cols="7" sm="8">
          {{ duration }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="5" sm="4"> Посетители </v-col>
        <v-col cols="7" sm="8">
          {{ visitors }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="5" sm="4"> Стоимость </v-col>
        <v-col cols="7" sm="8">
          {{ humanCost }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import DateHelper from '@/common/helpers/date.helper';
import TimeHelper from '@/common/helpers/time.helper';
import { DateTime } from 'luxon';
import CostHelper from '@/packages/order/helpers/cost.helper';

export default {
  name: 'OrderInfo',

  props: {
    placeName: {
      type: String,
      required: true,
    },

    placeTimezone: {
      type: String,
      required: true,
    },

    roomName: {
      type: String,
      required: true,
    },

    dateFrom: {
      type: String,
      required: true,
    },

    dateTo: {
      type: String,
      required: true,
    },

    visitors: {
      type: Number,
      required: true,
    },

    cost: {
      type: Number,
      required: true,
    },
  },

  computed: {
    dateTimeFrom() {
      return DateTime.fromSQL(this.dateFrom, { zone: 'UTC' }).setZone(this.placeTimezone);
    },

    dateTimeTo() {
      return DateTime.fromSQL(this.dateTo, { zone: 'UTC' }).setZone(this.placeTimezone);
    },

    date() {
      return DateHelper.humanDate(this.dateTimeFrom);
    },

    time() {
      return DateHelper.humanDate(this.dateTimeFrom, DateTime.TIME_SIMPLE);
    },

    duration() {
      const minutes = this.dateTimeTo.diff(this.dateTimeFrom, 'minutes').minutes;

      return TimeHelper.humanTimeFromMinutes(minutes);
    },

    humanCost() {
      return CostHelper.humanCost(this.cost);
    },
  },
};
</script>

<style>
.fasti__user-stat.v-list--dense .v-list-item.v-list-item--two-line,
.fasti__user-stat .v-list-item--dense.v-list-item--two-line {
  min-height: 30px;
}
</style>
