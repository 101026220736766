import axios from '@/plugins/axios';
import store from '@/store';

function fetchBalance() {
  return axios.get('/me/balance').then((response) => response.data.balance);
}

function storeBalance(balance) {
  store.dispatch('user/setBalance', balance);
}

export default { fetchBalance, storeBalance };
