<template>
  <user-info :user-id="$route.params.id" />
</template>

<script>
import UserInfo from '@/packages/user/components/info/index';

export default {
  name: 'UserInfoView',

  components: { UserInfo },
};
</script>

<style scoped></style>
