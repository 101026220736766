import VerifyEmail from '@/packages/verify-email/components/index';
import BlankLayout from '@/layouts/blank.layout';

const routes = [
  {
    path: '/verify-email/',
    name: 'verify-email',
    component: VerifyEmail,
    meta: {
      layout: BlankLayout,
      public: true,
    },
  },
];

export default routes;
