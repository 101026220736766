import AxiosInstance from 'axios';
import AuthHelper from '@/packages/auth/helpers/auth.helper';
import store from '@/store';
import SystemErrorService from '@/packages/system-error/services/system-error.service';

const axios = AxiosInstance.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json;charset=utf-8',
  },
  withCredentials: false,
});

axios.interceptors.request.use((config) => {
  if (store.getters.apiUrl) {
    config.baseURL = `${store.getters.apiUrl.replace(/\/$/, '')}/v1/`;
  }

  config.headers = {
    ...config.headers,
    ...AuthHelper.authHeader(),
  };

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    SystemErrorService.handleHttpErrors(error);
    return Promise.reject(error);
  }
);

export default axios;
