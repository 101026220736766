<template>
  <v-snackbar :value="showSnackbar" :timeout="message.timeout" :color="message.type">
    {{ message.text }}

    <template v-slot:action>
      <v-btn color="white" icon @click="close">
        <v-icon>far fa-times-circle</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import SystemMessageService from '@/packages/system-message/services/system-message.service';

export default {
  name: 'Notify',

  computed: {
    message() {
      return this.$store.getters['notify/message'];
    },

    showSnackbar() {
      return this.message && this.message.text;
    },
  },

  methods: {
    close() {
      SystemMessageService.clearNotify();
    },
  },
};
</script>

<style scoped></style>
