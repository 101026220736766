<template>
  <v-row v-if="costTypeItems.length > 1" align="center" justify="center" class="mb-3">
    <v-btn-toggle
      :value="costType"
      tile
      color="deep-purple accent-3"
      group
      mandatory
      dense
      @change="changeCostType"
    >
      <v-btn v-for="item in costTypeItems" :key="item.value" :value="item.value" x-small>
        {{ item.label }}
      </v-btn>
    </v-btn-toggle>
  </v-row>
</template>

<script>
import CostTypeEnum from '@/packages/order/enums/cost-type.enum';

export default {
  name: 'CostTypeSelect',

  props: {
    costType: {
      type: String,
      required: true,
    },

    isHourlyTypeAvailable: {
      type: Boolean,
      required: false,
      default: true,
    },

    isDailyTypeAvailable: {
      type: Boolean,
      required: false,
      default: false,
    },

    isManyDaysTypeAvailable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    costTypeItems() {
      const items = [];

      if (this.isHourlyTypeAvailable) {
        items.push({
          value: CostTypeEnum.TYPE_HOURLY,
          label: 'На час',
        });
      }

      if (this.isDailyTypeAvailable) {
        items.push({
          value: CostTypeEnum.TYPE_DAILY,
          label: 'На день',
        });
      }

      if (this.isManyDaysTypeAvailable) {
        items.push({
          value: CostTypeEnum.TYPE_MANY_DAYS,
          label: 'Несколько дней',
        });
      }

      return items;
    },
  },

  methods: {
    changeCostType(costType) {
      this.$emit('change', costType);
    },
  },
};
</script>

<style scoped></style>
