<template>
  <profile-form
    v-if="profile.email"
    title="Профиль"
    :email="profile.email"
    :first-name="profile.firstName"
    :last-name="profile.lastName"
    :middle-name="profile.middleName"
  />
</template>

<script>
import ProfileForm from '@/packages/user/components/profile/form/index.component';
import UserProfileService from '@/packages/user/services/user-profile.service';

export default {
  name: 'Profile',
  components: { ProfileForm },

  data() {
    return {
      profile: {},
    };
  },

  mounted() {
    UserProfileService.get().then((response) => {
      this.profile = response.user;
    });
  },
};
</script>

<style scoped></style>
