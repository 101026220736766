import ListView from '@/packages/place/views/list.view';
import CreateView from '@/packages/place/views/create.view';
import EditView from '@/packages/place/views/edit.view';

const routes = [
  {
    path: '/customer/place',
    name: 'customer-place',
    component: ListView,
  },
  {
    path: '/customer/place/new',
    name: 'customer-place-create',
    component: CreateView,
  },
  {
    path: '/customer/place/edit/:id',
    name: 'customer-place-edit',
    component: EditView,
  },
];

export default routes;
