import axios from '@/plugins/axios';

function getPhoneNumber(phone) {
  return axios.post('/phone-verification/call/number', { phone }).then((response) => {
    return response.data;
  });
}

function checkStatus(phone) {
  return axios.post('/phone-verification/call/status', { phone }).then((response) => {
    return response.data;
  });
}

export default { getPhoneNumber, checkStatus };
