export const CUSTOMERS_MANAGE_ROOMS = 'CUSTOMERS_MANAGE_ROOMS';
export const CUSTOMER_MANAGE_CALENDARS = 'CUSTOMER_MANAGE_CALENDARS';
export const SEARCH_USERS = 'SEARCH_USERS';
export const CREATE_ORDER_FOR_OTHER_USER = 'CREATE_ORDER_FOR_OTHER_USER';

/* Orders */
export const ROOM_ORDERS_VIEW_NEW = 'ROOM_ORDERS_VIEW_NEW';

export const PLACES_GET = 'PLACES_GET';
export const PLACES_CREATE = 'PLACES_CREATE';
export const PLACES_UPDATE = 'PLACES_UPDATE';
export const PLACES_DELETE = 'PLACES_DELETE';
