<template>
  <v-card v-if="isReady" :class="{ 'pt-3': !room.picture }">
    <v-img
      v-if="room.picture"
      :src="room.picture"
      class="white--text align-end mb-2"
      height="150px"
    >
      <v-card-title>
        <v-chip :color="room.calendar.backgroundColor" dark>
          {{ room.name }}
        </v-chip>
      </v-card-title>
    </v-img>

    <v-card-text class="pb-0 pt-3">
      <cost-type-select
        :cost-type="costType"
        :is-daily-type-available="isDailyTypeAvailable"
        :is-hourly-type-available="isHourlyTypeAvailable"
        :is-many-days-type-available="isManyDaysTypeAvailable"
        @change="changeCostType"
      />

      <hourly-order
        v-if="isHourlyType"
        :date-time-from="dateTimeFrom"
        :date-time-to="dateTimeTo"
        :minimal-date-time="minimalDateTime"
        :need-service-time="needServiceTime"
        :room="room"
        :selected-place="selectedPlace"
        :visitors="visitors"
        @change="changeMainFormData"
      />

      <daily-order
        v-if="isDailyType"
        :date-time="dateTimeFrom"
        :minimal-date-time="minimalDateTime"
        :room="room"
        :selected-place="selectedPlace"
        :visitors="visitors"
        @change="changeMainFormData"
      />

      <many-days-order
        v-if="isManyDaysType"
        :date-time-from="dateTimeFrom"
        :date-time-to="dateTimeTo"
        :minimal-date-time="minimalDateTime"
        :room="room"
        :selected-place="selectedPlace"
        :visitors="visitors"
        @change="changeMainFormData"
      />
    </v-card-text>

    <template v-if="roomsWithAvailability.length > 1">
      <v-card-subtitle class="pb-2">
        {{ $t('room.label') }}
      </v-card-subtitle>

      <v-card-text>
        <order-room-select
          :availability="availability"
          :is-loaded="isAvailabilityLoaded"
          :is-loading="isAvailabilityLoading"
          :room-id="room.id"
          :rooms="roomsWithAvailability"
          @change="changeRoom"
        />
      </v-card-text>
    </template>

    <v-expand-transition>
      <v-card-text v-if="isAvailabilityLoaded && !isSelectedRoomAvailable" class="pt-0 mt-0">
        <v-alert border="left" color="warning" colored-border dense elevation="2" type="warning">
          {{ $t('room.unavailable') }}
        </v-alert>
      </v-card-text>
    </v-expand-transition>

    <template v-if="canChangePaymentStatus || canChangePaymentMethod">
      <v-card-subtitle class="pb-0">
        <v-row dense>
          <v-col v-if="canChangePaymentStatus" cols="6"> Статус оплаты </v-col>

          <v-col v-if="canChangePaymentMethod" cols="6"> Метод оплаты </v-col>
        </v-row>
      </v-card-subtitle>

      <v-card-text>
        <v-row dense>
          <v-col v-if="canChangePaymentStatus" cols="6">
            <v-select
              :items="paymentStatuses"
              :value="paymentStatus"
              item-text="value"
              item-value="uid"
              menu-props="auto"
              @change="changePaymentStatus"
            />
          </v-col>

          <v-col v-if="canChangePaymentMethod" cols="6">
            <v-select
              :items="paymentMethods"
              :value="paymentMethod"
              item-text="value"
              item-value="uid"
              menu-props="auto"
              @change="changePaymentMethod"
            />
          </v-col>
        </v-row>

        <v-alert v-if="shouldShowRefundWarning" type="warning">
          Внимание! При данном методе оплаты клиент сможет вернуть средства на личный счёт при
          отмене бронирования.
        </v-alert>
      </v-card-text>
    </template>

    <v-card-subtitle class="pb-0"> Стоимость бронирования </v-card-subtitle>

    <v-card-title class="pt-0">
      {{ humanCost(cost) }}
    </v-card-title>

    <template v-if="canSetUserSpecifiedCost">
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-switch
              :value="useUserSpecifiedCost"
              hide-details
              label="Установить стоимость"
              @change="changeUseUserSpecifiedCost"
            />
          </v-col>
        </v-row>

        <v-row v-if="useUserSpecifiedCost">
          <v-col cols="6">
            <v-text-field
              :value="userSpecifiedCost"
              dense
              label="Введите стоимость"
              type="number"
              @change="changeUserSpecifiedCost"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </template>

    <v-card-text v-if="canSetAvailableTo">
      <v-row>
        <v-col cols="6">
          <v-switch
            :value="useAvailableTo"
            hide-details
            label="Установить время оплаты"
            @change="changeUseAvailableTo"
          />
        </v-col>
      </v-row>

      <v-row v-if="useAvailableTo">
        <v-col cols="6">
          <v-text-field
            v-model="formData.availableTo"
            :min="minAvailableTo"
            :max="maxAvailableTo"
            type="datetime-local"
            label="Время оплаты"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn color="blue darken-1" small text @click="$emit('cancel')"> Закрыть </v-btn>

      <v-spacer />

      <v-btn :disabled="nextButtonDisabled" color="primary" small @click="confirm">
        Продолжить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CostHelper from '@/common/helpers/cost.helper';
import CostTypeEnum from '@/packages/order/enums/cost-type.enum';
import CostTypeSelect from '@/packages/order/components/form/cost-type/cost-type-select';
import HourlyOrder from '@/packages/order/components/form/cost-type/hourly-order';
import DailyOrder from '@/packages/order/components/form/cost-type/daily-order';
import ManyDaysOrder from '@/packages/order/components/form/cost-type/many-days-order';
import OrderRoomSelect from '@/packages/order/components/form/room-select';
import SelectedPlaceMixin from '@/packages/order/mixins/selected-place.mixin';
import OrderPaymentStatusEnum from '@/packages/order/enums/order-payment-status.enum';
import PermissionMixin from '@/packages/permission/mixins/permission.mixin';
import PaymentMethodEnum from '@/packages/payment/enums/payment-method.enum';
import { DateTime } from 'luxon';

// TODO: Добавить возможность выбора метода оплаты пользователем
export default {
  name: 'MainForm',

  components: {
    OrderRoomSelect,
    ManyDaysOrder,
    DailyOrder,
    HourlyOrder,
    CostTypeSelect,
  },

  mixins: [SelectedPlaceMixin, PermissionMixin],

  props: {
    isHourlyTypeAvailable: {
      type: Boolean,
      required: false,
      default: true,
    },

    isDailyTypeAvailable: {
      type: Boolean,
      required: false,
      default: false,
    },

    isManyDaysTypeAvailable: {
      type: Boolean,
      required: false,
      default: false,
    },

    room: {
      type: Object,
      required: true,
    },

    rooms: {
      type: Array,
      required: false,
      default: () => [],
    },

    costType: {
      type: String,
      required: true,
    },

    minimalDateTime: {
      type: Object,
      required: true,
    },

    dateTimeFrom: {
      type: Object,
      required: true,
    },

    dateTimeTo: {
      type: Object,
      required: true,
    },

    visitors: {
      type: Number,
      required: true,
    },

    needServiceTime: {
      type: Boolean,
      required: true,
    },

    isAvailabilityLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    isAvailabilityLoaded: {
      type: Boolean,
      required: false,
      default: false,
    },

    availability: {
      type: Object,
      required: false,
      default: () => {},
    },

    cost: {
      type: Number,
      required: false,
      default: 0,
    },

    useUserSpecifiedCost: {
      type: Boolean,
      required: false,
      default: false,
    },

    userSpecifiedCost: {
      type: Number,
      required: false,
      default: 0,
    },

    paymentMethod: {
      type: String,
      required: false,
      default: PaymentMethodEnum.CARD,
    },

    paymentStatus: {
      type: String,
      required: false,
      default: OrderPaymentStatusEnum.NEW,
    },

    useAvailableTo: {
      type: Boolean,
      required: false,
      default: false,
    },

    availableTo: {
      type: [Object, null],
      required: false,
      default: null,
    },

    order: {
      type: [Object, null],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isReady: false,

      availableToFormat: "y-LL-dd'T'HH:mm",

      formData: {
        availableTo: '',
      },

      paymentStatuses: [
        {
          uid: OrderPaymentStatusEnum.NEW,
          value: 'Новое',
        },
        {
          uid: OrderPaymentStatusEnum.PAID,
          value: 'Оплачено',
        },
      ],

      paymentMethods: [
        {
          uid: PaymentMethodEnum.CARD,
          value: 'Карта',
        },
        {
          uid: PaymentMethodEnum.CASH,
          value: 'Наличные',
        },
        {
          uid: PaymentMethodEnum.TRANSFER,
          value: 'Перевод',
        },
      ],

      selectDateModal: false,
      minimalDate: null,
    };
  },

  computed: {
    isHourlyType() {
      return this.costType === CostTypeEnum.TYPE_HOURLY;
    },

    isDailyType() {
      return this.costType === CostTypeEnum.TYPE_DAILY;
    },

    isManyDaysType() {
      return this.costType === CostTypeEnum.TYPE_MANY_DAYS;
    },

    nextButtonDisabled() {
      return (
        this.isAvailabilityLoading || this.cost <= 0 || !this.isSelectedRoomAvailable
        // TODO: Добавить проверку изменения данных
        // || (this.isEditing && this.room.id === this.order.room.id && this.formData.dateTimeFrom.valueOf() === this.fo)
      );
    },

    roomsWithAvailability() {
      return this.rooms.map((room) => ({
        ...room,
        isAvailable: this.availability[room.id],
      }));
    },

    selectedRoom() {
      if (this.roomsWithAvailability.length === 1) {
        return this.roomsWithAvailability[0];
      }

      return this.roomsWithAvailability.find((room) => room.id === this.room.id) || {};
    },

    isSelectedRoomAvailable() {
      return this.selectedRoom.isAvailable;
    },

    isEditing() {
      return this.order && this.order.id;
    },

    canChangePaymentStatus() {
      return this.isUserCanChangePaymentStatus;
    },

    canChangePaymentMethod() {
      return this.isUserCanChangePaymentMethod;
    },

    canSetUserSpecifiedCost() {
      return this.isUserCanSetUserSpecifiedCost;
    },

    shouldShowRefundWarning() {
      return (
        this.canChangePaymentStatus &&
        this.canChangePaymentMethod &&
        this.paymentStatus !== OrderPaymentStatusEnum.NEW &&
        this.paymentMethod === PaymentMethodEnum.CARD
      );
    },

    canSetAvailableTo() {
      return (
        this.isUserCanSetAvailableTo &&
        this.paymentMethod === PaymentMethodEnum.CARD &&
        this.paymentStatus === OrderPaymentStatusEnum.NEW
      );
    },

    minAvailableTo() {
      return DateTime.local().plus({ minutes: 10 }).toFormat(this.availableToFormat);
    },

    maxAvailableTo() {
      return this.dateTimeFrom.toFormat(this.availableToFormat);
    },
  },

  watch: {
    'formData.availableTo': function (value) {
      this.changeAvailableTo(value);
    },
  },

  mounted() {
    if (this.availableTo && this.availableTo.isLuxonDateTime) {
      this.formData.availableTo = this.availableTo.toFormat(this.availableToFormat);
    }

    this.isReady = true;
  },

  methods: {
    changeCostType(costType) {
      this.$emit('change-cost-type', costType);
    },

    changeMainFormData(formData) {
      this.$emit('change', formData);
    },

    changeRoom(roomId) {
      this.$emit('change-room', roomId);
    },

    changePaymentMethod(paymentMethod) {
      this.$emit('change-payment-method', paymentMethod);
    },

    changePaymentStatus(paymentStatus) {
      this.$emit('change-payment-status', paymentStatus);
    },

    changeUseUserSpecifiedCost(useUserSpecifiedCost) {
      this.$emit('change-use-user-specified-cost', !!useUserSpecifiedCost);
    },

    changeUserSpecifiedCost(userSpecifiedCost) {
      this.$emit('change-user-specified-cost', parseInt(userSpecifiedCost, 10));
    },

    changeUseAvailableTo(useAvailableTo) {
      this.$emit('change-use-available-to', !!useAvailableTo);
    },

    changeAvailableTo(value) {
      if (value) {
        this.$emit('change-available-to', DateTime.fromISO(value));
      } else {
        this.$emit('change-available-to', null);
      }
    },

    humanCost(cost) {
      return CostHelper.humanCost(cost);
    },

    confirm() {
      this.$emit('next');
    },
  },
};
</script>

<style>
.fasti-date-picker .v-date-picker-title__date {
  font-size: 14px;
}
</style>
