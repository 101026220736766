<template>
  <div v-if="rooms.length > 1" class="text-center">
    <v-btn
      v-for="room in rooms"
      :key="room.id"
      small
      :color="room.calendar.backgroundColor"
      dark
      class="mr-2 mb-2"
      :outlined="!isRoomSelected(room.id)"
      @click="toggleRoom(room.id)"
    >
      {{ room.name }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'RoomSelect',

  props: {
    selectedRooms: {
      type: Array,
      required: false,
      default: () => [],
    },

    rooms: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  methods: {
    toggleRoom(roomId) {
      const index = this.getRoomIndex(roomId);

      if (index >= 0) {
        this.selectedRooms.splice(index, 1);
      } else {
        this.selectedRooms.push(roomId);
      }

      this.$emit('select', this.selectedRooms);
    },

    getRoomIndex(roomId) {
      return this.selectedRooms.findIndex((id) => id === roomId);
    },

    isRoomSelected(roomId) {
      return this.selectedRooms.length === 0 || this.getRoomIndex(roomId) !== -1;
    },
  },
};
</script>

<style scoped></style>
