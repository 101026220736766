import EditorView from '@/packages/localization/views/editor.view';

const routes = [
  {
    path: '/lang',
    name: 'lang.editor',
    component: EditorView,
  },
];

export default routes;
