<template>
  <div>
    <div class="order-calendar__loader">
      <v-progress-linear v-show="isLoading" indeterminate color="primary" />
    </div>

    <v-calendar
      ref="calendar"
      v-model="startDate"
      type="category"
      event-overlap-mode="column"
      :event-overlap-threshold="0"
      :event-category="getEventCategory"
      :categories="categories"
      category-show-all
      :category-days="daysInCalendar"
      :events="events"
      :first-time="placeTimeFrom"
      :interval-minutes="intervals.minutes"
      :interval-count="intervals.count"
      :interval-height="intervalHeight"
      :short-intervals="false"
      :event-ripple="false"
      color="primary"
      event-color="white"
      :locale="locale"
      :now="now"
      @click:time-category="createOrder"
    >
      <template v-slot:event="{ event }">
        <div
          class="fill-height fasti-event"
          :class="{ linkable: isLinkableEvent(event) }"
          :style="getEventGradientStyles(event)"
          @click="openEvent(event)"
        >
          <template v-if="isUserCanOrdersGetAll">
            <v-system-bar lights-out window height="20" class="pt-1">
              <small>{{ getTime(event.start) }} - {{ getTime(event.end) }}</small>
            </v-system-bar>

            <div class="px-2 mt-0">
              {{ event.user.lastName }} {{ event.user.firstName.slice(0, 1) }}
            </div>
          </template>

          <div v-else class="pl-1 pt-1">
            {{ getTime(event.start) }} &mdash; {{ getTime(event.end) }}
          </div>
        </div>
      </template>

      <template v-slot:day-body="{ date, week }">
        <div
          class="v-current-time"
          :class="{ first: date === week[0].date }"
          :style="{ top: nowY }"
        />
      </template>
    </v-calendar>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import PermissionMixin from '@/packages/permission/mixins/permission.mixin';
import SelectedPlaceMixin from '@/packages/order/mixins/selected-place.mixin';
import StringHelper from '@/common/helpers/string.helper';
import hexToRgb from '@/common/lib/hexToRgb';

export default {
  name: 'Calendar',

  mixins: [PermissionMixin, SelectedPlaceMixin],

  props: {
    startDate: {
      type: String,
      required: true,
    },

    daysInCalendar: {
      type: Number,
      required: true,
    },

    rooms: {
      type: Array,
      required: false,
      default: () => [],
    },

    orders: {
      type: Array,
      required: false,
      default: () => [],
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isReady: false,

      now: null,
      minimalDate: null,

      intervals: {
        first: 0,
        minutes: 60,
        count: 24,
      },
    };
  },

  computed: {
    categories() {
      return this.rooms.map((room) => room.id);
    },

    cal() {
      return this.isReady ? this.$refs.calendar : null;
    },

    nowY() {
      let pixels = 0;

      if (this.cal) {
        pixels = this.cal.timeToY(this.cal.times.now);
      }

      return this.cal ? `${pixels}px` : '-10px';
    },

    locale() {
      return window.navigator.language;
    },

    events() {
      return this.orders.map((order) => {
        // new Date используется для правильного отображения времени
        // в случае, если local timezone отличается от event timezone
        const event = {
          id: order.id,
          isOwner: order.isOwner,
          timed: true,
          start: new Date(
            order.dateFrom.year,
            order.dateFrom.month - 1,
            order.dateFrom.day,
            order.dateFrom.hour,
            order.dateFrom.minute,
            order.dateFrom.second
          ),
          end: new Date(
            order.dateTo.year,
            order.dateTo.month - 1,
            order.dateTo.day,
            order.dateTo.hour,
            order.dateTo.minute,
            order.dateTo.second
          ),
          dateFrom: order.dateFrom,
          dateTo: order.dateTo,
          room: order.room,
          permissions: order.permissions,
        };

        if (order.user) {
          event.user = order.user;
        }

        return event;
      });
    },

    intervalHeight() {
      return this.events.length === 0 || !('user' in this.events[0]) ? 30 : 40;
    },
  },

  watch: {
    placeId() {
      this.initCalendarIntervals();
    },
  },

  mounted() {
    const time = DateTime.local().setZone(this.placeTimezone);

    this.minimalDate = time.toISODate();
    this.now = time.toFormat('y-LL-dd HH:mm:ss');

    this.initCalendarIntervals();

    this.isReady = true;

    this.scrollToTime();
    this.updateTime();
  },

  destroyed() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  methods: {
    initCalendarIntervals() {
      const start = this.placeTimeFrom.split(':');
      const startHour = parseInt(start[0], 10);
      // const startMinutes = parseInt(start[1], 10);

      const end = this.placeTimeTo.split(':');
      const endHour = parseInt(end[0], 10);
      // const endMinutes = parseInt(end[1], 10);

      this.intervals.count =
        (((endHour === 0 ? 24 : endHour) - startHour) * 60) / this.intervals.minutes;
    },

    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0;
    },

    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },

    updateTime() {
      this.intervalId = setInterval(() => {
        const currentDateTime = DateTime.local().setZone(this.placeTimezone);

        this.now = currentDateTime.toFormat('y-LL-dd HH:mm:ss');
        this.minimalDate = currentDateTime.toISODate();

        if (DateTime.fromISO(this.startDate) < currentDateTime) {
          this.startDate = currentDateTime.toISODate();
        }

        this.cal.updateTimes();
      }, 60 * 1000);
    },

    getEventCategory(event) {
      return event.room.id;
    },

    isLinkableEvent(event) {
      return event.permissions.viewable;
    },

    openEvent(event) {
      if (!this.isLinkableEvent(event)) {
        return;
      }

      this.$emit('view-order', event.id);
    },

    createOrder(date) {
      const eventDateTime = DateTime.fromObject(
        {
          year: date.year,
          month: date.month,
          day: date.day,
          hour: date.hour,
          minute: date.minute,
        },
        {
          zone: this.placeTimezone,
        }
      );

      const filteredEvents = this.events.filter((event) => {
        const eventStart = event.dateFrom;
        const eventEnd = event.dateTo;

        return (
          event.room.id === date.category &&
          eventDateTime >= eventStart &&
          eventDateTime <= eventEnd
        );
      });

      if (filteredEvents.length > 0) {
        return;
      }

      this.$emit('create-order', {
        roomId: date.category,
        dateTime: eventDateTime,
      });
    },

    getTime(time) {
      let hour = this.padTime(time.getHours());
      let minute = this.padTime(time.getMinutes());

      if (hour === 23 && minute === 59) {
        hour = '00';
        minute = '00';
      }

      return `${hour}:${minute}`;
    },

    padTime(value) {
      return value < 10 ? `0${value}` : value;
    },

    getHumanPhone(phone) {
      return StringHelper.humanPhone(phone);
    },

    getEventGradientStyles(event) {
      const hexColor = event.room.calendar.backgroundColor;

      if (event.isOwner === false) {
        return {
          background: hexColor,
        };
      }

      const rgb = hexToRgb(hexColor);

      const deg = 120;

      const percentsOpacity = {
        0: 1,
        14: 1,
        15: 0.8,
        24: 0.8,
        25: 1,
        34: 1,
        35: 0.8,
        44: 0.8,
        45: 1,
        54: 1,
        55: 0.8,
        64: 0.8,
        65: 1,
        74: 1,
        75: 0.8,
        84: 0.8,
        85: 1,
        100: 1,
      };

      const gradient = [`${deg}deg`];

      Object.keys(percentsOpacity).forEach((percent) => {
        const opacity = percentsOpacity[percent];
        gradient.push(`rgba(${rgb.r},${rgb.g},${rgb.b},${opacity}) ${percent}%`);
      });

      return {
        background: [hexColor, `linear-gradient(${gradient.join(',')})`],
      };
    },
  },
};
</script>

<style lang="scss">
.order-calendar__loader {
  height: 4px;
}

.v-calendar {
  .v-calendar-daily__day {
    min-width: 0 !important;
  }

  .v-calendar-category__column-header {
    min-width: 0 !important;
  }

  .v-calendar-daily_head-weekday,
  .v-calendar-daily_head-day-label {
    position: unset !important;
    left: unset !important;
    width: unset !important;
  }

  .v-calendar-category__columns {
    .v-current-time {
      height: 1px;
      background-color: #ea4335;
      position: absolute;
      left: -1px;
      right: 0;
      pointer-events: none;
    }

    .v-calendar-category__column:first-child .v-current-time {
      & .first::before {
        content: '';
        position: absolute;
        background-color: #ea4335;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-top: -3px;
        margin-left: -3.5px;
      }
    }
  }

  .v-calendar-category__category {
    display: none;
  }

  .v-calendar-category__column {
    border: none !important;
  }

  .v-event-timed-container {
    margin-right: 0 !important;
  }

  .v-event-timed {
    .fasti-event {
      font-size: 12px !important;

      a {
        color: white;
      }
    }

    .fasti-event.linkable {
      cursor: pointer;
    }
  }

  .v-btn--fab {
    width: 30px !important;
    height: 30px !important;
  }

  .v-event-timed {
    font-size: 10px !important;
    cursor: default !important;
  }

  .v-calendar-category__column {
    cursor: cell !important;
  }
}

.theme--light.v-calendar-daily .v-calendar-daily__intervals-body {
  border-bottom: 1px solid #e0e0e0;
}
</style>
