import axios from '@/plugins/axios';

function prolongOrder(id, minutes) {
  return axios.patch(`/order/${id}/prolong`, { minutes }).then((response) => {
    return response.data;
  });
}

export default {
  prolongOrder,
};
