<template>
  <v-app>
    <system-error v-if="hasSystemError" />

    <template v-if="isAppReady && !hasSystemError">
      <Auth v-if="!isLoggedIn && !isPublicArea" />

      <component :is="layout" v-else>
        <router-view />

        <order-actions />
      </component>

      <user-profile-dialog v-if="showProfileModal" />
    </template>

    <main-loader v-if="isLoading" />

    <system-message />
  </v-app>
</template>

<script>
import LoginForm from '@/packages/auth/components/login-form/index.component';
import AuthService from '@/packages/auth/services/auth.service';
import { mapGetters } from 'vuex';
import MainLoader from '@/common/components/main-loader.component';
import DefaultLayout from '@/layouts/default.layout';
import AppService from '@/packages/app/services/app.service';
import UserProfileDialog from '@/packages/user/components/profile/dialog/index.component';
import SystemMessage from '@/packages/system-message/components/system-message';
import SystemError from '@/packages/system-error/components/index.component';
import LocalizationService from '@/packages/localization/services/localization.service';
import AccessTokenService from '@/common/services/access-token.service';
import Echo from '@/plugins/echo';
import PaymentMethodService from '@/packages/payment/services/payment-method.service';
import CustomerService from '@/packages/customer/services/customer.service';
import OrderActions from '@/packages/order/components/actions';
import Auth from '@/packages/auth/components/auth';

export default {
  name: 'App',

  components: {
    Auth,
    OrderActions,
    SystemError,
    SystemMessage,
    UserProfileDialog,
    MainLoader,
    LoginForm,
  },

  data() {
    return {
      isAppReady: false,

      appState: {
        isLoading: true,
        isLoaded: false,
        hasError: false,
      },

      customerState: {
        isLoading: true,
        isLoaded: false,
        hasError: false,
      },

      localizationState: {
        isLoading: true,
        isLoaded: false,
        hasError: false,
      },
    };
  },

  computed: {
    ...mapGetters([
      'isAppEnabled',
      'isCustomerEnabled',
      'isLoggedIn',
      'showProfileModal',
      'hasSystemError',
    ]),

    isLoading() {
      return this.appState.isLoading && this.customerState.isLoading;
    },

    routeMeta() {
      const route = this.$route.matched[0] || {};
      return route.meta || {};
    },

    layout() {
      if (this.routeMeta.layout) {
        return this.routeMeta.layout;
      }

      return DefaultLayout;
    },

    isPublicArea() {
      return this.routeMeta.public || false;
    },
  },

  async mounted() {
    const app = await this.loadApp();

    if (app === null || !this.isAppEnabled) {
      // TODO: Добавить сообщение о недоступности сервиса

      return;
    }

    await this.loadCustomer();

    if (!this.isCustomerEnabled) {
      // TODO: Добавить сообщение о недоступности сервиса

      return;
    }

    await this.initLocalization();

    if (AccessTokenService.hasAccessToken()) {
      await AuthService.getUserInfo();
    }

    this.isAppReady = true;

    this.initSockets();
  },

  methods: {
    async loadApp() {
      this.appState.isLoading = true;
      this.appState.isLoaded = false;
      this.appState.hasError = false;

      try {
        const app = await AppService.fetchApp();
        AppService.storeApp(app);

        this.appState.isLoaded = true;

        return app;
      } catch (error) {
        this.appState.hasError = true;

        // TODO: Заменить на отображение ошибки
        console.warn('Fetch app error: ', error);
      } finally {
        this.appState.isLoading = false;
      }

      return null;
    },

    async loadCustomer() {
      this.customerState.isLoading = true;
      this.customerState.isLoaded = false;
      this.customerState.hasError = false;

      try {
        const customer = await CustomerService.fetchCustomerInfo();
        CustomerService.storeCustomer(customer);

        this.customerState.isLoaded = true;
      } catch (error) {
        this.customerState.hasError = true;

        // TODO: Заменить на отображение ошибки
        console.warn('Fetch customer error: ', error);
      } finally {
        this.customerState.isLoading = false;
      }
    },

    async checkAuth() {
      await AuthService.getUserInfo();
    },

    async initLocalization() {
      this.localizationState.isLoading = true;
      this.localizationState.isLoaded = false;
      this.localizationState.hasError = false;

      try {
        const messages = await LocalizationService.load();
        LocalizationService.init(this.$i18n, messages);

        this.localizationState.isLoaded = true;
      } catch (error) {
        this.localizationState.hasError = true;
      } finally {
        this.localizationState.isLoading = false;
      }
    },

    loadPaymentMethods() {
      PaymentMethodService.fetchPaymentMethods().then((methods) => {
        PaymentMethodService.storeMethods(methods);
      });
    },

    initSockets() {
      Echo.init();
    },
  },
};
</script>
