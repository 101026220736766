var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h2',{staticClass:"ml-1 mb-3"},[_vm._v("Клиенты")]),_c('v-text-field',{attrs:{"placeholder":"Поиск по имени, телефону, E-mail","solo":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"pr-2",attrs:{"small":""}},[_vm._v("fas fa-search")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.table.headers,"items":_vm.filteredUsers,"loading":_vm.isLoading,"loading-text":"Загружаем список клиентов","no-data-text":"Клиенты не найдены","sort-by":"registeredAt","sort-desc":"","footer-props":{
      itemsPerPageText: 'Клиентов на страницу',
      itemsPerPageAllText: 'Все',
    }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'user-view', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.lastName)+" "+_vm._s(item.firstName)+" ")])]}},{key:"item.contacts",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"pr-2",attrs:{"small":""}},[_vm._v("fas fa-phone-volume")]),_c('a',{attrs:{"href":("tel:+" + (item.phone)),"title":"Позвонить"}},[_vm._v(_vm._s(_vm.humanPhoneNumber(item.phone)))])]}},{key:"item.registeredAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRegistrationDate(item.registeredAt))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.getRegistrationTime(item.registeredAt)))])]}},{key:"item.orders.sum",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getHumanCost(item.orders.sum))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }