<template>
  <v-dialog :value="true" persistent scrollable>
    <profile-form title="Заполните профиль" />
  </v-dialog>
</template>

<script>
import ProfileForm from '@/packages/user/components/profile/form/index.component';

export default {
  name: 'UserProfileDialog',

  components: { ProfileForm },
};
</script>
