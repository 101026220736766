var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"loading-text":"Загружаем список бронирований","no-data-text":"Бронирования не найдены","sort-by":"dateFrom","sort-desc":"","single-expand":"","expanded":_vm.table.expanded,"show-expand":false,"item-key":"id","calculate-widths":"","loading":_vm.loading,"footer-props":{
    itemsPerPageText: 'Бронирований на страницу',
    itemsPerPageAllText: 'Все',
  }},on:{"update:expanded":function($event){return _vm.$set(_vm.table, "expanded", $event)}},scopedSlots:_vm._u([{key:"item.publicId",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":_vm.getOrderStatusColor(item.status.uid)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({style:({ color: _vm.getOrderStatusColor(item.status.uid) })},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.publicId)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getOrderStatusText(item.status.uid)))])])]}},{key:"item.dateFrom",fn:function(ref){
  var item = ref.item;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('view-order', item.id)}}},[_vm._v(" "+_vm._s(_vm.getHumanDate(item.dateFrom))+" "+_vm._s(_vm.getTime(item.dateFrom))+" ")]),_c('br'),_c('small',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.getDuration(item)))])]}},{key:"item.room",fn:function(ref){
  var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.room.name))]),_c('small',{staticClass:"text--secondary"},[_vm._v(_vm._s(item.place.name))])]}},(_vm.isAdmin && _vm.showUser)?{key:"item.user",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'user-view', params: { id: item.user.id } }}},[_vm._v(" "+_vm._s(item.user.firstName)+" "+_vm._s(item.user.lastName)+" ")]),_c('br'),_c('a',{attrs:{"href":("tel:+" + (item.user.phone))}},[_vm._v(_vm._s(_vm.getHumanPhone(item.user.phone)))])]}}:null,{key:"item.cost",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":_vm.getPaymentStatusColor(item.payment.status.uid)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{attrs:{"small":"","color":_vm.getPaymentStatusColor(item.payment.status.uid),"dark":""}},[_vm._v(" "+_vm._s(item.cost.toLocaleString())+" "),_c('v-avatar',{attrs:{"right":""}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-currency-rub")])],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getPaymentStatusText(item.payment.status.uid)))])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }