import axios from '@/plugins/axios';

function confirmEmail(email, code) {
  return axios
    .get('/user/confirm-email', {
      params: {
        email,
        code,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export default { confirmEmail };
