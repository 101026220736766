<template>
  <v-card>
    <v-card-title>Бронирование создано</v-card-title>

    <v-card-text>
      <div class="text-center mt-5">
        <v-icon class="mb-10" color="success" x-large> mdi-calendar-check-outline </v-icon>
      </div>

      <p>Ваше бронирование успешно создано.</p>

      <p>
        Номер бронирования: <strong>{{ publicId }}</strong>
      </p>

      <p>
        Информация о бронировании отправлена на почту <strong>{{ userEmail }}</strong>
      </p>

      <v-alert v-if="!isCardPaymentMethod" type="success">
        В ближайшее время наш менеджер свяжется с Вами для подтверждения бронирования.
      </v-alert>

      <order-payment
        v-if="!forOtherUser && isCardPaymentMethod && !isOrderPaid"
        :available-to="availableTo"
        :cost="cost"
        :order-id="orderId"
        @cancel="$emit('cancel')"
        @paid="setStatusToPaid"
      />
    </v-card-text>

    <v-card-actions v-if="payment.isPaid || forOtherUser || !isCardPaymentMethod || isOrderPaid">
      <v-spacer />

      <v-btn color="blue darken-1" small text @click="$emit('cancel')"> Закрыть </v-btn>

      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import CostHelper from '@/common/helpers/cost.helper';
import OrderPayment from '@/packages/order/components/form/payment';
import OrderPaymentStatusEnum from '@/packages/order/enums/order-payment-status.enum';
import PaymentMethodEnum from '@/packages/payment/enums/payment-method.enum';

export default {
  name: 'OrderSuccess',

  components: { OrderPayment },

  props: {
    publicId: {
      type: String,
      default: '',
    },

    orderId: {
      type: String,
      default: '',
    },

    forOtherUser: {
      type: Boolean,
      required: false,
      default: false,
    },

    email: {
      type: String,
      required: false,
      default: '',
    },

    availableTo: {
      type: [String, null],
      required: false,
      default: null,
    },

    paymentMethod: {
      type: String,
      required: false,
      default: PaymentMethodEnum.CARD,
    },

    paymentStatus: {
      type: String,
      required: false,
      default: OrderPaymentStatusEnum.NEW,
    },

    cost: {
      type: Number,
      required: true,
    },

    useUserSpecifiedCost: {
      type: Boolean,
      required: false,
      default: false,
    },

    userSpecifiedCost: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data() {
    return {
      payment: {
        payByUserAccount: true,
        isPaid: false,
        paymentUrl: null,
        error: null,
        isLoading: false,
      },
    };
  },

  computed: {
    ...mapGetters(['user', 'userBalance']),

    userEmail() {
      if (this.forOtherUser) {
        return this.email;
      }

      return this.user.email;
    },

    isCardPaymentMethod() {
      return this.paymentMethod === PaymentMethodEnum.CARD;
    },

    isOrderPaid() {
      return this.paymentStatus === OrderPaymentStatusEnum.PAID;
    },
  },

  methods: {
    getHumanCost(cost) {
      return CostHelper.humanCost(cost);
    },

    setStatusToPaid() {
      this.payment.isPaid = true;
      this.$emit('paid');
    },
  },
};
</script>

<style scoped>
a.text--white {
  color: white;
}
</style>
