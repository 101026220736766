<template>
  <order-form
    v-if="roomState.isLoaded"
    :selected-place="order.place"
    :rooms="rooms"
    :order="order"
    @cancel="$emit('cancel', $event)"
    @updated="$emit('updated', $event)"
  />
</template>

<script>
import OrderForm from '@/packages/order/components/form';
import RoomService from '@/packages/room/services/room.service';
import SystemMessageService from '@/packages/system-message/services/system-message.service';

export default {
  name: 'OrderEdit',

  components: { OrderForm },

  props: {
    order: {
      type: Object,
      required: true,
    },

    /*
    rooms: {
      type: Array,
      required: true,
    },
     */
  },

  data() {
    return {
      roomState: {
        isLoading: false,
        isLoaded: false,
      },

      rooms: [],
    };
  },

  async mounted() {
    await this.loadRooms();
  },

  methods: {
    async loadRooms() {
      try {
        this.roomState.isLoading = true;

        const rooms = await RoomService.fetchWidgetRooms();

        this.rooms = rooms.filter((room) => room.placeId === this.order.place.id);

        this.roomState.isLoaded = true;
      } catch (e) {
        SystemMessageService.addNotify(`Ошибка загрузки данных: ${e}`, 'error');
      } finally {
        this.roomState.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
