export default {
  namespaced: true,

  state: {
    permissions: [],
  },

  actions: {
    clear({ commit }) {
      commit('clear');
    },

    setPermissions({ commit }, permissions) {
      commit('setPermissions', permissions);
    },
  },

  mutations: {
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },

    clear(state) {
      state.permissions = [];
    },
  },
};
