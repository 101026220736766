export default {
  namespaced: true,

  state: {
    code: null,
    message: null,
  },

  actions: {
    setError({ commit }, { code, message }) {
      commit('setCode', code);
      commit('setMessage', message);
    },

    clear({ commit }) {
      commit('clear');
    },
  },

  mutations: {
    setCode(state, code) {
      state.code = code;
    },

    setMessage(state, message) {
      state.message = message;
    },

    clear(state) {
      state.code = null;
      state.message = null;
    },
  },

  getters: {
    code: (state) => state.code,
    message: (state) => state.message,
  },
};
