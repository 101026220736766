<template>
  <div>
    <v-btn
      v-for="room in rooms"
      :key="room.id"
      :color="room.calendar.backgroundColor"
      :dark="room.isAvailable"
      :disabled="!room.isAvailable"
      :loading="isLoading"
      :outlined="!isRoomSelected(room.id)"
      class="mb-2 mr-2"
      small
      @click="changeRoom(room.id)"
    >
      {{ room.name }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'OrderRoomSelect',

  props: {
    roomId: {
      type: String,
      required: true,
    },

    rooms: {
      type: Array,
      required: false,
      default: () => [],
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    isRoomSelected(roomId) {
      return this.roomId === roomId;
    },

    changeRoom(roomId) {
      this.$emit('change', roomId);
    },
  },
};
</script>

<style scoped></style>
