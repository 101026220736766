import store from '@/store';

function storePermissions(permissions) {
  store.dispatch('userPermission/setPermissions', permissions);
}

function can(permissions) {
  if (!Array.isArray(permissions)) {
    permissions = [permissions];
  }

  const userPermissions = store.getters.userPermissions;

  if (!Array.isArray(userPermissions) || userPermissions.length === 0) {
    return false;
  }

  return permissions.every((permission) => userPermissions.includes(permission));
}

export default { can, storePermissions };
