<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" sm="8">
        <CheckUser
          v-if="shouldShowCheckUserForm"
          @back="showCheckUserForm"
          @checked="userChecked"
        />

        <Login
          v-if="shouldShowLoginForm"
          :phone="checkUserState.clearPhone"
          @back="showCheckUserForm"
          @reset-password="showResetPasswordForm"
          @logged-in="loggedIn"
        />

        <ResetPassword
          v-if="shouldShowResetPasswordForm"
          :phone="checkUserState.clearPhone"
          @changed="passwordChanged"
        />

        <Register
          v-if="shouldShowRegisterForm"
          :phone="checkUserState.clearPhone"
          @back="showCheckUserForm"
          @registered="registered"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ResetPassword from '@/packages/auth/components/reset-password';
import CheckUser from '@/packages/auth/components/check-user';
import Login from '@/packages/auth/components/login';
import Register from '@/packages/auth/components/register';

export default {
  name: 'Auth',

  components: { Register, Login, CheckUser, ResetPassword },

  props: {
    canProceed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      step: 'CHECK_USER',

      checkUserState: {
        isChecked: false,
        phone: '',
        clearPhone: '',
        hasUser: false,
      },

      resetPasswordShown: false,
    };
  },

  computed: {
    shouldShowCheckUserForm() {
      return this.step === 'CHECK_USER';
    },

    shouldShowLoginForm() {
      return this.step === 'LOGIN';
    },

    shouldShowRegisterForm() {
      return this.step === 'REGISTER';
    },

    shouldShowResetPasswordForm() {
      return this.step === 'RESET_PASSWORD';
    },
  },

  methods: {
    userChecked({ phone, clearPhone, hasUser }) {
      this.checkUserState.isChecked = true;
      this.checkUserState.phone = phone;
      this.checkUserState.clearPhone = clearPhone;
      this.checkUserState.hasUser = hasUser;

      this.step = hasUser ? 'LOGIN' : 'REGISTER';
    },

    loggedIn() {
      this.$emit('logged-in');

      this.next();
    },

    showResetPasswordForm() {
      this.step = 'RESET_PASSWORD';
    },

    showCheckUserForm() {
      this.step = 'CHECK_USER';
    },

    passwordChanged() {
      this.$emit('logged-in');

      this.next();
    },

    registered() {
      this.$emit('logged-in');

      this.next();
    },

    next() {
      this.$emit('next');
    },
  },
};
</script>

<style scoped></style>
