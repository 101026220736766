export default {
  namespaced: true,
  state: {
    isCreated: false,
    info: {},
    workTime: [],
  },
  actions: {
    setInfo({ commit }, info) {
      commit('setInfo', info);
    },
    setWorkTime({ commit }, workTime) {
      commit('setWorkTime', workTime);
    },
  },
  mutations: {
    setInfo(state, info) {
      state.isCreated = true;
      state.info = info;
    },
    setWorkTime(state, workTime) {
      state.workTime = workTime;
    },
    clear(state) {
      state.isLoggedIn = false;
      state.info = {};
      state.workTime = [];
    },
  },
};
