import store from '@/store';

function handleHttpErrors(error) {
  const response = error.response || {};
  const data = response.data || {};

  if (data.error && data.error.code && parseInt(data.error.code, 10) < 10000) {
    store.dispatch('systemError/setError', data.error);
  }
}

export default { handleHttpErrors };
