<script>
import PaymentStatusEnum from '@/packages/payment/enums/payment-status.enum';

export default {
  name: 'PaymentStatusMixin',

  data() {
    return {
      paymentStatusOptions: {
        [PaymentStatusEnum.NEW]: {
          value: PaymentStatusEnum.NEW,
          label: 'Новый',
          color: '#B0BEC5',
        },
        [PaymentStatusEnum.PROCESSING]: {
          value: PaymentStatusEnum.PROCESSING,
          label: 'В процессе',
          color: '#de9b1e',
        },
        [PaymentStatusEnum.PAID]: {
          value: PaymentStatusEnum.PAID,
          label: 'Оплачено',
          color: '#35BC7C',
        },
        [PaymentStatusEnum.FAIL]: {
          value: PaymentStatusEnum.FAIL,
          label: 'Отменён',
          color: '#E85F66',
        },
        [PaymentStatusEnum.REFUNDING]: {
          value: PaymentStatusEnum.REFUNDING,
          label: 'Возвращается',
          color: '#de9b1e',
        },
        [PaymentStatusEnum.REFUNDED]: {
          value: PaymentStatusEnum.REFUNDED,
          label: 'Возвращён',
          color: '#35BC7C',
        },
      },
    };
  },

  methods: {
    getStatusLabel(status) {
      return this.paymentStatusOptions[status].label;
    },

    getStatusColor(status) {
      return this.paymentStatusOptions[status].color;
    },

    isFailed(status) {
      return status === PaymentStatusEnum.FAIL;
    },
  },
};
</script>
