<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>{{ appName }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="phoneNumber"
                v-mask="'+7 (###) ###-##-##'"
                placeholder="+7 (999) 999-99-99"
                label="Номер телефона"
                name="phone"
                type="text"
                :error-messages="phoneNumberErrors"
                @blur="$v.phoneNumber.$touch()"
                @input="$v.phoneNumber.$touch()"
              />

              <small v-if="isCodeSent && !canResend" class="text--secondary">
                Вы сможете запросить код через {{ timeBeforeNextSend }} {{ secondsLabel }}
              </small>

              <small v-if="canResend">
                <a href="#" @click.stop="getCode">Повторно отправить код</a>
              </small>

              <v-text-field
                v-if="isCodeSent"
                v-model="code"
                v-mask="'####'"
                label="Код подтверждения"
                name="code"
                type="text"
                :error-messages="codeErrors"
                @blur="$v.code.$touch()"
                @input="$v.code.$touch()"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="!isCodeSent"
              :disabled="isSubmitButtonDisabled"
              color="primary"
              :loading="isSendCodeLoading"
              @click="getCode"
            >
              Получить код
            </v-btn>
            <v-btn
              v-if="isCodeSent"
              :disabled="isSubmitButtonDisabled"
              :loading="isLoginLoading"
              color="primary"
              @click="login"
            >
              Войти
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { maxLength, minLength, numeric, required } from 'vuelidate/lib/validators';
import AuthService from '@/packages/auth/services/auth.service';
import StringHelper from '@/common/helpers/string.helper';
import { mapGetters } from 'vuex';

export default {
  name: 'LoginForm',

  data() {
    return {
      phoneNumber: '',
      code: '',

      isCodeSent: false,
      isSendCodeLoading: false,
      isLoginLoading: false,

      apiErrors: {
        phoneNumber: [],
        code: [],
      },

      isTimerShown: false,
      timeBeforeNextSend: 60,
      intervalId: 0,
      canResend: false,
    };
  },

  validations() {
    if (!this.isCodeSent) {
      return {
        phoneNumber: { required },
      };
    }

    return {
      phoneNumber: { required },
      code: { required, numeric, minLength: minLength(4), maxLength: maxLength(4) },
    };
  },

  computed: {
    ...mapGetters(['appName']),

    isSubmitButtonDisabled() {
      return this.$v.$invalid;
    },

    cleanPhoneNumber() {
      return this.phoneNumber.replace(/[^\d]/g, '');
    },

    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;

      if (this.$v.phoneNumber.$invalid) {
        errors.push('Введите номер телефона');
      }

      this.apiErrors.phoneNumber.forEach((error) => {
        errors.push(error);
      });

      return errors;
    },

    codeErrors() {
      const errors = [];
      if (!this.$v.code.$dirty) return errors;
      if (this.$v.code.$invalid) {
        errors.push('Введите код подтверждения');
      }

      this.apiErrors.code.forEach((error) => {
        errors.push(error);
      });

      return errors;
    },

    secondsLabel() {
      return StringHelper.declOfNum(this.timeBeforeNextSend, ['секунду', 'секунды', 'секунд']);
    },
  },

  watch: {
    phoneNumber(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.isCodeSent = false;
        clearInterval(this.intervalId);
      }
    },
  },

  methods: {
    getCode() {
      this.isSendCodeLoading = true;
      this.clearApiErrors();

      AuthService.requestCode(this.cleanPhoneNumber)
        .then(() => {
          this.isCodeSent = true;
          this.showTimer();
          this.startTimer();
        })
        .catch((error) => {
          this.setApiErrors(error);
        })
        .finally(() => {
          this.isSendCodeLoading = false;
        });
    },

    login() {
      this.isLoginLoading = true;
      this.clearApiErrors();

      AuthService.login(this.cleanPhoneNumber, this.code)
        .then(() => {})
        .catch((error) => {
          this.setApiErrors(error);
        })
        .finally(() => {
          this.isLoginLoading = false;
        });
    },

    startTimer() {
      const times = this.timeBeforeNextSend;
      this.canResend = false;

      this.intervalId = setInterval(() => {
        this.timeBeforeNextSend -= 1;

        if (this.timeBeforeNextSend <= 0) {
          clearInterval(this.intervalId);
          this.canResend = true;
          this.timeBeforeNextSend = times;
          this.hideTimer();
        }
      }, 1000);
    },

    showTimer() {
      this.isTimerShown = true;
    },

    hideTimer() {
      this.isTimerShown = false;
    },

    setApiErrors(error) {
      if (error.response && error.response.status && error.response.status === 422) {
        this.apiErrors.phoneNumber = error.response.data.errors.phone || [];
        this.apiErrors.code = error.response.data.errors.code || [];
      }
    },

    clearApiErrors() {
      this.apiErrors.phoneNumber = [];
      this.apiErrors.code = [];
    },
  },
};
</script>

<style scoped></style>
