<template>
  <v-toolbar flat color="white">
    <v-dialog ref="dialog" v-model="selectDateModal" persistent width="290px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </template>

      <v-date-picker
        :value="startDate"
        locale="ru"
        class="pb-2 fasti-date-picker"
        :first-day-of-week="1"
        :min="datePickerMinDate"
        :title-date-format="() => datePickerText"
        @change="changeDate"
      />
    </v-dialog>

    <v-btn small outlined class="mr-4" color="grey darken-2" @click="setToday"> Сегодня</v-btn>

    <v-spacer v-if="!showTitle" />

    <v-btn :disabled="isPrevButtonDisabled" small fab text color="grey darken-2" @click="prev">
      <v-icon small>mdi-chevron-left</v-icon>
    </v-btn>

    <v-btn small fab text color="grey darken-2" @click="next">
      <v-icon small>mdi-chevron-right</v-icon>
    </v-btn>

    <template v-if="showTitle">
      <v-spacer />

      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
    </template>
  </v-toolbar>
</template>

<script>
import DateHelper from '@/common/helpers/date.helper';
import { DateTime } from 'luxon';
import PermissionMixin from '@/packages/permission/mixins/permission.mixin';

export default {
  name: 'Navigation',

  mixins: [PermissionMixin],

  props: {
    startDate: {
      type: String,
      required: true,
    },

    daysInCalendar: {
      type: Number,
      required: true,
    },

    showTitle: {
      type: Boolean,
      required: false,
      default: true,
    },

    title: {
      type: String,
      required: false,
      default: '',
    },

    placeTimezone: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectDateModal: false,
      minimalDate: null,
    };
  },

  computed: {
    startDateTime() {
      return DateTime.fromISO(this.startDate);
    },

    datePickerText() {
      return DateHelper.humanDate(this.startDateTime, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },

    isPrevButtonDisabled() {
      if (this.isUserCanWidgetShowPrevPeriods) {
        return false;
      }

      return DateTime.fromISO(this.startDate) <= DateTime.local().setZone(this.placeTimezone);
    },

    datePickerMinDate() {
      if (!this.isUserCanWidgetShowPrevPeriods) {
        return DateTime.local().toISODate();
      }

      return null;
    },
  },

  methods: {
    changeDate(date) {
      this.selectDateModal = false;

      this.emitEvent(DateTime.fromISO(date));
    },

    setToday() {
      this.emitEvent(DateTime.local());
    },

    prev() {
      this.emitEvent(this.startDateTime.minus({ days: this.daysInCalendar }));
    },

    next() {
      this.emitEvent(this.startDateTime.plus({ days: this.daysInCalendar }));
    },

    /**
     * @param date {DateTime}
     */
    emitEvent(date) {
      this.$emit('change-date', date.toISODate());
    },
  },
};
</script>

<style scoped></style>
