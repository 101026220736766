<template>
  <div v-if="isReady">
    <place-select :selected-place-id="placeId" :places="places" @change="changePlace" />

    <room-select
      :rooms="placeRooms"
      :selected-rooms="selectedRooms"
      class="mt-5 mb-1"
      @select="selectedRooms = $event"
    />

    <navigation
      :start-date="startDate"
      :days-in-calendar="daysInCalendar"
      :place-timezone="placeTimezone"
      @change-date="changeDate"
    />

    <calendar
      :is-loading="isLoading"
      :start-date="startDate"
      :days-in-calendar="daysInCalendar"
      :rooms="placeRooms"
      :selected-place="selectedPlace"
      :orders="filteredOrders"
      @view-order="showOrderView"
      @create-order="showOrderCreate"
    />

    <order-create
      v-if="orderCreateState.isActive"
      :selected-place="selectedPlace"
      :rooms="placeRooms"
      :room-id="orderCreateState.roomId"
      :date-time="orderCreateState.dateTime"
      @cancel="hideOrderCreate"
      @created="loadOrders"
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import Navigation from '@/packages/order/components/calendar/navigation';
import PlaceSelect from '@/packages/order/components/common/place-select';
import RoomSelect from '@/packages/order/components/common/room-select';
import Calendar from '@/packages/order/components/calendar/calendar';
import PlaceService from '@/packages/place/services/place.service';
import RoomService from '@/packages/room/services/room.service';
import OrderService from '@/packages/order/services/order.service';
import OrderActionsMixin from '@/packages/order/mixins/order-actions.mixin';
import OrderCreate from '@/packages/order/components/create';
import EventBus from '@/common/lib/event-bus';

export default {
  name: 'OrderCalendar',

  components: {
    OrderCreate,
    Calendar,
    Navigation,
    RoomSelect,
    PlaceSelect,
  },

  mixins: [OrderActionsMixin],

  data() {
    return {
      isReady: false,
      isLoading: false,

      places: [],
      rooms: [],
      orders: [],

      selectedPlace: null,

      startDate: '',

      selectedRooms: [],
    };
  },

  computed: {
    placeId() {
      return this.selectedPlace.id;
    },

    placeTimezone() {
      return this.selectedPlace.timezone;
    },

    placeRooms() {
      return this.rooms.filter(
        (room) => room.placeId === this.selectedPlace.id && room.isActive === true
      );
    },

    daysInCalendar() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 1;
        case 'sm':
          return 3;
        case 'md':
          return 4;
        default:
          return 5;
      }
    },

    filteredOrders() {
      if (this.selectedRooms.length === 0) {
        return this.orders;
      }

      return this.orders.filter((order) => this.selectedRooms.includes(order.room.id));
    },
  },

  async mounted() {
    await this.loadPlaces();
    await this.loadRooms();

    const time = DateTime.local().setZone(this.placeTimezone);

    this.startDate = time.toISODate();

    this.isReady = true;

    this.loadOrders();

    this.subscribeToOrdersUpdatedEvent();
  },

  destroyed() {
    this.unsubscribeFromOrdersUpdatedEvent();
  },

  methods: {
    async loadPlaces() {
      try {
        this.places = await PlaceService.fetchActivePlaces();

        this.selectedPlace = this.places[0];
      } catch (error) {
        console.warn('Fetch place error: ', error);
      }
    },

    async loadRooms() {
      try {
        this.rooms = await RoomService.fetchRooms();
      } catch (error) {
        console.warn('Fetch rooms error: ', error);
      }
    },

    loadOrders() {
      this.isLoading = true;

      const startDate = DateTime.fromISO(this.startDate);
      const endDate = startDate.plus({
        days: this.daysInCalendar,
      });

      const params = {
        placeId: this.selectedPlace.id,
        dateFrom: startDate,
        dateTo: endDate,
      };

      OrderService.fetchWidgetOrders(params)
        .then((orders) => {
          this.orders = orders;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    changePlace(placeId) {
      const foundPlace = this.places.find((place) => place.id === placeId);

      if (foundPlace && foundPlace.id) {
        this.selectedPlace = foundPlace;

        this.orders = [];

        this.loadOrders();
      }
    },

    changeDate(date) {
      this.startDate = date;

      this.loadOrders();
    },

    subscribeToOrdersUpdatedEvent() {
      EventBus.$on('orders-updated', () => {
        this.loadOrders();
      });
    },

    unsubscribeFromOrdersUpdatedEvent() {
      EventBus.$off('orders-updated');
    },
  },
};
</script>

<style lang="scss"></style>
