<template>
  <user-account-status-success />
</template>

<script>
import UserAccountStatusSuccess from '@/packages/user-account/components/status/success';

export default {
  name: 'StatusSuccessView',

  components: { UserAccountStatusSuccess },
};
</script>

<style scoped></style>
