<template>
  <v-data-table
    :headers="table.headers"
    :items="items"
    :items-per-page="20"
    :loading="isLoading"
    loading-text="Загружаем историю операций"
    no-data-text="Операции не найдены"
    :footer-props="{
      itemsPerPageText: 'Записей на страницу',
      itemsPerPageAllText: 'Все',
      itemsPerPageOptions: [10, 20, 30, -1],
    }"
  >
    <template v-slot:item.createdAt="{ item }">
      {{ getHumanDate(item.createdAt) }} {{ getTime(item.createdAt) }}
    </template>

    <template v-slot:item.type="{ item }">
      <template v-if="item.entity">
        <div v-if="hasOrderLink(item.type.uid)" class="text--secondary">
          <a
            class="text--secondary text-decoration-underline"
            @click="$emit('view-order', item.entity.id)"
          >
            {{ getLabel(item.type.uid) }}
          </a>
        </div>

        <div v-else>
          {{ getLabel(item.type.uid) }}
        </div>

        <small
          v-if="item.type.uid === 'REFILL' && item.entity.uid === 'ORDER'"
          class="text--secondary"
        >
          <a
            class="text--secondary text-decoration-underline"
            @click="$emit('view-order', item.entity.id)"
          >
            Для оплаты бронирования
          </a>
        </small>
      </template>

      <template v-else>
        {{ getLabel(item.type.uid) }}
      </template>
    </template>

    <template v-slot:item.amount="{ item }">
      <div v-if="isRefill(item.type.uid)" class="green--text">
        <v-icon size="12" color="green"> fas fa-plus </v-icon>

        {{ item.amount.toLocaleString() }}
      </div>

      <div v-else class="red--text">
        <v-icon size="12" color="red"> fas fa-minus </v-icon>

        {{ item.amount.toLocaleString() }}
      </div>
    </template>
  </v-data-table>
</template>

<script>
import DateHelper from '@/common/helpers/date.helper';
import { DateTime } from 'luxon';
import UserAccountTypeEnum from '@/packages/user-account/enums/type.enum';

export default {
  name: 'UserAccountTable',

  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      table: {
        headers: [
          {
            text: 'Номер',
            align: 'start',
            sortable: false,
            value: 'publicId',
          },
          {
            text: 'Дата',
            sortable: false,
            value: 'createdAt',
          },
          {
            text: 'Операция',
            sortable: false,
            value: 'type',
          },
          {
            text: 'Сумма',
            sortable: false,
            align: 'end',
            value: 'amount',
          },
        ],
      },
    };
  },

  methods: {
    getHumanDate(date) {
      return DateHelper.humanDate(DateTime.fromSQL(date), {
        month: 'long',
        day: 'numeric',
      });
    },

    getTime(date) {
      return DateTime.fromSQL(date, { zone: 'UTC' })
        .toLocal()
        .toLocaleString(DateTime.TIME_24_SIMPLE);
    },

    isRefill(type) {
      switch (type) {
        case UserAccountTypeEnum.BONUS:
        case UserAccountTypeEnum.REFILL:
        case UserAccountTypeEnum.ORDER_CANCEL:
        case UserAccountTypeEnum.ORDER_DECREASE:
          return true;
        default:
          return false;
      }
    },

    getLabel(type) {
      switch (type) {
        case UserAccountTypeEnum.BONUS:
          return 'Бонус';
        case UserAccountTypeEnum.REFILL:
          return 'Пополнение баланса';
        case UserAccountTypeEnum.ORDER_CANCEL:
          return 'Отмена бронирования';
        case UserAccountTypeEnum.ORDER_DECREASE:
        case UserAccountTypeEnum.ORDER_INCREASE:
          return 'Изменение бронирования';
        case UserAccountTypeEnum.REFUND:
          return 'Возврат';
        case UserAccountTypeEnum.ORDER_CREATE:
          return 'Создание бронирования';
        case UserAccountTypeEnum.FINE:
          return 'Оплата штрафа';
        case UserAccountTypeEnum.WITHDRAW:
          return 'Вывод средств';
        case UserAccountTypeEnum.FEE:
          return 'Комиссия';
        default:
          return '';
      }
    },

    hasOrderLink(type) {
      switch (type) {
        case UserAccountTypeEnum.ORDER_CANCEL:
        case UserAccountTypeEnum.ORDER_DECREASE:
        case UserAccountTypeEnum.ORDER_INCREASE:
        case UserAccountTypeEnum.ORDER_CREATE:
          return true;
        default:
          return false;
      }
    },
  },
};
</script>

<style scoped></style>
