<template>
  <div>
    <v-list class="fasti__user-stat mt-0 pt-0 px-0" dense flat>
      <v-list-item class="pa-0" dense two-line>
        <v-list-item-content class="mt-0 pt-0">
          <v-list-item-title>Создано</v-list-item-title>

          <v-list-item-subtitle>{{ humanCreatedAt }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <div class="text-center">
      <div v-if="canApprove" class="mb-2">
        <v-btn color="green" dark small @click="showApproveOrderModal(order.id)">
          Подтвердить
        </v-btn>
      </div>

      <div v-if="canEdit" class="mb-2">
        <v-btn color="primary" dark small @click="showEditOrderModal(order.id)"> Изменить </v-btn>
      </div>

      <div v-if="canProlong" class="mb-2">
        <v-btn color="primary" dark small @click="showProlongOrderModal(order.id)">
          Продлить
        </v-btn>
      </div>

      <div v-if="isNeedPay" class="mb-2">
        <v-btn color="green darken-1" dark small @click="showPayOrderModal(order.id)">
          Оплатить
        </v-btn>
      </div>

      <div v-if="canCancel" class="mb-2">
        <v-btn color="red darken-1" dark small @click="showCancelOrderModal(order.id)">
          Отменить
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import DateHelper from '@/common/helpers/date.helper';
import OrderPaymentStatusEnum from '@/packages/order/enums/order-payment-status.enum';
import { mapGetters } from 'vuex';
import OrderActionsMixin from '@/packages/order/mixins/order-actions.mixin';

export default {
  name: 'OrderActionsMenu',

  mixins: [OrderActionsMixin],

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['isPaymentEnabled']),

    canApprove() {
      return (
        this.order.payment.method.uid === 'CASH' &&
        this.order.permissions &&
        this.order.permissions.canApprove
      );
    },

    canCancel() {
      return this.order.permissions && this.order.permissions.canCancel;
    },

    isNeedPay() {
      return (
        this.isPaymentEnabled &&
        this.order.payment.method.uid === 'CARD' &&
        (this.order.payment.status.uid === OrderPaymentStatusEnum.NEW ||
          this.order.payment.status.uid === OrderPaymentStatusEnum.PARTIALLY_PAID) &&
        (this.canCancel || this.canProlong)
      );
    },

    canEdit() {
      return this.order.permissions && this.order.permissions.canEdit;
    },

    canProlong() {
      // TODO: Добавить ограничение на продление, если бронирование заканчивается в конце дня
      return this.order.permissions && this.order.permissions.canProlong;
    },

    humanCreatedAt() {
      const date = DateHelper.humanDate(this.order.createdAt);
      const time = DateHelper.humanDate(this.order.createdAt, DateTime.TIME_24_SIMPLE);

      return `${date} ${time}`;
    },
  },
};
</script>
