export default {
  namespaced: true,

  state: {
    id: null,
    isEnabled: false,
    settings: {
      payment: {
        isEnabled: false,
        methods: [],
      },
    },
  },

  actions: {
    clear({ commit }) {
      commit('clear');
    },

    setCustomer({ commit }, customer) {
      commit('setCustomer', customer);
    },
  },

  mutations: {
    setCustomer(state, customer) {
      state.id = customer.id;
      state.isEnabled = customer.isEnabled;
      state.settings = {
        payment: {
          isEnabled: customer.settings.payment.isEnabled,
          methods: customer.settings.payment.methods,
        },
      };
    },

    clear(state) {
      state.id = null;
      state.isEnabled = false;
      state.settings = {
        payment: {
          isEnabled: false,
          methods: [],
        },
      };
    },
  },
};
