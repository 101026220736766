<template>
  <order-form
    :selected-place="selectedPlace"
    :rooms="rooms"
    :room-id="roomId"
    :date-time="dateTime"
    @cancel="$emit('cancel', $event)"
    @created="$emit('created', $event)"
    @logged-in="$emit('logged-in', $event)"
  />
</template>

<script>
import PermissionMixin from '@/packages/permission/mixins/permission.mixin';
import SelectedPlaceMixin from '@/packages/order/mixins/selected-place.mixin';
import OrderForm from '@/packages/order/components/form';

export default {
  name: 'OrderCreate',

  components: { OrderForm },

  mixins: [PermissionMixin, SelectedPlaceMixin],

  props: {
    rooms: {
      type: Array,
      required: true,
    },

    roomId: {
      type: String,
      required: true,
    },

    dateTime: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
