import { DateTime } from 'luxon';

function humanDate(date, format) {
  if (!date.isLuxonDateTime) {
    return '';
  }

  return date.toLocaleString(format || DateTime.DATE_FULL);
}

function humanDateTimeFromIso(date) {
  const luxonDate = DateTime.fromSQL(date, { zone: 'UTC' }).setZone('local');

  return luxonDate.toLocaleString(DateTime.DATETIME_MED);
}

export default { humanDate, humanDateTimeFromIso };
