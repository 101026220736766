<template>
  <order-calendar />
</template>

<script>
import OrderCalendar from '@/packages/order/components/calendar';

export default {
  name: 'CreateOrderView',

  components: { OrderCalendar },
};
</script>

<style scoped></style>
