import axios from '@/plugins/axios';

/**
 * @param userId
 * @return {Promise<{count: number, sold: number, paid: number}>}
 */
function fetchOrdersStatistics(userId) {
  return axios.get(`/user/${userId}/stat/orders`).then((response) => {
    return response.data;
  });
}

export default { fetchOrdersStatistics };
