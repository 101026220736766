<template>
  <v-row dense>
    <v-col :cols="cols">
      <v-dialog ref="dialog" v-model="selectDateModal" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :value="datePickerText" label="Период" readonly v-bind="attrs" v-on="on" />
        </template>

        <v-date-picker
          v-model="formData.dateRange"
          locale="ru"
          :first-day-of-week="1"
          class="pb-3 fasti-date-picker"
          range
          :selected-items-text="datePickerText"
          :min="minimalDate"
          @input="changeDateRange"
          @change="changeDateRange"
        />
      </v-dialog>
    </v-col>

    <v-col v-if="showVisitors" :cols="cols">
      <v-select
        v-model="formData.visitors"
        label="Количество посетителей"
        :items="visitorsItems"
        :disabled="visitorsItems.length <= 1"
        menu-props="auto"
      />
    </v-col>
  </v-row>
</template>

<script>
import DateHelper from '@/common/helpers/date.helper';
import DateTimeHelper from '@/common/helpers/date-time.helper';
import { DateTime } from 'luxon';
import SelectedPlaceMixin from '@/packages/order/mixins/selected-place.mixin';

export default {
  name: 'ManyDaysOrder',

  mixins: [SelectedPlaceMixin],

  props: {
    minimalDateTime: {
      type: Object,
      required: true,
    },

    room: {
      type: Object,
      required: true,
    },

    dateTimeFrom: {
      type: Object,
      required: true,
    },

    dateTimeTo: {
      type: Object,
      required: true,
    },

    visitors: {
      type: Number,
      required: false,
      default: 1,
    },
  },

  data() {
    return {
      formData: {
        dateRange: [],

        dateTimeFrom: null,
        dateTimeTo: null,
        visitors: 1,
      },

      minimalDate: '',

      selectDateModal: false,
    };
  },

  computed: {
    cols() {
      if (this.$vuetify.breakpoint.name === 'xs' || !this.showVisitors) {
        return 12;
      }

      return 6;
    },

    datePickerText() {
      const startDateTime = DateTime.fromISO(this.formData.dateRange[0]);
      const endDateTime = DateTime.fromISO(
        this.formData.dateRange[1] || this.formData.dateRange[0]
      );

      const startFormat = {
        day: 'numeric',
      };

      if (startDateTime.month !== endDateTime.month) {
        startFormat.month = 'long';
      }

      if (startDateTime.year !== endDateTime.year) {
        startFormat.year = 'numeric';
      }

      if (startDateTime.day === endDateTime.day) {
        return DateHelper.humanDate(DateTime.fromISO(this.formData.dateRange[0]), {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
      }

      const start = DateHelper.humanDate(startDateTime, startFormat);
      const end = DateHelper.humanDate(DateTime.fromISO(this.formData.dateRange[1]), {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      return `${start} - ${end}`;
    },

    visitorsItems() {
      const visitors = this.room.visitors || 1;

      return new Array(visitors).fill(1).map((_value, index) => index + 1);
    },

    showVisitors() {
      return this.visitorsItems.length > 1;
    },
  },

  mounted() {
    this.initDefaults();
  },

  methods: {
    initDefaults() {
      // Минимальное время для календаря
      this.minimalDate = this.minimalDateTime.toISODate();

      this.formData.dateTimeFrom = this.dateTimeFrom;
      this.formData.dateTimeTo = this.dateTimeTo;
      this.formData.visitors = this.visitors;

      let dateTo = DateTimeHelper.appendTime(
        this.formData.dateTimeTo,
        this.placeTimeTo
      ).toISODate();

      if (this.placeTimeTo === '00:00') {
        dateTo = this.formData.dateTimeTo.minus({ days: 1 }).endOf('day').toISODate();
      }

      this.formData.dateRange = [this.formData.dateTimeFrom.toISODate(), dateTo];
    },

    changeDateRange() {
      if (this.formData.dateRange.length < 2) {
        return;
      }

      const start = this.formData.dateRange[0];
      const end = this.formData.dateRange[1];

      let startDate = start;
      let endDate = end;

      if (DateTime.fromISO(start) > DateTime.fromISO(end)) {
        startDate = end;
        endDate = start;
      }

      if (startDate === endDate) {
        endDate = DateTime.fromISO(endDate).plus({ days: 1 }).toISODate();
      }

      this.formData.dateRange = [startDate, endDate];
      this.selectDateModal = false;

      this.emitChanges();
    },

    changeVisitors() {
      this.emitChanges();
    },

    emitChanges() {
      const start = DateTime.fromFormat(
        `${this.formData.dateRange[0]} ${this.placeTimeFrom}`,
        'y-LL-dd HH:mm',
        {
          zone: this.placeTimezone,
        }
      );

      let end = DateTime.fromFormat(
        `${this.formData.dateRange[1]} ${this.placeTimeTo}`,
        'y-LL-dd HH:mm',
        {
          zone: this.placeTimezone,
        }
      );

      if (this.placeTimeTo === '00:00') {
        end = end.plus({ days: 1 }).startOf('day');
      }

      const formData = {
        dateTimeFrom: start,
        dateTimeTo: end,
        visitors: this.formData.visitors,
      };

      this.$emit('change', formData);
    },
  },
};
</script>

<style scoped></style>
