<script>
export default {
  name: 'SelectedPlaceMixin',

  props: {
    selectedPlace: {
      type: Object,
      required: true,
    },
  },

  computed: {
    placeId() {
      return this.selectedPlace.id;
    },

    placeTimezone() {
      return this.selectedPlace.timezone;
    },

    placeTimeFrom() {
      return this.selectedPlace.timeFrom;
    },

    placeTimeTo() {
      return this.selectedPlace.timeTo;
    },
  },
};
</script>
