import axios from '@/plugins/axios';

/**
 * @param id
 * @return {Promise<{isTimeExceeded: boolean, canRefund: boolean, canRefundToCard: boolean}>}
 */
function fetchRefundOptions(id) {
  return axios.get(`/order/${id}/refund-options`).then((response) => response.data);
}

function cancelOrder(id, needRefundToCard) {
  return axios.patch(`/order/${id}/cancel`, { needRefundToCard }).then((response) => {
    return response.data;
  });
}

export default {
  fetchRefundOptions,
  cancelOrder,
};
