<template>
  <v-row dense>
    <v-col :cols="cols">
      <v-dialog ref="dialog" v-model="selectDateModal" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :value="datePickerText" label="Дата" readonly v-bind="attrs" v-on="on" />
        </template>

        <v-date-picker
          v-model="formData.date"
          locale="ru"
          :first-day-of-week="1"
          class="pb-3 fasti-date-picker"
          :min="minimalDate"
          :title-date-format="() => datePickerText"
          @change="changeDate"
        />
      </v-dialog>
    </v-col>

    <v-col v-if="showVisitors" :cols="cols">
      <v-select
        v-model="formData.visitors"
        label="Количество посетителей"
        :items="visitorsItems"
        :disabled="visitorsItems.length <= 1"
        menu-props="auto"
        @change="changeVisitors"
      />
    </v-col>
  </v-row>
</template>

<script>
import DateHelper from '@/common/helpers/date.helper';
import { DateTime } from 'luxon';
import TimeHelper from '@/common/helpers/time.helper';
import DateTimeHelper from '@/common/helpers/date-time.helper';
import SelectedPlaceMixin from '@/packages/order/mixins/selected-place.mixin';

export default {
  name: 'DailyOrder',

  mixins: [SelectedPlaceMixin],

  props: {
    room: {
      type: Object,
      required: true,
    },

    dateTime: {
      type: Object,
      required: true,
    },

    visitors: {
      type: Number,
      required: false,
      default: 1,
    },
  },

  data() {
    return {
      formData: {
        date: '',
        visitors: 1,

        dateTime: null,
      },

      minimalDate: null,

      selectDateModal: false,
    };
  },

  computed: {
    placeCurrentDateTime() {
      return DateTime.local().setZone(this.placeTimezone).startOf('minute');
    },

    placeStartDateTime() {
      const time = TimeHelper.parseTime(this.placeTimeFrom);

      return this.placeCurrentDateTime.set({
        hour: time.hour,
        minute: time.minute,
      });
    },

    cols() {
      if (this.$vuetify.breakpoint.name === 'xs' || !this.showVisitors) {
        return 12;
      }

      return 6;
    },

    datePickerText() {
      return DateHelper.humanDate(DateTime.fromISO(this.formData.date), {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },

    visitorsItems() {
      const visitors = this.room.visitors || 1;

      return new Array(visitors).fill(1).map((_value, index) => index + 1);
    },

    showVisitors() {
      return this.visitorsItems.length > 1;
    },
  },

  mounted() {
    this.initDefaults();
  },

  methods: {
    initDefaults() {
      this.formData.dateTime = this.dateTime;
      this.formData.visitors = this.visitors;

      this.formData.date = this.formData.dateTime.toISODate();
      this.minimalDate = this.formData.dateTime.toISODate();
    },

    changeDate() {
      this.selectDateModal = false;

      this.emitChanges();
    },

    changeVisitors() {
      this.emitChanges();
    },

    emitChanges() {
      const start = DateTime.fromFormat(
        `${this.formData.date} ${this.placeTimeFrom}`,
        'y-LL-dd HH:mm',
        {
          zone: this.placeTimezone,
        }
      );

      const end = DateTimeHelper.appendTime(start, this.placeTimeTo);

      const formData = {
        dateTimeFrom: start,
        dateTimeTo: end,
        visitors: this.formData.visitors,
      };

      this.$emit('change', formData);
    },
  },
};
</script>

<style scoped></style>
