<template>
  <v-data-table
    :headers="tableHeaders"
    :items="items"
    loading-text="Загружаем список бронирований"
    no-data-text="Бронирования не найдены"
    sort-by="dateFrom"
    sort-desc
    single-expand
    :expanded.sync="table.expanded"
    :show-expand="false"
    item-key="id"
    calculate-widths
    :loading="loading"
    :footer-props="{
      itemsPerPageText: 'Бронирований на страницу',
      itemsPerPageAllText: 'Все',
    }"
  >
    <template v-slot:item.publicId="{ item }">
      <v-tooltip top :color="getOrderStatusColor(item.status.uid)">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" :style="{ color: getOrderStatusColor(item.status.uid) }" v-on="on">
            {{ item.publicId }}
          </span>
        </template>

        <span>{{ getOrderStatusText(item.status.uid) }}</span>
      </v-tooltip>
    </template>

    <template v-slot:item.dateFrom="{ item }">
      <a href="#" @click.prevent="$emit('view-order', item.id)">
        {{ getHumanDate(item.dateFrom) }} {{ getTime(item.dateFrom) }}
      </a>

      <br />

      <small class="text--secondary">{{ getDuration(item) }}</small>
    </template>

    <template v-slot:item.room="{ item }">
      <div>{{ item.room.name }}</div>

      <small class="text--secondary">{{ item.place.name }}</small>
    </template>

    <template v-if="isAdmin && showUser" v-slot:item.user="{ item }">
      <router-link :to="{ name: 'user-view', params: { id: item.user.id } }">
        {{ item.user.firstName }} {{ item.user.lastName }}
      </router-link>

      <br />

      <a :href="`tel:+${item.user.phone}`">{{ getHumanPhone(item.user.phone) }}</a>
    </template>

    <template v-slot:item.cost="{ item }">
      <v-tooltip top :color="getPaymentStatusColor(item.payment.status.uid)">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-chip small :color="getPaymentStatusColor(item.payment.status.uid)" dark>
              {{ item.cost.toLocaleString() }}

              <v-avatar right>
                <v-icon x-small>mdi-currency-rub</v-icon>
              </v-avatar>
            </v-chip>
          </span>
        </template>

        <span>{{ getPaymentStatusText(item.payment.status.uid) }}</span>
      </v-tooltip>
    </template>

    <!--
    <template v-slot:item.actions="{ item }">
      <order-menu
        :order="item"
        @cancel-order="$emit('cancel-order', item.id)"
        @pay-order="$emit('pay-order', item.id)"
        @view-order="$emit('view-order', item.id)"
      />
    </template>
    -->
  </v-data-table>
</template>

<script>
import { DateTime } from 'luxon';
import DateHelper from '@/common/helpers/date.helper';
import TimeHelper from '@/common/helpers/time.helper';
// import OrderMenu from '@/packages/order/components/table/order-menu';
import OrderStatusMixin from '@/packages/order/mixins/order-status.mixin';
import PaymentStatusMixin from '@/packages/order/mixins/payment-status.mixin';
import UserPermissionService from '@/packages/user/services/user-permission.service';
import * as Permissions from '@/packages/user/permissions';
import CostConstants from '@/packages/order/inventory/cost.constants';
import StringHelper from '@/common/helpers/string.helper';

export default {
  name: 'OrdersTable',

  // components: { OrderMenu },

  mixins: [OrderStatusMixin, PaymentStatusMixin],

  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    showUser: {
      type: Boolean,
      required: false,
      default: false,
    },

    linkFrom: {
      type: [String, null],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      table: {
        headers: [
          {
            text: 'Номер',
            align: 'start',
            sortable: true,
            value: 'publicId',
          },
          {
            text: 'Дата',
            align: 'start',
            sortable: true,
            value: 'dateFrom',
          },
          {
            text: 'Длительность',
            align: 'start',
            sortable: true,
            value: 'duration',
          },
          {
            text: this.$t('room.label'),
            align: 'start',
            sortable: false,
            value: 'room',
          },
          {
            text: 'Стоимость',
            align: 'start',
            sortable: true,
            value: 'cost',
          },
          /*
          {
            text: '',
            align: 'end',
            sortable: false,
            value: 'actions',
          },
           */
        ],

        expanded: [],
      },

      changingOrderId: null,
      isApproveLoading: false,
      isCancelLoading: false,
      isPaymentLoading: false,
    };
  },

  computed: {
    isAdmin() {
      return UserPermissionService.can(Permissions.CUSTOMERS_MANAGE_ROOMS);
    },

    tableHeaders() {
      let headers = [
        {
          text: 'Номер',
          align: 'start',
          sortable: true,
          value: 'publicId',
        },
        {
          text: 'Дата',
          align: 'start',
          sortable: true,
          value: 'dateFrom',
        },
        {
          text: this.$t('room.label'),
          align: 'start',
          sortable: false,
          value: 'room',
        },
      ];

      if (this.isAdmin && this.showUser) {
        headers.push({
          text: 'Клиент',
          align: 'start',
          sortable: false,
          value: 'user',
        });
      }

      headers = headers.concat([
        {
          text: 'Стоимость',
          align: 'start',
          sortable: true,
          value: 'cost',
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'actions',
        },
      ]);

      return headers;
    },
  },

  methods: {
    getHumanDate(date) {
      return DateHelper.humanDate(date, {
        month: 'long',
        day: 'numeric',
      });
    },

    getTime(date) {
      return date.toLocaleString(DateTime.TIME_24_SIMPLE);
    },

    getDuration(item) {
      if (item.costType === CostConstants.TYPE_MANY_DAYS) {
        const diff = item.dateTo.diff(item.dateFrom.startOf('day'), 'days');

        const days = Math.ceil(diff.days);
        const label = StringHelper.declOfNum(days, ['день', 'дня', 'дней']);

        return `${days} ${label}`;
      }

      if (item.costType === CostConstants.TYPE_DAILY) {
        return 'Весь день';
      }

      return TimeHelper.humanTimeFromMinutes(item.dateTo.diff(item.dateFrom, 'minute').minutes);
    },

    getHumanPhone(phone) {
      return StringHelper.humanPhone(phone);
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
