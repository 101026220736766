import axios from '@/plugins/axios';

/**
 *
 * @param $i18n {VueI18n & IVueI18n}
 * @param messages {Object}
 */
function init($i18n, messages) {
  $i18n.setLocaleMessage('ru', messages);
}

function load() {
  return axios.get('/lang').then((response) => response.data);
}

function loadSettings() {
  return axios.get('/lang/settings').then((response) => response.data.settings);
}

function saveSettings(settings) {
  return axios.post('/lang/settings', { settings }).then((response) => response.data);
}

export default { load, init, loadSettings, saveSettings };
