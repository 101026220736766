<script>
import PaymentStatusHelper from '@/packages/order/helpers/payment-status.helper';
import * as PaymentStatus from '@/packages/order/constants/payment-status';
import OrderService from '@/packages/order/services/order.service';
import SystemMessageService from '@/packages/system-message/services/system-message.service';

export default {
  name: 'PaymentStatusMixin',

  data() {
    return {
      paymentStatusOptions: [
        {
          value: PaymentStatus.NEW,
          label: 'Не оплачено',
        },
        {
          value: PaymentStatus.PAID,
          label: 'Оплачено',
        },
      ],

      isPaymentLoading: false,
    };
  },

  methods: {
    getPaymentStatusText(uid) {
      return PaymentStatusHelper.getStatusByUid(uid);
    },

    getPaymentStatusColor(uid) {
      return PaymentStatusHelper.getStatusColorByUid(uid);
    },

    changePaymentStatus(orderId, uid) {
      this.isPaymentLoading = true;

      OrderService.changePaymentStatus(orderId, uid)
        .then(() => {
          SystemMessageService.addNotify(`Статус оплаты изменён`, 'success');
          this.$emit('change-payment-status', { orderId, uid });
        })
        .catch((e) => {
          SystemMessageService.addNotify(`Ошибка изменения статуса оплаты: ${e}`, 'error');
        })
        .finally(() => {
          this.isPaymentLoading = false;
        });
    },
  },
};
</script>
