<template>
  <v-card tile flat>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model.trim="$v.form.name.$model"
            label="Название"
            :counter="150"
            persistent-hint
            :error-messages="nameErrors"
            @blur="$v.form.name.$touch()"
            @input="$v.form.name.$touch()"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model.trim="$v.form.phone.$model"
            label="Номер телефона"
            persistent-hint
            :error-messages="phoneErrors"
            @blur="$v.form.phone.$touch()"
            @input="$v.form.phone.$touch()"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-select
            v-model="$v.form.stateId.$model"
            :items="states"
            label="Регион"
            item-text="name"
            item-value="id"
            menu-props="auto"
            :return-object="false"
            :loading="statesLoading"
            persistent-hint
            :error-messages="stateErrors"
            @blur="$v.form.stateId.$touch()"
            @change="changeState"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-select
            v-model="$v.form.cityId.$model"
            :items="cities"
            :disabled="cities.length <= 1"
            label="Город"
            item-text="name"
            item-value="id"
            menu-props="auto"
            :return-object="false"
            :loading="citiesLoading"
            persistent-hint
            :error-messages="cityErrors"
            @blur="$v.form.cityId.$touch()"
            @select="$v.form.cityId.$touch()"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model.trim="$v.form.street.$model"
            label="Улица"
            :counter="150"
            persistent-hint
            :error-messages="streetErrors"
            @blur="$v.form.street.$touch()"
            @input="$v.form.street.$touch()"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model.trim="$v.form.address.$model"
            label="Адрес"
            hint="Номер дома, подъезда, этаж, номер офиса"
            :counter="150"
            persistent-hint
            :error-messages="addressErrors"
            @blur="$v.form.address.$touch()"
            @input="$v.form.address.$touch()"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="6">
          <v-select
            v-model="$v.form.timeFrom.$model"
            label="Время начала работы"
            :items="startTimes"
            menu-props="auto"
          />
        </v-col>

        <v-col cols="6">
          <v-select
            v-model="$v.form.timeTo.$model"
            label="Время завершения работы"
            :items="endTimes"
            menu-props="auto"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn color="blue darken-1" small text :to="{ name: 'customer-place' }">Назад</v-btn>

      <v-spacer></v-spacer>

      <v-btn small color="primary" :loading="isLoading" :disabled="$v.$invalid" @click="save">
        {{ saveButtonTitle }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators';
import GeoService from '@/common/services/geo.service';
import PlaceService from '@/packages/place/services/place.service';
import SystemMessageService from '@/packages/system-message/services/system-message.service';
import TimeHelper from '@/common/helpers/time.helper';

export default {
  name: 'PlaceForm',

  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },

    name: {
      type: String,
      required: false,
      default: '',
    },

    phone: {
      type: String,
      required: false,
      default: '',
    },

    stateId: {
      type: Number,
      required: false,
      default: null,
    },

    cityId: {
      type: Number,
      required: false,
      default: null,
    },

    street: {
      type: String,
      required: false,
      default: '',
    },

    address: {
      type: String,
      required: false,
      default: '',
    },

    timeFrom: {
      type: String,
      required: false,
      default: '09:00',
    },

    timeTo: {
      type: String,
      required: false,
      default: '23:00',
    },
  },

  data() {
    return {
      form: {
        name: '',
        phone: '',
        stateId: null,
        cityId: null,
        street: '',
        address: '',
        timeFrom: '09:00',
        timeTo: '23:00',
      },

      isLoading: false,

      statesLoading: true,
      states: [],

      citiesLoading: false,
      cities: [],

      startTimes: TimeHelper.generateForTimePicker({ start: '00:00', end: '23:00' }),
      endTimes: TimeHelper.generateForTimePicker({ start: '01:00', end: '00:00' }),
    };
  },

  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(150),
      },
      phone: {
        required,
      },
      stateId: {
        required,
      },
      cityId: {
        required,
      },
      street: {
        required,
        maxLength: maxLength(150),
      },
      address: {
        required,
        maxLength: maxLength(150),
      },
      timeFrom: {
        required,
      },
      timeTo: {
        required,
      },
    },
  },

  computed: {
    saveButtonTitle() {
      return this.$props.id ? 'Сохранить' : 'Добавить';
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;

      if (!this.$v.form.name.required) {
        errors.push('Введите название');
      }

      if (!this.$v.form.name.maxLength) {
        errors.push('Название слишком длинное');
      }

      return errors;
    },

    phoneErrors() {
      const errors = [];
      if (!this.$v.form.phone.$dirty) return errors;

      if (!this.$v.form.phone.required) {
        errors.push('Введите номер телефона');
      }

      return errors;
    },

    stateErrors() {
      const errors = [];
      if (!this.$v.form.stateId.$dirty) return errors;

      if (!this.$v.form.stateId.required) {
        errors.push('Выберите регион');
      }

      return errors;
    },

    cityErrors() {
      const errors = [];
      if (!this.$v.form.stateId.$dirty) return errors;

      if (!this.$v.form.stateId.required) {
        errors.push('Выберите город');
      }

      return errors;
    },

    streetErrors() {
      const errors = [];
      if (!this.$v.form.street.$dirty) return errors;

      if (!this.$v.form.street.required) {
        errors.push('Введите название улицы');
      }

      if (!this.$v.form.street.maxLength) {
        errors.push('Название улицы слишком длинное');
      }

      return errors;
    },

    addressErrors() {
      const errors = [];
      if (!this.$v.form.address.$dirty) return errors;

      if (!this.$v.form.address.required) {
        errors.push('Введите адрес');
      }

      if (!this.$v.form.address.maxLength) {
        errors.push('Адрес слишком длинный');
      }

      return errors;
    },
  },

  async mounted() {
    try {
      const result = await GeoService.fetchStates();
      this.states = result.items;
    } finally {
      this.statesLoading = false;
    }

    await this.initValues();
  },

  methods: {
    initValues() {
      this.form.name = this.name;
      this.form.phone = this.phone;
      this.form.stateId = this.stateId;
      this.form.cityId = this.cityId;
      this.form.street = this.street;
      this.form.address = this.address;
      this.form.timeFrom = this.timeFrom;
      this.form.timeTo = this.timeTo;

      if (this.form.stateId) {
        this.fetchCountries(this.form.stateId);
      }
    },

    fetchCountries(stateId) {
      this.citiesLoading = true;

      GeoService.fetchCountries(stateId)
        .then((result) => {
          this.cities = result.items;

          if (this.cities.length === 1) {
            this.form.cityId = this.cities[0].id;
          }
        })
        .finally(() => {
          this.citiesLoading = false;
        });
    },

    changeState(stateId) {
      this.$v.form.stateId.$touch();

      this.form.cityId = null;

      this.fetchCountries(stateId);
    },

    save() {
      this.isLoading = true;

      if (!this.$props.id) {
        this.create();
      } else {
        this.update();
      }
    },

    create() {
      PlaceService.create(this.prepareData())
        .then(() => {
          SystemMessageService.addNotify(
            this.$t('place.successfulAdded', { name: this.form.name }),
            'success'
          );

          this.$router.push({ name: 'customer-place' });
        })
        .catch((error) => {
          let message;
          let code = 0;

          if (error.response && error.response.data) {
            message = error.response.data.message || 'Unknown';
          }

          if (error.response && error.response.status) {
            code = error.response.status;
          }

          SystemMessageService.addNotify(`Ошибка добавления: ${message} (${code})`, 'error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    update() {
      PlaceService.update(this.id, this.prepareData())
        .then(() => {
          SystemMessageService.addNotify(
            this.$t('place.successfulSaved', { name: this.form.name }),
            'success'
          );

          this.$router.push({ name: 'customer-place' });
        })
        .catch((error) => {
          let message;
          let code = 0;

          if (error.response && error.response.data) {
            message = error.response.data.message || 'Unknown';
          }

          if (error.response && error.response.status) {
            code = error.response.status;
          }

          SystemMessageService.addNotify(`Ошибка сохранения: ${message} (${code})`, 'error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    prepareData() {
      return {
        name: this.form.name,
        phone: this.form.phone,
        stateId: this.form.stateId,
        cityId: this.form.cityId,
        address1: this.form.street,
        address2: this.form.address,
        timeFrom: this.form.timeFrom,
        timeTo: this.form.timeTo,
      };
    },
  },
};
</script>
