<template>
  <v-dialog :value="true" scrollable persistent max-width="600px">
    <div>
      <v-card class="pt-2">
        <v-card-title class="pb-2">Стоимость бронирования</v-card-title>

        <v-row v-if="costTypeItems.length > 1" align="center" justify="center" class="mb-3">
          <v-btn-toggle
            v-model="formData.costType"
            tile
            color="deep-purple accent-3"
            group
            mandatory
            @change="changeCostType"
          >
            <v-btn
              v-for="item in costTypeItems"
              :key="item.value"
              :value="item.value"
              :disabled="getCostTypeDisableState(item.value)"
              small
            >
              {{ item.label }}
            </v-btn>
          </v-btn-toggle>
        </v-row>

        <v-card-text>
          <v-row v-if="!isManyDaysType" dense>
            <v-col cols="12">
              <v-select
                v-model="formData.day"
                :items="daysItems"
                label="День недели"
                item-text="name"
                item-value="id"
                menu-props="auto"
              />
            </v-col>
          </v-row>

          <v-row v-if="isHourlyType" dense>
            <v-col cols="6">
              <v-select
                v-model="formData.timeFrom"
                label="Начало периода"
                :items="startTimes"
                menu-props="auto"
              />
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="formData.timeTo"
                label="Окончание периода"
                :items="endTimes"
                menu-props="auto"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model.number="formData.values[0].amount"
                type="number"
                label="Стоимость"
                :hint="mainCostHint"
                persistent-hint
                aria-autocomplete="none"
                autocomplete="off"
                @input="updateCostValues"
              />
            </v-col>
          </v-row>

          <template v-if="!isDailyType">
            <v-row v-for="(_item, index) in formData.values" :key="index" dense>
              <template v-if="index > 0">
                <v-col cols="5">
                  <v-select
                    v-model.number="formData.values[index].starts"
                    :items="isHourlyType ? timeItems : dateItems"
                    :label="isHourlyType ? 'Время' : 'Дни'"
                    item-text="label"
                    item-value="value"
                    menu-props="auto"
                    @change="sortCostValues"
                  />
                </v-col>

                <v-col cols="3">
                  <template v-if="isSumAmountType(formData.values[index].amountType)">
                    <v-text-field
                      v-model.number="formData.values[index].amount"
                      type="number"
                      :label="getCostAmountLabel(formData.values[index].amountType)"
                      aria-autocomplete="none"
                      autocomplete="off"
                      @input="updateCostValues"
                    />
                  </template>

                  <template v-if="isPercentAmountType(formData.values[index].amountType)">
                    <v-text-field
                      v-model.number="formData.values[index].amount"
                      type="number"
                      :label="getCostAmountLabel(formData.values[index].amountType)"
                      aria-autocomplete="none"
                      autocomplete="off"
                      :max="100"
                      :hint="getCostHint(index)"
                      persistent-hint
                      @input="updateCostValues"
                    />
                  </template>
                </v-col>

                <v-col cols="3">
                  <v-select
                    v-model="formData.values[index].amountType"
                    :items="discountTypes"
                    item-text="label"
                    item-value="value"
                    menu-props="auto"
                    @change="updateCostValues"
                  />
                </v-col>

                <v-col cols="1" class="text-right">
                  <v-btn icon color="red lighten-3" class="mt-3" @click="removeCost(index)">
                    <v-icon small class="mr-1">fas fa-trash-alt</v-icon>
                  </v-btn>
                </v-col>
              </template>
            </v-row>

            <v-row dense>
              <v-col cols="12" class="text-center">
                <v-btn
                  x-small
                  color="green lighten-2"
                  :dark="isCostValuesValid"
                  :disabled="!isCostValuesValid"
                  @click="addCostValue"
                >
                  Добавить стоимость
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" small text @click="$emit('close')">Закрыть</v-btn>

          <v-spacer></v-spacer>

          <v-btn color="primary" small :disabled="!isFormValid" @click="save">
            <template v-if="!isEdit">Добавить</template>

            <template v-else>Применить</template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import DayHelper from '@/common/helpers/day.helper';
import TimeHelper from '@/common/helpers/time.helper';
import { DateTime } from 'luxon';
import StringHelper from '@/common/helpers/string.helper';
import CostHelper from '@/common/helpers/cost.helper';
import DateTimeConstants from '@/common/constants/datetime.constants';
import CostConstants from '@/packages/order/inventory/cost.constants';

export default {
  name: 'PriceForm',

  props: {
    costId: {
      type: [Number, null],
      required: false,
      default: null,
    },

    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },

    timeFrom: {
      type: String,
      required: true,
    },

    timeTo: {
      type: String,
      required: true,
    },

    minimalOrderTime: {
      type: Number,
      required: false,
      default: 60,
    },

    orderTimeStep: {
      type: Number,
      required: false,
      default: 30,
    },

    daysAllowed: {
      type: Boolean,
      required: false,
      default: false,
    },

    manyDaysAllowed: {
      type: Boolean,
      required: false,
      default: false,
    },

    existingItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      formData: {
        day: 0,
        timeFrom: this.timeFrom,
        timeTo: this.timeTo,

        costType: CostConstants.TYPE_HOURLY,

        values: [
          {
            starts: 0,
            amount: '',
            amountType: CostConstants.AMOUNT_TYPE_SUM,
          },
        ],
      },

      times: TimeHelper.generateForTimePicker({ start: this.timeFrom, end: this.timeTo }),

      discountTypes: [
        {
          value: CostConstants.AMOUNT_TYPE_SUM,
          label: 'Фикс. цена',
        },
        {
          value: CostConstants.AMOUNT_TYPE_PERCENT,
          label: 'Скидка',
        },
      ],
    };
  },

  computed: {
    canSelectCostType() {
      return this.costTypeItems.length > 1 && !this.edit;
    },

    isFormValid() {
      return this.isCostValuesValid && !this.disabledDays[this.formData.day];
    },

    isCostValuesValid() {
      return this.formData.values.every((cost) => {
        return !(
          !Number.isInteger(cost.starts) ||
          !Number.isInteger(cost.amount) ||
          (cost.amountType === CostConstants.AMOUNT_TYPE_PERCENT && cost.amount >= 100)
        );
      });
    },

    costTypeItems() {
      const items = [
        {
          value: CostConstants.TYPE_HOURLY,
          label: 'Час',
        },
        {
          value: CostConstants.TYPE_DAILY,
          label: 'День',
        },
      ];

      const hasManyDaysCosts = this.existingItems.findIndex(
        (item) => item.costType === CostConstants.TYPE_MANY_DAYS
      );

      if ((!this.isEdit && hasManyDaysCosts < 0) || this.isEdit) {
        items.push({
          value: CostConstants.TYPE_MANY_DAYS,
          label: 'Несколько дней',
        });
      }

      return items;
    },

    isHourlyType() {
      return this.formData.costType === CostConstants.TYPE_HOURLY;
    },

    isDailyType() {
      return this.formData.costType === CostConstants.TYPE_DAILY;
    },

    isManyDaysType() {
      return this.formData.costType === CostConstants.TYPE_MANY_DAYS;
    },

    disabledDays() {
      const items = {};

      if (this.formData.costType !== CostConstants.TYPE_DAILY) {
        return items;
      }

      this.existingItems.forEach((item) => {
        if (
          (item.costType === this.formData.costType && !this.isEdit) ||
          (item.costType === this.formData.costType && this.isEdit && item.id !== this.costId)
        ) {
          items[item.day] = true;
        }
      });

      return items;
    },

    daysItems() {
      const disabledDays = this.disabledDays;

      return DayHelper.getDays().map((item) => {
        item.disabled = !!disabledDays[item.id];
        return item;
      });
    },

    mainCostHint() {
      if (this.isHourlyType) {
        return 'Рублей в час';
      }

      const startTime = TimeHelper.parseTime(this.timeFrom);
      const endTime = TimeHelper.parseTime(this.timeTo);

      const start = DateTime.local()
        .set({
          hours: startTime.hour,
          minutes: startTime.minute,
        })
        .startOf('minute');

      let end = DateTime.local()
        .set({
          hours: endTime.hour,
          minutes: endTime.minute,
        })
        .startOf('minute');

      if (this.timeTo === '00:00') {
        end = end.plus({ days: 1 }).startOf('day');
      }

      const label = TimeHelper.humanTimeFromMinutes(end.diff(start, 'minutes').minutes);

      return `Рублей за день ${this.timeFrom}-${this.timeTo} (${label})`;
    },

    startTimes() {
      const times = [...this.times];

      times.splice(times.length - 1, 1);

      return times;
    },

    endTimes() {
      const times = [...this.times];

      times.splice(0, 1);

      return times;
    },

    timeItems() {
      const startTime = TimeHelper.parseTime(this.formData.timeFrom);
      const endTime = TimeHelper.parseTime(this.formData.timeTo);

      const start = DateTime.local()
        .set({
          hours: startTime.hour,
          minutes: startTime.minute,
        })
        .plus({ hours: 1 })
        .startOf('hour');

      let end = DateTime.local()
        .set({
          hours: endTime.hour,
          minutes: endTime.minute,
        })
        .startOf('hour');

      if (this.formData.timeTo === '00:00') {
        end = end.plus({ days: 1 }).startOf('day');
      }

      const steps = end.diff(start, 'hours').hours;

      const times = [];

      const startStep = Math.ceil(this.minimalOrderTime / DateTimeConstants.MINUTES_IN_HOUR);

      for (let i = startStep; i < steps; i += 1) {
        const hours = i;
        const hourLabel = StringHelper.declOfNum(hours, ['часа', 'часов', 'часов']);
        const label = `от ${hours} ${hourLabel}`;

        times.push({
          label,
          value: hours * DateTimeConstants.MINUTES_IN_HOUR,
        });
      }

      return times;
    },

    dateItems() {
      const items = 30;
      return new Array(items).fill(1).map((_value, index) => {
        const day = index + 2;
        const label = StringHelper.declOfNum(day, ['дня', 'дней', 'дней']);

        return {
          value: day,
          label: `от ${day} ${label}`,
        };
      });
    },
  },

  mounted() {
    // this.formData.costType = this.costType;
    this.initValues();
  },

  methods: {
    initValues() {
      if (this.isEdit) {
        const costItem = this.existingItems.find((item) => item.id === this.costId);

        if (costItem && costItem.id) {
          this.formData.costType = costItem.costType;
          this.formData.values = costItem.values;

          if (costItem.costType === CostConstants.TYPE_HOURLY) {
            this.formData.day = costItem.day;
            this.formData.timeFrom = costItem.timeFrom;
            this.formData.timeTo = costItem.timeTo;
          }

          if (costItem.costType === CostConstants.TYPE_DAILY) {
            this.formData.day = costItem.day;
          }
        }
      }
    },

    getCostTypeDisableState(costType) {
      if (!this.isEdit) {
        return false;
      }

      return costType !== this.formData.costType;
    },

    isPercentAmountType(type) {
      return type === CostConstants.AMOUNT_TYPE_PERCENT;
    },

    isSumAmountType(type) {
      return type === CostConstants.AMOUNT_TYPE_SUM;
    },

    addCostValue() {
      this.formData.values.push({
        starts: '',
        amount: '',
        amountType: CostConstants.AMOUNT_TYPE_SUM,
      });
    },

    removeCost(index) {
      this.formData.values.splice(index, 1);
    },

    getCostAmountLabel(amountType) {
      return amountType === CostConstants.AMOUNT_TYPE_SUM ? 'Стоимость' : 'Проценты';
    },

    sortCostValues() {
      this.formData.values.sort((costA, costB) => costA.starts - costB.starts);

      this.updateCostValues();
    },

    updateCostValues() {
      const values = this.formData.values.map((cost, index) => {
        if (index === 0) {
          cost.cost = cost.amount;
        } else if (cost.amountType === CostConstants.AMOUNT_TYPE_SUM) {
          cost.cost = cost.amount;
        } else {
          cost.amount = Math.min(cost.amount, 100);
          const prevCost = this.formData.values[index - 1].cost;
          cost.cost = Math.round((prevCost - (prevCost / 100) * cost.amount) * 100) / 100;
        }

        return cost;
      });

      this.$set(this.formData, 'values', values);
    },

    getCostHint(index) {
      const cost = this.formData.values[index];

      if (cost && cost.amountType === CostConstants.AMOUNT_TYPE_PERCENT) {
        const humanCost = CostHelper.humanCost(cost.cost);
        const label = this.isHourlyType ? 'час' : 'день';

        return `${humanCost}/${label}`;
      }

      return '';
    },

    changeCostType() {
      this.formData.values.splice(1, this.formData.values.length);
      this.formData.day = 0;
    },

    save() {
      const data = {
        costType: this.formData.costType,
        values: this.formData.values,
        day: this.formData.day,
      };

      if (Number.isInteger(this.costId)) {
        data.id = this.costId;
      }

      if (this.isHourlyType) {
        data.timeFrom = this.formData.timeFrom;
        data.timeTo = this.formData.timeTo;
      }

      this.$emit('save', data);
    },
  },
};
</script>

<style scoped></style>
