<template>
  <v-container>
    <h2 class="ml-1 mb-3">Платежи</h2>

    <payments-list />
  </v-container>
</template>

<script>
import PaymentsList from '@/packages/payment/components/list';

export default {
  name: 'PaymentView',

  components: { PaymentsList },
};
</script>

<style scoped></style>
