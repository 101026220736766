<template>
  <place-form />
</template>

<script>
import PlaceForm from '@/packages/place/components/form/index.component';

export default {
  name: 'PlaceCreate',

  components: { PlaceForm },
};
</script>

<style scoped></style>
