import CookieService from '@/common/services/cookie.service';
import CookieConstants from '@/packages/auth/constants/cookie.constants';

function saveAccessToken(accessToken) {
  CookieService.set(CookieConstants.COOKIE_TOKEN_NAME, accessToken);
}

function getAccessToken() {
  return CookieService.get(CookieConstants.COOKIE_TOKEN_NAME);
}

function hasAccessToken() {
  return !!getAccessToken();
}

export default {
  saveAccessToken,
  getAccessToken,
  hasAccessToken,
};
