<template>
  <room-list />
</template>

<script>
import RoomList from '@/packages/room/components/list/index';

export default {
  name: 'RoomListView',

  components: { RoomList },
};
</script>

<style scoped></style>
