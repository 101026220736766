<template>
  <v-row v-if="isReady">
    <v-col cols="12" sm="6">
      <v-text-field
        v-model.trim="formData.searchString"
        :placeholder="searchPlaceholder"
        prepend-icon="mdi-magnify"
        clearable
        @input="search"
      />
    </v-col>

    <v-col cols="12" sm="6">
      <v-menu
        ref="menu"
        v-model="isDatePickerActive"
        :close-on-content-click="false"
        :return-value.sync="formData.dates"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="dateRangeText"
            label="Период"
            prepend-icon="mdi-calendar-range-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>

        <v-date-picker
          v-model="formData.dates"
          no-title
          scrollable
          range
          locale="ru"
          :first-day-of-week="1"
        >
          <v-btn text color="blue darken-1" @click="isDatePickerActive = false">Отменить</v-btn>

          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="changeDates">Применить</v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { DateTime } from 'luxon';
import DateHelper from '@/common/helpers/date.helper';

export default {
  name: 'OrderFilter',

  props: {
    dateFrom: {
      type: String,
      required: true,
    },

    dateTo: {
      type: String,
      required: true,
    },

    searchPlaceholder: {
      type: String,
      required: false,
      default: 'Номер бронирования',
    },
  },

  data() {
    return {
      isReady: false,

      formData: {
        searchString: '',

        dates: [],
      },

      isDatePickerActive: false,
    };
  },

  computed: {
    dateRangeText() {
      const startDateTime = DateTime.fromISO(this.formData.dates[0]);
      const endDateTime = DateTime.fromISO(this.formData.dates[1]);

      if (this.formData.dates[0] === this.formData.dates[1] || !this.formData.dates[1]) {
        return DateHelper.humanDate(startDateTime, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
      }

      const startFormat = {
        day: 'numeric',
      };

      if (startDateTime.month !== endDateTime.month) {
        startFormat.month = 'long';
      }

      if (startDateTime.year !== endDateTime.year) {
        startFormat.year = 'numeric';
      }

      const start = DateHelper.humanDate(startDateTime, startFormat);

      const end = DateHelper.humanDate(endDateTime, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      return `${start} - ${end}`;
    },
  },

  mounted() {
    this.initValues();

    this.isReady = true;
  },

  methods: {
    initValues() {
      this.formData.dates = [this.dateFrom, this.dateTo];
    },

    search() {
      this.$emit('search', this.formData.searchString);
    },

    changeDates() {
      let start = this.formData.dates[0];
      let end = this.formData.dates[1] || start;

      if (DateTime.fromISO(start) > DateTime.fromISO(end)) {
        [start, end] = [end, start];
      }

      this.formData.dates = [start, end];

      this.$refs.menu.save(this.formData.dates);

      this.$emit('change-dates', this.formData.dates);
    },
  },
};
</script>

<style scoped></style>
