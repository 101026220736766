import axios from '@/plugins/axios';
import store from '@/store';

function save(formData) {
  return axios
    .patch('/profile', {
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      middleName: formData.middleName,
    })
    .then((response) => {
      store.dispatch('user/setUserInfo', response.data.user);
      return response.data;
    });
}

function get() {
  return axios.get('/profile').then((response) => {
    store.dispatch('user/setUserInfo', response.data.user);
    return response.data;
  });
}

export default { save, get };
