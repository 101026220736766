<script>
import OrderPermissionMixin from '@/packages/permission/mixins/order-permission.mixin';
import WidgetPermissionMixin from '@/packages/permission/mixins/widget-permission.mixin';

export default {
  name: 'PermissionMixin',

  mixins: [OrderPermissionMixin, WidgetPermissionMixin],
};
</script>
