import ListView from '@/packages/user-account/views/list.view';
import RefillView from '@/packages/user-account/views/refill.view';
import StatusFailView from '@/packages/user-account/views/status-fail.view';
import StatusSuccessView from '@/packages/user-account/views/status-success.view';

const routes = [
  {
    path: '/account',
    name: 'user-account.list',
    component: ListView,
  },
  {
    path: '/account/refill',
    name: 'user-account.refill',
    component: RefillView,
  },
  {
    path: '/account/:id/fail',
    name: 'user-account.status-fail',
    component: StatusFailView,
  },
  {
    path: '/account/:id/success',
    name: 'user-account.status-success',
    component: StatusSuccessView,
  },
];

export default routes;
