<template>
  <v-card>
    <v-card-title>Пожалуйста, представьтесь</v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="$v.formData.phone.$model"
            v-mask="'+7 (###) ###-##-##'"
            :error-messages="phoneErrors"
            :loading="checkUserState.isLoading"
            :readonly="checkUserState.isLoading"
            label="Номер телефона"
            name="phone"
            placeholder="+7 (999) 999-99-99"
            type="text"
            @blur="blurPhoneNumber"
            @focus="insertPhoneCode"
            @input="$v.formData.phone.$touch()"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer />

      <v-btn
        color="primary"
        small
        :disabled="isCheckUserButtonDisabled"
        :loading="checkUserState.isLoading"
        @click="checkUser"
      >
        Продолжить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import UserService from '@/packages/user/services/user.service';

export default {
  name: 'CheckUser',

  data() {
    return {
      formData: {
        phone: '',
      },

      apiErrors: {
        phone: [],
      },

      checkUserState: {
        isLoading: false,
        isChecked: false,
        hasUser: false,
      },
    };
  },

  validations() {
    return {
      formData: {
        phone: {
          required,
          isValid: () => this.cleanPhoneNumber.length === 11,
        },
      },
    };
  },

  computed: {
    cleanPhoneNumber() {
      return this.formData.phone.replace(/[^\d]/g, '');
    },

    isCheckUserButtonDisabled() {
      return this.$v.$invalid;
    },

    phoneErrors() {
      const errors = [];
      if (!this.$v.formData.phone.$dirty) return errors;

      if (this.$v.formData.phone.$invalid) {
        errors.push('Введите номер телефона');
      }

      this.apiErrors.phone.forEach((error) => {
        errors.push(error);
      });

      return errors;
    },
  },

  methods: {
    insertPhoneCode() {
      if (this.formData.phone.length === 0) {
        this.formData.phone = '+7 (';
      }
    },

    blurPhoneNumber() {
      setTimeout(() => {
        if (this.formData.phone === '+7 (') {
          this.formData.phone = '';
        }

        this.$v.formData.phone.$touch();
      }, 500);
    },

    checkUser() {
      this.checkUserState.isLoading = true;
      this.checkUserState.isChecked = false;

      UserService.checkUser(this.cleanPhoneNumber)
        .then((response) => {
          this.$emit('checked', {
            phone: this.formData.phone,
            clearPhone: this.cleanPhoneNumber,
            hasUser: response.hasUser,
          });

          this.checkUserState.hasUser = response.hasUser;
          this.checkUserState.isChecked = true;
        })
        .finally(() => {
          this.checkUserState.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
