<template>
  <v-container>
    <h2 class="ml-1 mb-3">Бронирования</h2>

    <v-row>
      <v-col :cols="isAdmin ? 8 : 12">
        <v-btn small color="primary" :to="{ name: 'order-new' }"> Забронировать </v-btn>
      </v-col>

      <v-col v-if="isAdmin" cols="4" class="text-right">
        <v-btn-toggle v-model="viewMode" dense>
          <v-btn small value="table">
            <v-icon small> fas fa-table </v-icon>
          </v-btn>

          <v-btn small value="calendar">
            <v-icon small> fas fa-calendar-alt </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <order-table v-if="isTableView" ref="orderTable" show-user @order-view="showOrderView" />

    <order-calendar v-if="isCalendarView" ref="orderCalendar" />
  </v-container>
</template>

<script>
import OrderTable from '@/packages/order/components/table';
import OrderCalendar from '@/packages/order/components/calendar';
import UserPermissionService from '@/packages/user/services/user-permission.service';
import * as Permissions from '@/packages/user/permissions';
import OrderActionsMixin from '@/packages/order/mixins/order-actions.mixin';

export default {
  name: 'OrderList',

  components: {
    OrderCalendar,
    OrderTable,
  },

  mixins: [OrderActionsMixin],

  data() {
    return {
      isReady: false,
      isLoading: false,
      isLoaded: false,

      viewMode: 'table',

      orders: [],
    };
  },

  computed: {
    isAdmin() {
      return UserPermissionService.can(Permissions.CUSTOMERS_MANAGE_ROOMS);
    },

    isTableView() {
      return this.viewMode === 'table';
    },

    isCalendarView() {
      return this.viewMode === 'calendar';
    },
  },

  methods: {
    async hideCancelOrderModalAndReloadOrders() {
      this.reloadOrders();
      await this.hideCancelOrderModal();
    },

    async hideProlongOrderModalAndReloadOrders() {
      this.reloadOrders();
      await this.hideProlongOrderModal();
    },

    async hideEditOrderFormAndReloadOrders() {
      this.reloadOrders();
      await this.hideEditOrderForm();
    },

    reloadOrders() {
      if (this.isTableView) {
        this.$refs.orderTable.loadOrders();
      } else if (this.isCalendarView) {
        this.$refs.orderCalendar.loadOrders();
      }
    },
  },
};
</script>

<style scoped>
.order-list-title {
  font-size: 1.5rem;
}
</style>
