<template>
  <v-dialog v-if="isReady" :value="true" persistent max-width="600px">
    <main-form
      v-if="isMainStep"
      :selected-place="selectedPlace"
      :room="room"
      :rooms="rooms"
      :cost-type="formData.costType"
      :is-hourly-type-available="isHourlyTypeAvailable"
      :is-daily-type-available="isDailyTypeAvailable"
      :is-many-days-type-available="isManyDaysTypeAvailable"
      :date-time-from="formData.dateTimeFrom"
      :date-time-to="formData.dateTimeTo"
      :visitors="formData.visitors"
      :need-service-time="formData.needServiceTime"
      :availability="availability"
      :is-availability-loading="isAvailabilityLoading"
      :is-availability-loaded="isAvailabilityLoaded"
      :cost="cost"
      :minimal-date-time="minimalDateTime"
      :payment-method="formData.paymentMethod"
      :payment-status="formData.paymentStatus"
      :use-user-specified-cost="formData.useUserSpecifiedCost"
      :user-specified-cost="formData.userSpecifiedCost"
      :use-available-to="formData.useAvailableTo"
      :available-to="formData.availableTo"
      :order="order"
      @change="changeOrderData"
      @change-cost-type="changeCostType"
      @change-payment-method="changePaymentMethod"
      @change-payment-status="changePaymentStatus"
      @change-use-user-specified-cost="changeUseUserSpecifiedCost"
      @change-user-specified-cost="changeUserSpecifiedCost"
      @change-use-available-to="changeUseAvailableTo"
      @change-available-to="changeAvailableTo"
      @change-room="changeRoom"
      @cancel="$emit('cancel')"
      @next="next"
    />

    <user-select
      v-if="isUserSelectStep"
      :for-other-user="formData.user.forOtherUser"
      :auto-search="formData.user.autoSearch"
      :user-id="formData.user.id"
      :phone-number="formData.user.phoneNumber"
      :email="formData.user.email"
      :first-name="formData.user.firstName"
      :last-name="formData.user.lastName"
      @back="backFromUserSelect"
      @confirm="setUserData"
    />

    <order-preview
      v-if="isPreviewStep"
      :room="room"
      :cost-type="formData.costType"
      :date-time-from="formData.dateTimeFrom"
      :date-time-to="formData.dateTimeTo"
      :visitors="formData.visitors"
      :need-service-time="formData.needServiceTime"
      :for-other-user="formData.user.forOtherUser"
      :phone-number="formData.user.phoneNumber"
      :email="formData.user.email"
      :first-name="formData.user.firstName"
      :last-name="formData.user.lastName"
      :cost="cost"
      :errors="savingErrors"
      :is-loading="isSavingLoading"
      :order="order"
      :payment-method="formData.paymentMethod"
      :payment-status="formData.paymentStatus"
      :use-user-specified-cost="formData.useUserSpecifiedCost"
      :user-specified-cost="formData.userSpecifiedCost"
      :use-available-to="formData.useAvailableTo"
      :available-to="formData.availableTo"
      @back="back"
      @confirm="save"
    />

    <order-success
      v-if="isSuccessStep"
      :public-id="publicId"
      :for-other-user="formData.user.forOtherUser"
      :email="formData.user.email"
      :available-to="availableTo"
      :payment-method="formData.paymentMethod"
      :payment-status="formData.paymentStatus"
      :use-user-specified-cost="formData.useUserSpecifiedCost"
      :user-specified-cost="formData.userSpecifiedCost"
      :cost="cost"
      :order-id="createdOrderId"
      @cancel="$emit('cancel')"
      @next="toPayment()"
    />
  </v-dialog>
</template>

<script>
import MainForm from '@/packages/order/components/form/main-form';
import OrderPreview from '@/packages/order/components/form/preview';
import { mapGetters } from 'vuex';
import OrderService from '@/packages/order/services/order.service';
import OrderSuccess from '@/packages/order/components/form/success';
import { DateTime } from 'luxon';
import CostTypeEnum from '@/packages/order/enums/cost-type.enum';
import DateTimeConstants from '@/common/constants/datetime.constants';
import DateTimeHelper from '@/common/helpers/date-time.helper';
import PlaceService from '@/packages/place/services/place.service';
import CalculateCostService from '@/packages/order/services/calculate-cost.service';
import UserSelect from '@/packages/order/components/form/user-select';
import StringHelper from '@/common/helpers/string.helper';
import PermissionMixin from '@/packages/permission/mixins/permission.mixin';
import SelectedPlaceMixin from '@/packages/order/mixins/selected-place.mixin';
import SystemMessageService from '@/packages/system-message/services/system-message.service';
import OrderPaymentStatusEnum from '@/packages/order/enums/order-payment-status.enum';
import PaymentMethodEnum from '@/packages/payment/enums/payment-method.enum';

export default {
  name: 'OrderForm',

  components: {
    UserSelect,
    OrderSuccess,
    OrderPreview,
    MainForm,
  },

  mixins: [PermissionMixin, SelectedPlaceMixin],

  props: {
    rooms: {
      type: Array,
      required: true,
    },

    roomId: {
      type: [String, null],
      required: false,
      default: null,
    },

    dateTime: {
      type: [Object, null],
      required: false,
      default: null,
    },

    order: {
      type: [Object, null],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isReady: false,

      step: 'MAIN_FORM',

      formData: {
        roomId: null,
        costType: CostTypeEnum.TYPE_HOURLY,
        dateTimeFrom: null,
        dateTimeTo: null,
        visitors: 2,
        needServiceTime: true,
        paymentMethod: PaymentMethodEnum.CARD,
        paymentStatus: OrderPaymentStatusEnum.NEW,
        useUserSpecifiedCost: false,
        userSpecifiedCost: null,
        useAvailableTo: false,
        availableTo: null,

        user: {
          autoSearch: true,
          forOtherUser: false,
          id: null,
          phoneNumber: '',
          email: '',
          firstName: '',
          lastName: '',
        },
      },

      placeCurrentDateTime: null,

      minimalDateTime: null,
      selectedDateTime: null,

      availability: {},

      cost: 0,

      showVisitors: true,

      savingErrors: {},
      isSavingLoading: false,

      publicId: '',
      createdOrderId: null,
      userId: null,
      availableTo: null,

      isAvailabilityLoading: true,
      isAvailabilityLoaded: false,

      intervalId: null,
    };
  },

  computed: {
    ...mapGetters(['customerId', 'isLoggedIn', 'isPaymentEnabled', 'paymentMethods']),

    isEditing() {
      return this.order && this.order.id;
    },

    isMainStep() {
      return this.step === 'MAIN_FORM';
    },

    isUserSelectStep() {
      return this.step === 'USER_SELECT';
    },

    isPreviewStep() {
      return this.step === 'PREVIEW';
    },

    isSuccessStep() {
      return this.step === 'SUCCESS';
    },

    isHourlyType() {
      return this.formData.costType === CostTypeEnum.TYPE_HOURLY;
    },

    isDailyType() {
      return this.formData.costType === CostTypeEnum.TYPE_DAILY;
    },

    isManyDaysType() {
      return this.formData.costType === CostTypeEnum.TYPE_MANY_DAYS;
    },

    room() {
      if (this.rooms.length === 1) {
        return this.rooms[0];
      }

      return this.rooms.find((room) => room.id === this.formData.roomId);
    },

    roomCosts() {
      return this.room.costs;
    },

    dailyCosts() {
      return this.roomCosts.filter((item) => item.costType === CostTypeEnum.TYPE_DAILY);
    },

    manyDaysCost() {
      return this.roomCosts.filter((item) => item.costType === CostTypeEnum.TYPE_MANY_DAYS);
    },

    isHourlyTypeAvailable() {
      return true;
    },

    isDailyTypeAvailable() {
      return this.room.settings.allowDailyOrder && this.dailyCosts.length > 0;
    },

    isManyDaysTypeAvailable() {
      return this.room.settings.allowDailyOrder && this.manyDaysCost.length > 0;
    },

    placeStartDateTime() {
      return DateTimeHelper.appendTime(this.placeCurrentDateTime, this.placeTimeFrom);
    },

    placeEndDateTime() {
      return DateTimeHelper.appendTime(this.placeCurrentDateTime, this.placeTimeTo);
    },

    isServiceTimeEnabled() {
      return (
        this.isHourlyType &&
        this.room.settings.serviceTimeEnabled &&
        this.room.settings.serviceTimeValue > 0 &&
        this.formData.needServiceTime
      );
    },

    isServiceTimeFree() {
      return this.room.settings.serviceTimeFree;
    },

    canChangePaymentStatus() {
      return this.isUserCanChangePaymentStatus;
    },

    canChangePaymentMethod() {
      return this.isUserCanChangePaymentMethod;
    },
  },

  mounted() {
    this.placeCurrentDateTime = this.getPlaceCurrentDateTime();

    if (this.isEditing) {
      this.initEditOrderState();
    } else {
      this.initCreateOrderState();
    }

    this.isReady = true;

    if (this.intervalId === null) {
      this.intervalId = setInterval(() => {
        this.placeCurrentDateTime = this.getPlaceCurrentDateTime();

        this.minimalDateTime = this.getMinimalDateTime();
      }, 15000);
    }
  },

  destroyed() {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
    }
  },

  methods: {
    initCreateOrderState() {
      this.formData.roomId = this.roomId;

      this.minimalDateTime = this.getMinimalDateTime();
      this.saveUserSelectedDate();

      this.initOrderDates();

      this.initPaymentMethod();

      this.loadRoomAvailabilityInfo();
      this.calculateOrderCost();
    },

    initEditOrderState() {
      this.formData.roomId = this.order.room.id;

      this.minimalDateTime = this.getMinimalDateTime();

      this.formData.costType = this.order.cost.type;

      this.formData.dateTimeFrom = this.order.period.from;
      this.formData.dateTimeTo = this.order.period.to.minus({
        minute: this.order.serviceTime.amount,
      });

      this.formData.visitors = this.order.visitors;
      this.formData.needServiceTime = this.order.serviceTime.amount !== 0;
      this.formData.paymentMethod = this.order.payment.method.uid;

      this.cost = this.order.cost.amount;

      this.loadRoomAvailabilityInfo();
    },

    getPlaceCurrentDateTime() {
      return DateTime.local().setZone(this.placeTimezone);
    },

    getMinimalDateTime() {
      let date;

      if (this.isHourlyType) {
        date = this.placeCurrentDateTime.startOf('minute');

        const startTimeStep = this.room.settings.startTimeStep;
        const steps = DateTimeConstants.MINUTES_IN_HOUR / startTimeStep;

        for (let i = 0; i < steps; i += 1) {
          if (i + 1 === steps) {
            date = date.plus({ hour: 1 }).startOf('hour');
            break;
          }

          if (startTimeStep * (i + 1) >= date.minute) {
            date = date.set({ minute: startTimeStep * (i + 1) });
            break;
          }
        }

        if (date.plus({ minutes: this.room.settings.minimalOrderTime }) >= this.placeEndDateTime) {
          date = DateTimeHelper.appendTime(
            this.placeEndDateTime.plus({ days: 1 }),
            this.placeTimeFrom
          );
        } else if (date < this.placeStartDateTime) {
          date = this.placeStartDateTime;
        }
      } else {
        date = this.placeStartDateTime;

        if (
          this.placeCurrentDateTime >= this.placeStartDateTime ||
          this.placeCurrentDateTime >= this.placeEndDateTime
        ) {
          date = this.placeStartDateTime.plus({ days: 1 });
        }
      }

      return date;
    },

    saveUserSelectedDate() {
      let dateTime = this.dateTime;

      const startTimeStep = this.room.settings.startTimeStep;
      const steps = DateTimeConstants.MINUTES_IN_HOUR / startTimeStep;

      for (let i = 0; i < steps; i += 1) {
        if (dateTime.minute < startTimeStep * (i + 1)) {
          dateTime = dateTime.set({ minute: startTimeStep * i });
          break;
        }
      }

      this.selectedDateTime = dateTime;
    },

    initOrderDates() {
      this.minimalDateTime = this.getMinimalDateTime();

      if (this.isDailyType) {
        this.formData.dateTimeFrom = DateTimeHelper.appendTime(
          this.selectedDateTime,
          this.placeTimeFrom
        );

        if (this.formData.dateTimeFrom < this.minimalDateTime) {
          this.formData.dateTimeFrom = this.minimalDateTime;
        }

        this.formData.dateTimeTo = DateTimeHelper.appendTime(
          this.formData.dateTimeFrom,
          this.placeTimeTo
        );

        const diff = this.formData.dateTimeTo.diff(this.formData.dateTimeFrom, 'minutes').minutes;
        if (diff < this.room.settings.minimalOrderTime) {
          this.formData.dateTimeTo = this.formData.dateTimeFrom.plus({
            minutes: this.room.settings.minimalOrderTime,
          });
        }
      } else if (this.isManyDaysType) {
        this.formData.dateTimeFrom = DateTimeHelper.appendTime(
          this.selectedDateTime,
          this.placeTimeFrom
        );

        if (this.formData.dateTimeFrom < this.minimalDateTime) {
          this.formData.dateTimeFrom = this.minimalDateTime;
        }

        this.formData.dateTimeTo = DateTimeHelper.appendTime(
          this.formData.dateTimeFrom,
          this.placeTimeTo
        ).plus({ days: 1 });
      } else {
        this.formData.dateTimeFrom = this.selectedDateTime;

        if (this.formData.dateTimeFrom < this.minimalDateTime) {
          this.formData.dateTimeFrom = this.minimalDateTime;
        }

        const endOrderDate = this.formData.dateTimeFrom.plus({
          minutes: this.room.settings.minimalOrderTime,
        });

        const endPlaceDate = DateTimeHelper.appendTime(
          this.formData.dateTimeFrom,
          this.placeTimeTo
        );

        if (endOrderDate >= endPlaceDate) {
          this.formData.dateTimeFrom = DateTimeHelper.appendTime(
            this.formData.dateTimeFrom.plus({ days: 1 }),
            this.placeTimeFrom
          );
        }

        this.formData.dateTimeTo = this.formData.dateTimeFrom.plus({
          minutes: this.room.settings.minimalOrderTime,
        });
      }
    },

    initPaymentMethod() {
      if (this.isPaymentEnabled === false) {
        this.formData.paymentMethod = PaymentMethodEnum.CASH;

        return;
      }

      const paymentMethods = this.paymentMethods || [];

      let paymentMethod;

      const cardIndex = paymentMethods.findIndex((method) => method.uid === 'CARD');
      if (cardIndex >= 0) {
        paymentMethod = paymentMethods[cardIndex];
      } else {
        paymentMethod = paymentMethods[0];
      }

      this.formData.paymentMethod = paymentMethod.uid;
    },

    changeCostType(costType) {
      this.formData.costType = costType;

      this.initOrderDates();

      this.loadRoomAvailabilityInfo();
      this.calculateOrderCost();
    },

    changePaymentMethod(paymentMethod) {
      this.formData.paymentMethod = paymentMethod;
    },

    changePaymentStatus(paymentStatus) {
      this.formData.paymentStatus = paymentStatus;
    },

    changeUseUserSpecifiedCost(useUserSpecifiedCost) {
      this.formData.useUserSpecifiedCost = useUserSpecifiedCost;
    },

    changeUserSpecifiedCost(userSpecifiedCost) {
      this.formData.userSpecifiedCost = userSpecifiedCost;
    },

    changeUseAvailableTo(useAvailableTo) {
      this.formData.useAvailableTo = useAvailableTo;
    },

    changeAvailableTo(availableTo) {
      this.formData.availableTo = availableTo;
    },

    changeOrderData(formData) {
      const oldDateFrom = this.formData.dateTimeFrom.toISO();
      const oldDateTo = this.formData.dateTimeTo.toISO();
      const oldNeedServiceTime = this.formData.needServiceTime;

      Object.keys(formData).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(this.formData, key)) {
          this.$set(this.formData, key, formData[key]);
        }
      });

      const newDateFrom = this.formData.dateTimeFrom.toISO();
      const newDateTo = this.formData.dateTimeTo.toISO();
      const newNeedServiceTime = this.formData.needServiceTime;

      if (
        oldDateFrom !== newDateFrom ||
        oldDateTo !== newDateTo ||
        oldNeedServiceTime !== newNeedServiceTime
      ) {
        this.loadRoomAvailabilityInfo();
        this.calculateOrderCost();
      }
    },

    changeRoom(roomId) {
      this.formData.roomId = roomId;
      this.minimalDateTime = this.getMinimalDateTime();

      if (
        (this.formData.costType === CostTypeEnum.TYPE_DAILY && !this.isDailyTypeAvailable) ||
        (this.formData.costType === CostTypeEnum.TYPE_MANY_DAYS && !this.isManyDaysTypeAvailable)
      ) {
        this.formData.costType = CostTypeEnum.TYPE_HOURLY;
        this.initOrderDates();
      }

      this.loadRoomAvailabilityInfo();
      this.calculateOrderCost();
    },

    async save() {
      this.isSavingLoading = true;

      try {
        if (this.isEditing) {
          await this.updateOrder();
        } else {
          await this.createOrder();
        }
      } catch (e) {
        if (e.response && e.response.status === 422) {
          this.savingErrors = e.response.data.errors;
        }
      } finally {
        this.isSavingLoading = false;
      }
    },

    async createOrder() {
      const response = await OrderService.createOrder(this.getRequestParams());

      this.publicId = response.publicId;
      this.createdOrderId = response.id;
      this.userId = response.userId;
      this.availableTo = response.availableTo;
      this.formData.paymentMethod = response.payment.type.uid;

      this.toSuccess();
      this.$emit('created');
    },

    async updateOrder() {
      await OrderService.updateOrder(this.order.id, this.getRequestParams());

      SystemMessageService.addNotify(`Бронирование обновлено`, 'success');
      this.$emit('updated');
    },

    getRequestParams() {
      const formData = {
        roomId: this.formData.roomId,
        costType: this.formData.costType,
        dateFrom: this.formData.dateTimeFrom
          .toUTC()
          .toFormat(DateTimeConstants.API_DATETIME_FORMAT),
        dateTo: this.formData.dateTimeTo.toUTC().toFormat(DateTimeConstants.API_DATETIME_FORMAT),
        visitors: this.formData.visitors,
        paymentMethod: this.formData.paymentMethod,
      };

      if (this.isServiceTimeEnabled) {
        formData.needServiceTime = true;
      }

      if (this.isUserCanOrdersCreateForOtherUsers && this.formData.user.forOtherUser) {
        if (this.formData.user.id) {
          formData.userId = this.formData.user.id;
        } else {
          formData.newUser = {
            firstName: this.formData.user.firstName,
            lastName: this.formData.user.lastName,
            email: this.formData.user.email,
            phoneNumber: StringHelper.clearPhoneNumber(this.formData.user.phoneNumber),
          };
        }
      }

      if (
        this.canChangePaymentStatus &&
        this.formData.paymentStatus !== OrderPaymentStatusEnum.NEW
      ) {
        formData.paymentStatus = this.formData.paymentStatus;
      }

      if (this.isUserCanSetUserSpecifiedCost && this.formData.useUserSpecifiedCost) {
        formData.userSpecifiedCost = this.formData.userSpecifiedCost;
      }

      if (
        this.isUserCanSetAvailableTo &&
        this.formData.useAvailableTo &&
        this.formData.availableTo &&
        this.formData.availableTo.isLuxonDateTime
      ) {
        formData.availableTo = this.formData.availableTo.toUTC();
      }

      return formData;
    },

    backFromUserSelect(userInfo) {
      this.formData.user = userInfo;
      this.formData.user.autoSearch = false;
      this.back();
    },

    setUserData(userInfo) {
      this.formData.user = userInfo;
      this.formData.user.autoSearch = false;
      this.next();
    },

    back() {
      this.savingErrors = {};

      if (this.isUserCanOrdersCreateForOtherUsers && !this.isEditing && this.step === 'PREVIEW') {
        this.step = 'USER_SELECT';
      } else {
        this.step = 'MAIN_FORM';
      }
    },

    next() {
      if (this.step === 'MAIN_FORM') {
        if (this.isUserCanOrdersCreateForOtherUsers && !this.isEditing) {
          this.step = 'USER_SELECT';
        } else {
          this.step = 'PREVIEW';
        }
      } else if (this.step === 'USER_SELECT') {
        this.step = 'PREVIEW';
      } else if (this.step === 'PREVIEW') {
        this.step = 'SUCCESS';
      }
    },

    toSuccess() {
      this.step = 'SUCCESS';
    },

    toPayment() {
      this.step = 'PAYMENT';
    },

    loadRoomAvailabilityInfo() {
      this.isAvailabilityLoading = true;
      this.isAvailabilityLoaded = false;

      const orderId = this.isEditing ? this.order.id : null;

      PlaceService.fetchRoomsAvailability(
        this.placeId,
        this.formData.dateTimeFrom,
        this.getEndDateTime(),
        orderId
      )
        .then((response) => {
          const availability = {};

          response.forEach((room) => {
            availability[room.id] = !!room.available;
          });

          this.availability = availability;
          this.isAvailabilityLoaded = true;
        })
        .finally(() => {
          this.isAvailabilityLoading = false;
        });
    },

    calculateOrderCost() {
      if (this.roomCosts.length === 0) {
        this.cost = 0;

        this.formData.userSpecifiedCost = this.cost;

        return;
      }

      this.cost = CalculateCostService.calculateCost(
        this.formData.dateTimeFrom,
        this.getEndDateTime(),
        this.roomCosts,
        this.formData.costType,
        this.room.settings.startTimeStep
      );

      this.formData.userSpecifiedCost = this.cost;
    },

    getEndDateTime() {
      let endDateTime = this.formData.dateTimeTo;

      if (this.isServiceTimeEnabled && !this.isServiceTimeFree) {
        endDateTime = endDateTime.plus({ minutes: this.room.settings.serviceTimeValue });
      } else if (this.isEditing && this.isServiceTimeFree) {
        endDateTime = endDateTime.minus({ minutes: this.order.serviceTime.amount });
      }

      return endDateTime;
    },
  },
};
</script>

<style scoped></style>
