import store from '@/store';
import axios from '@/plugins/axios';

function fetchPaymentMethods() {
  return axios.get('/payment-method').then((response) => response.data.methods);
}

function storeMethods(methods) {
  store.dispatch('paymentMethod/setMethods', methods);
}

export default {
  fetchPaymentMethods,
  storeMethods,
};
