<template>
  <v-container>
    <h2 class="ml-1 mb-3">Платежи</h2>

    <payments-list :user-id="user.id" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import PaymentsList from '@/packages/payment/components/list';

export default {
  name: 'UserPayments',

  components: { PaymentsList },

  computed: {
    ...mapGetters(['user']),
  },
};
</script>

<style scoped></style>
