<template>
  <v-card flat>
    <v-card-title>Бронирования</v-card-title>

    <v-card-text>
      <order-table
        ref="orderTable"
        :is-loading="isLoading"
        :orders="orders"
        :show-user="false"
        :user-id="userId"
        @change-dates="loadOrders"
        @order-view="showOrderView"
      />

      <!--
      <order-view
        v-if="orderViewState.isActive"
        :order="orderViewState.order"
        @cancel="hideOrderView"
        @order-approve="showApproveOrderModal"
        @order-cancel="showCancelOrderModal"
        @order-prolong="showProlongOrderModal"
        @order-edit="showEditOrderForm"
      />

      <order-edit
        v-if="orderEditState.isActive"
        :order="orderEditState.order"
        @cancel="hideEditOrderForm"
        @updated="hideEditOrderFormAndReloadOrders"
      />

      <order-approve
        v-if="orderApproveState.isActive"
        :order="orderApproveState.order"
        @cancel="hideApproveOrderModal"
        @approved="hideApproveOrderModal"
      />

      <order-cancel
        v-if="orderCancelState.isActive"
        :order="orderCancelState.order"
        @cancel="hideCancelOrderModal"
        @canceled="hideCancelOrderModalAndReloadOrders"
      />

      <order-prolong
        v-if="orderProlongState.isActive"
        :order="orderProlongState.order"
        @cancel="hideProlongOrderModal"
        @prolonged="hideProlongOrderModalAndReloadOrders"
      />
      -->
    </v-card-text>
  </v-card>
</template>

<script>
import { DateTime } from 'luxon';
import OrderService from '@/packages/order/services/order.service';
import SystemMessageService from '@/packages/system-message/services/system-message.service';
import OrderTable from '@/packages/order/components/table';
import OrderActionsMixin from '@/packages/order/mixins/order-actions.mixin';

export default {
  name: 'UserOrders',

  components: {
    OrderTable,
  },

  mixins: [OrderActionsMixin],

  props: {
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isReady: false,
      isLoading: false,
      isLoaded: false,

      orders: [],

      filter: {
        menu: false,
        dates: [],
        searchString: '',
      },

      ordersParams: {},
    };
  },

  methods: {
    initDates() {
      const start = DateTime.local();
      const end = start.plus({ days: 7 });

      this.filter.dates = [start.toISODate(), end.toISODate()];

      this.isReady = true;
    },

    openCancelModal(orderId) {
      const order = this.orders.find((o) => o.id === orderId);

      this.showCancelModal(order);
    },

    loadOrders(params) {
      this.isLoading = true;
      this.isLoaded = false;

      params.userId = this.userId;

      this.ordersParams = params;

      OrderService.fetchOrders(params)
        .then((orders) => {
          this.orders = orders;
          this.isLoaded = true;
        })
        .catch((e) => {
          SystemMessageService.addNotify(`Ошибка получения бронирований: ${e}`, 'error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async hideCancelOrderModalAndReloadOrders() {
      this.$refs.orderTable.loadOrders();
      await this.hideCancelOrderModal();
    },

    async hideProlongOrderModalAndReloadOrders() {
      this.$refs.orderTable.loadOrders();
      await this.hideProlongOrderModal();
    },

    async hideEditOrderFormAndReloadOrders() {
      this.$refs.orderTable.loadOrders();
      await this.hideEditOrderForm();
    },
  },
};
</script>

<style scoped></style>
