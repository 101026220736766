<template>
  <div>
    <v-row dense>
      <v-col cols="5" sm="4">Имя</v-col>
      <v-col cols="7" sm="8">{{ firstName }} {{ lastName }}</v-col>
    </v-row>

    <v-row dense>
      <v-col cols="5" sm="4">Телефон</v-col>
      <v-col cols="7" sm="8">
        <a :href="`tel:+${phoneNumber}`">{{ humanPhoneNumber }}</a>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="5" sm="4">E-mail</v-col>
      <v-col cols="7" sm="8">
        <a :href="`mailto:${email}`">{{ email }}</a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StringHelper from '@/common/helpers/string.helper';

export default {
  name: 'UserInfo',

  props: {
    firstName: {
      type: String,
      required: true,
    },

    lastName: {
      type: String,
      required: true,
    },

    phoneNumber: {
      type: String,
      required: true,
    },

    email: {
      type: String,
      required: true,
    },
  },

  computed: {
    humanPhoneNumber() {
      return StringHelper.humanPhone(this.phoneNumber);
    },
  },
};
</script>
