import Vue from 'vue';
import Vuex from 'vuex';
import UserStore from '@/packages/user/store/user.store';
import UserPermissionsStore from '@/packages/user/store/user-permission.store';
import CalendarStore from '@/packages/personal-calendar/store/CalendarStore';
import AppStore from '@/packages/app/store/app.store';
import NotifyStore from '@/packages/system-message/store/system-message.store';
import SystemErrorStore from '@/packages/system-error/store/system-error.store';
import UserAccountStore from '@/packages/user-account/store/user-account.store';
import PaymentMethodStore from '@/packages/payment/store/payment-method.store';
import CustomerStore from '@/packages/customer/store/customer.store';
import OrderActionsStore from '@/packages/order/store/order-actions.store';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},

  mutations: {},

  actions: {},

  modules: {
    app: AppStore,
    customer: CustomerStore,
    user: UserStore,
    userPermissions: UserPermissionsStore,
    calendar: CalendarStore,
    notify: NotifyStore,
    systemError: SystemErrorStore,
    userAccount: UserAccountStore,
    paymentMethod: PaymentMethodStore,
    orderActionsStore: OrderActionsStore,
  },

  getters: {
    hasSystemError: (state) => state.systemError.code !== null,

    // Customer
    customerId: (state) => state.customer.id,
    isCustomerEnabled: (state) => state.customer.isEnabled,

    // App
    isAppEnabled: (state) => state.app.isEnabled,
    apiUrl: (state) => state.app.apiUrl,
    appUrl: (state) => state.app.appUrl,
    appName: (state) => state.app.name,
    appSettings: (state) => state.app.settings,

    // Payments
    isPaymentEnabled: (state) => state.customer.settings.payment.isEnabled,
    paymentMethods: (state) => state.customer.settings.payment.methods,

    isLoggedIn: (state) => state.user.isLoggedIn,

    user: (state) => state.user,
    currentUserId: (state) => state.user?.id || null,

    // User Account
    userBalance: (state) => state.userAccount.balance,

    showProfileModal: (state) => state.user.isLoggedIn && !state.user.email,

    userPermissions: (state) => state.userPermissions.permissions,
  },
});
