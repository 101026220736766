<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="orange" dark flat>
            <v-icon class="mr-4">fas fa-exclamation-circle</v-icon>
            <v-toolbar-title>
              {{ title }}
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <p>Сервис временно недоступен.</p>

            <div>
              <div>
                <small class="detail-link" @click="showDetails = !showDetails">
                  <v-icon x-small class="error-caret">
                    <template v-if="!showDetails">fas fa-caret-right</template>
                    <template v-else>fas fa-caret-down</template>
                  </v-icon>

                  <u>Подробнее</u>
                </small>
              </div>

              <v-expand-transition>
                <div v-show="showDetails">
                  <small>{{ errorMessage }} ({{ errorCode }})</small>
                </div>
              </v-expand-transition>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SystemError',

  data() {
    return {
      showDetails: false,
    };
  },

  computed: {
    errorCode() {
      return this.$store.state.systemError.code;
    },

    errorMessage() {
      return this.$store.state.systemError.message;
    },

    title() {
      return 'Ошибка';
    },

    message() {
      return this.errorCode;
    },
  },
};
</script>

<style scoped>
.detail-link {
  cursor: pointer;
  cursor: hand;
}

.error-caret {
  width: 10px;
}
</style>
