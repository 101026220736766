var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h2',{staticClass:"ml-1 mb-3"},[_vm._v(_vm._s(_vm.$t('place.menuLabel')))]),_c('v-row',[_c('v-col',{staticClass:"mb-3 text-center text-md-left"},[_c('v-btn',{attrs:{"small":"","color":"primary","dark":"","to":{ name: 'customer-place-create' }}},[_vm._v(" "+_vm._s(_vm.$t('place.addLabel'))+" ")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.table.headers,"items":_vm.places,"loading-text":"Загружаем список","no-data-text":"Записи не найдены","sort-by":"name","calculate-widths":"","loading":_vm.loading,"hide-default-footer":_vm.places.length <= 5,"footer-props":{
      itemsPerPageText: 'Записей на страницу',
      itemsPerPageAllText: 'Все',
    }},scopedSlots:_vm._u([{key:"item.times",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text--secondary"},[_vm._v(_vm._s(item.timeFrom)+" - "+_vm._s(item.timeTo))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"text":"","small":"","color":"primary","to":{ name: 'customer-place-edit', params: { id: item.id } }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fas fa-pencil-alt")]),(!_vm.$vuetify.breakpoint.mobile)?[_vm._v("Изменить")]:_vm._e()],2),_c('v-btn',{attrs:{"text":"","small":"","color":"red lighten-3"},on:{"click":function($event){return _vm.showDeletionDialog(item)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("fas fa-trash-alt")]),(!_vm.$vuetify.breakpoint.mobile)?[_vm._v("Удалить")]:_vm._e()],2)],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"mb-3 text--secondary",attrs:{"color":"warning lighten-2","flat":""}},[_c('v-toolbar-title',[_vm._v("Удалить \""+_vm._s(_vm.deletingPlace.name)+"\"?")])],1),_c('v-card-text',[_c('p',[_vm._v("Данное действие нельзя будет отменить.")]),_c('p',[_vm._v("Данные о бронированиях будут сохранены.")])]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","small":"","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Закрыть")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","dark":"","small":""},on:{"click":function($event){return _vm.deletePlace(_vm.deletingPlace.id)}}},[_vm._v("Удалить")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }