const days = {
  0: {
    id: 0,
    name: 'Ежедневно',
  },
  1: {
    id: 1,
    name: 'Понедельник',
  },
  2: {
    id: 2,
    name: 'Вторник',
  },
  3: {
    id: 3,
    name: 'Среда',
  },
  4: {
    id: 4,
    name: 'Четверг',
  },
  5: {
    id: 5,
    name: 'Пятница',
  },
  6: {
    id: 6,
    name: 'Суббота',
  },
  7: {
    id: 7,
    name: 'Воскресенье',
  },
  8: {
    id: 8,
    name: 'Будни',
  },
  9: {
    id: 9,
    name: 'Выходные',
  },
};

function getDayName(dayId) {
  return days[dayId].name;
}

function getDays() {
  return Object.values(days);
}

export default { getDayName, getDays };
