<template>
  <confirmation-modal
    confirm-text="Продлить бронирование"
    :is-loading="prolongState.isLoading"
    :disabled="isConfirmationDisabled"
    max-width="600px"
    @cancel="$emit('cancel')"
    @confirm="prolongOrder"
  >
    <template #title>Продление бронирования</template>

    <template #text>
      <loader v-if="roomState.isLoading" />

      <template v-if="roomState.isLoaded">
        <p>Выберите время продления.</p>

        <v-row>
          <v-col cols="6" xs="12">
            <v-select
              v-model="formData.minutes"
              label="Время продление"
              :items="durations"
              item-text="label"
              item-value="value"
              hide-details
              :disabled="durations.length <= 1"
              solo
              @change="loadRoomAvailabilityInfo"
            />
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-row v-if="availabilityState.isLoaded && !isRoomAvailable">
            <v-col cols="12">
              <v-alert
                border="left"
                color="warning"
                colored-border
                dense
                elevation="2"
                type="warning"
              >
                {{ $t('order.prolongUnavailable') }}
              </v-alert>
            </v-col>
          </v-row>
        </v-expand-transition>

        <v-card-subtitle class="pb-0 mt-2 pl-0">Стоимость бронирования</v-card-subtitle>

        <v-card-title class="pt-0 pl-0">{{ humanCost }}</v-card-title>

        <v-switch
          v-model="formData.confirmed"
          inset
          dense
          label="Я подтверждаю продление бронирования."
        />
      </template>
    </template>
  </confirmation-modal>
</template>

<script>
import ConfirmationModal from '@/common/components/confirmation-modal';
import { DateTime } from 'luxon';
import DateHelper from '@/common/helpers/date.helper';
import CostHelper from '@/packages/order/helpers/cost.helper';
import { mapGetters } from 'vuex';
import TimeHelper from '@/common/helpers/time.helper';
import CalculateCostService from '@/packages/order/services/calculate-cost.service';
import PlaceService from '@/packages/place/services/place.service';
import DateTimeHelper from '@/common/helpers/date-time.helper';
import ProlongOrderService from '@/packages/order/services/prolong-order.service';
import SystemMessageService from '@/packages/system-message/services/system-message.service';
import Loader from '@/common/components/loader';
import RoomService from '@/packages/room/services/room.service';

// TODO: Добавить предложение забронировать другой кабинет.
export default {
  name: 'OrderProlong',

  components: { Loader, ConfirmationModal },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      formData: {
        minutes: 30,
        confirmed: false,
      },

      room: null,

      availability: {},

      durations: [],

      roomState: {
        isLoading: false,
        isLoaded: false,
      },

      prolongState: {
        isLoading: false,
      },

      availabilityState: {
        isLoading: false,
        isLoaded: false,
      },
    };
  },

  computed: {
    ...mapGetters(['currentUserId']),

    isConfirmationDisabled() {
      return (
        !this.formData.confirmed ||
        !this.isRoomAvailable ||
        this.durations.length === 0 ||
        !this.roomState.isLoaded
      );
    },

    dateTimeTo() {
      return this.order.period.to.plus({ minutes: this.formData.minutes });
    },

    isRoomAvailable() {
      return this.availability[this.room.id];
    },

    cost() {
      if (!this.roomState.isLoaded || this.room === null) {
        return 0;
      }

      return CalculateCostService.calculateCost(
        this.order.period.from,
        this.dateTimeTo,
        this.room.costs,
        this.order.cost.type,
        this.room.settings.startTimeStep
      );
    },

    orderDate() {
      return DateHelper.humanDate(this.order.period.from);
    },

    orderTime() {
      return DateHelper.humanDate(this.order.period.from, DateTime.TIME_SIMPLE);
    },

    humanCost() {
      return CostHelper.humanCost(this.cost);
    },
  },

  async mounted() {
    await this.loadRoom();

    this.durations = this.getDurations();

    this.loadRoomAvailabilityInfo();
  },

  methods: {
    async loadRoom() {
      try {
        this.roomState.isLoading = true;

        this.room = await RoomService.fetchWidgetRoom(this.order.room.id);

        this.roomState.isLoaded = true;
      } catch (e) {
        SystemMessageService.addNotify(`Ошибка загрузки данных: ${e}`, 'error');
      } finally {
        this.roomState.isLoading = false;
      }
    },

    getDurations() {
      // 2 часа
      const maxDuration = 2 * 60;
      const step = 30;
      const maxStep = maxDuration / step;

      const durations = [];

      // TODO: Добавить ограничение на окончание дня.

      const endOfDay = DateTimeHelper.appendTime(
        DateTime.now().setZone(this.order.place.timezone),
        this.order.place.timeTo
      );

      for (let i = 0; i < maxStep; i += 1) {
        const minutes = i * step + step;

        if (this.order.period.to.plus({ minutes }) > endOfDay) {
          break;
        }

        durations.push({
          value: minutes,
          label: TimeHelper.humanTimeFromMinutes(minutes),
        });
      }

      return durations;
    },

    loadRoomAvailabilityInfo() {
      this.availabilityState.isLoading = true;
      this.availabilityState.isLoaded = false;

      PlaceService.fetchRoomsAvailability(
        this.room.placeId,
        this.order.period.from,
        this.dateTimeTo,
        this.order.id
      )
        .then((response) => {
          const availability = {};

          response.forEach((room) => {
            availability[room.id] = !!room.available;
          });

          this.availability = availability;
          this.availabilityState.isLoaded = true;
        })
        .finally(() => {
          this.availabilityState.isLoading = false;
        });
    },

    prolongOrder() {
      this.prolongState.isLoading = true;

      ProlongOrderService.prolongOrder(this.order.id, this.formData.minutes)
        .then(() => {
          SystemMessageService.addNotify(`Бронирование продлено`, 'success');
          this.$emit('prolonged');
        })
        .catch((e) => {
          SystemMessageService.addNotify(`Ошибка отмены бронирования: ${e}`, 'error');
        })
        .finally(() => {
          this.prolongState.isLoading = false;
        });
    },
  },
};
</script>
