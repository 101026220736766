<template>
  <div>
    <user-account-summary :balance="balance" />

    <user-account-table
      :items="payments.items"
      :is-loading="payments.isLoading"
      @view-order="showOrderView({ orderId: $event, userId })"
    />
  </div>
</template>

<script>
import UserAccountSummary from '@/packages/user-account/components/list/summary';
import UserAccountTable from '@/packages/user-account/components/list/table';
import UserAccountService from '@/packages/user-account/services/user-account.service';
import OrderActionsMixin from '@/packages/order/mixins/order-actions.mixin';

export default {
  name: 'UserAccount',

  components: { UserAccountTable, UserAccountSummary },

  mixins: [OrderActionsMixin],

  props: {
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      payments: {
        isLoading: false,
        items: [],
      },

      balance: 0,
    };
  },

  mounted() {
    this.loadUserAccount();
  },

  methods: {
    loadUserAccount() {
      this.payments.isLoading = true;

      UserAccountService.fetchUserPayments(this.userId)
        .then((response) => {
          this.payments.items = response.payments;
          this.balance = response.balance.amount;
        })
        .finally(() => {
          this.payments.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
