<script>
import * as OrderStatus from '@/packages/order/constants/order-status';
import OrderStatusHelper from '@/packages/order/helpers/order-status.helper';
import OrderService from '@/packages/order/services/order.service';
import SystemMessageService from '@/packages/system-message/services/system-message.service';

export default {
  name: 'OrderStatusMixin',

  data() {
    return {
      orderStatusOptions: [
        {
          value: OrderStatus.NEW,
          label: 'Новое',
        },
        {
          value: OrderStatus.APPROVED,
          label: 'Подтверждено',
        },
        {
          value: OrderStatus.CANCELED,
          label: 'Отменено',
        },
      ],

      isCancelLoading: false,
      isApproveLoading: false,
    };
  },

  methods: {
    getOrderStatusText(uid) {
      return OrderStatusHelper.statusText(uid);
    },

    getOrderStatusColor(uid) {
      return OrderStatusHelper.statusColor(uid);
    },

    isNewOrder(uid) {
      return uid === OrderStatus.NEW;
    },

    isApprovedOrder(uid) {
      return uid === OrderStatus.APPROVED;
    },

    isCanceledOrder(uid) {
      return uid === OrderStatus.CANCELED;
    },

    approveOrder(orderId) {
      this.isApproveLoading = true;

      OrderService.approve(orderId)
        .then(() => {
          this.$emit('change-status', { orderId, uid: OrderStatus.APPROVED });
          SystemMessageService.addNotify(`Бронирование подтверждено`, 'success');
        })
        .catch((e) => {
          SystemMessageService.addNotify(`Ошибка подтверждения бронирования: ${e}`, 'error');
        })
        .finally(() => {
          this.isApproveLoading = false;
        });
    },

    cancelOrder(orderId) {
      this.isCancelLoading = true;

      OrderService.cancel(orderId)
        .then(() => {
          this.$emit('change-status', { orderId, uid: OrderStatus.CANCELED });
          SystemMessageService.addNotify(`Бронирование отменено`, 'success');
        })
        .catch((e) => {
          SystemMessageService.addNotify(`Ошибка отмены бронирования: ${e}`, 'error');
        })
        .finally(() => {
          this.isCancelLoading = false;
        });
    },
  },
};
</script>
