<template>
  <div>
    <template v-if="isLoading">
      <div class="order-view__user-statistics__loader">
        <loader />
      </div>
    </template>

    <v-list v-if="!isLoading" dense class="fasti__user-stat mt-0 pt-0 px-0" flat>
      <v-list-item two-line dense class="pa-0">
        <v-list-item-content class="mt-0 pt-0">
          <v-list-item-title>Визиты</v-list-item-title>

          <v-list-item-subtitle>{{ visits }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line dense class="pa-0">
        <v-list-item-content class="mt-0 pt-0">
          <v-list-item-title>Продано</v-list-item-title>

          <v-list-item-subtitle>{{ getHumanCost(sold) }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line dense class="pa-0">
        <v-list-item-content class="mt-0 pt-0">
          <v-list-item-title>Оплачено</v-list-item-title>

          <v-list-item-subtitle>{{ getHumanCost(paid) }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!--
      <v-list-item two-line dense class="pa-0">
        <v-list-item-content class="mt-0 pt-0">
          <v-list-item-title>Баланс</v-list-item-title>

          <v-list-item-subtitle>10 000 рублей</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      -->
    </v-list>
  </div>
</template>

<script>
import CostHelper from '@/common/helpers/cost.helper';
import Loader from '@/common/components/loader';

export default {
  name: 'UserStatistics',
  components: { Loader },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    visits: {
      type: Number,
      required: false,
      default: 0,
    },

    sold: {
      type: Number,
      required: false,
      default: 0,
    },

    paid: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  methods: {
    getHumanCost(cost) {
      return CostHelper.humanCost(cost);
    },
  },
};
</script>

<style lang="scss">
.fasti__user-stat.v-list--dense .v-list-item.v-list-item--two-line,
.fasti__user-stat .v-list-item--dense.v-list-item--two-line {
  min-height: 30px;
}

.order-view__user-statistics {
  &__loader {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
}
</style>
