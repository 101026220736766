<template>
  <div>
    Банк отклонил Ваш платёж. Вы можете попробовать пополнить баланс ещё раз.

    <v-divider class="mt-5" />

    <user-account-refill />
  </div>
</template>

<script>
import UserAccountRefill from '@/packages/user-account/components/refill';

export default {
  name: 'UserAccountStatusFail',
  components: { UserAccountRefill },
};
</script>

<style scoped></style>
