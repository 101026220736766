<script>
export default {
  name: 'PayOrderMixin',

  data() {
    return {
      payOrderState: {
        isActive: false,
        orderId: null,
        cost: null,
        availableTo: null,
      },
    };
  },

  methods: {
    showPayOrderModal({ orderId, cost, availableTo }) {
      this.payOrderState.orderId = orderId;
      this.payOrderState.cost = cost;
      this.payOrderState.availableTo = availableTo;
      this.payOrderState.isActive = true;
    },

    hidePayOrderModal() {
      this.payOrderState = {
        isActive: false,
        orderId: null,
        cost: null,
        availableTo: null,
      };
    },
  },
};
</script>
