<template>
  <div>
    <v-navigation-drawer v-model="drawer" app clipped color="grey lighten-4">
      <div class="d-flex justify-space-between flex-column fill-height">
        <v-list-item-group>
          <v-list dense class="grey lighten-4" shaped>
            <template v-for="(item, i) in items">
              <template v-if="!item.hidden">
                <v-subheader v-if="item.heading" :key="i">
                  {{ item.heading }}
                </v-subheader>

                <v-divider v-else-if="item.divider && !item.hidden" :key="i" dark class="my-4" />

                <v-list-item v-else :key="i" link exact :to="{ name: item.to }">
                  <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="grey--text">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </v-list>
        </v-list-item-group>
      </div>
    </v-navigation-drawer>

    <v-app-bar app color="indigo" dark clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

      <v-toolbar-title>
        <router-link :to="{ name: 'main' }">
          {{ appName }}
        </router-link>
      </v-toolbar-title>

      <v-spacer />

      <div class="mr-2 d-none d-sm-block">
        {{ userName }}
      </div>

      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item :to="{ name: 'user-profile-edit' }">
            <v-list-item-icon>
              <v-icon>far fa-user-circle</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Профиль</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isUserAccountEnabled" :to="{ name: 'user-account.list' }">
            <v-list-item-icon>
              <v-icon>fas fa-wallet</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Личный счёт</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset />

          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>fas fa-sign-out-alt</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Выйти</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main class="mt-3 mx-2">
      <slot />
    </v-main>
  </div>
</template>

<script>
import AuthService from '@/packages/auth/services/auth.service';
import { mapGetters } from 'vuex';
import UserPermissionService from '@/packages/user/services/user-permission.service';
import * as Permissions from '@/packages/user/permissions';
import UserAccountService from '@/packages/user-account/services/user-account.service';

export default {
  name: 'MainLayout',

  data() {
    return {
      drawer: !this.$vuetify.breakpoint.mdAndDown,
    };
  },

  computed: {
    ...mapGetters(['customerId', 'appName']),

    userName() {
      return this.$store.getters['user/name'];
    },

    isUserAccountEnabled() {
      return UserAccountService.isEnabled();
    },

    items() {
      let menuItems = [
        {
          icon: 'fas fa-calendar-plus',
          title: 'Забронировать',
          to: 'order-new',
        },

        {
          icon: 'fas fa-calendar-alt',
          title: 'Бронирования',
          to: 'order',
        },
      ];

      if (this.isUserAccountEnabled) {
        menuItems = menuItems.concat([
          {
            icon: 'fas fa-wallet',
            title: 'Личный счёт',
            to: 'user-account.list',
          },
          {
            icon: 'far fa-credit-card',
            title: 'Платежи',
            to: 'user.payments',
          },
        ]);
      }

      if (UserPermissionService.can(Permissions.CUSTOMERS_MANAGE_ROOMS)) {
        menuItems = menuItems.concat([
          {
            divider: true,
            hidden: true,
          },

          {
            heading: 'Аналитика',
            hidden: true,
          },

          {
            divider: true,
          },

          {
            icon: 'fas fa-user-cog',
            title: 'Настройки',
            to: 'customer-settings',
            hidden: true,
          },

          {
            icon: 'fas fa-building',
            title: this.$t('place.menuLabel'),
            to: 'customer-place',
          },

          {
            icon: 'fas fa-person-booth',
            title: this.$t('room.menuLabel'),
            to: 'customer-room',
          },
        ]);

        if (this.isUserAccountEnabled) {
          menuItems.push({
            icon: 'far fa-credit-card',
            title: 'Платежи',
            to: 'payment.list',
          });
        }

        menuItems = menuItems.concat([
          {
            divider: true,
          },

          {
            icon: 'fas fa-users',
            title: 'Клиенты',
            to: 'user-list',
          },
        ]);

        menuItems = menuItems.concat([
          {
            divider: true,
          },

          {
            icon: 'fas fa-globe',
            title: 'Локализация',
            to: 'lang.editor',
          },
        ]);
      }

      return menuItems;
    },
  },

  methods: {
    logout() {
      AuthService.logout();
    },
  },
};
</script>

<style scoped>
.v-list-item__action {
  width: 30px;
}

.v-toolbar__title a {
  text-decoration: none;
  color: white;
}
</style>
