import StringHelper from '@/common/helpers/string.helper';

function humanCost(cost) {
  const rubles = Math.trunc(cost);
  const penny = (cost - rubles) * 100;

  const rublesLabel = StringHelper.declOfNum(rubles, ['рубль', 'рубля', 'рублей']);

  if (penny === 0) {
    return `${rubles.toLocaleString()} ${rublesLabel}`;
  }

  const pennyLabel = StringHelper.declOfNum(penny, ['копейка', 'копейки', 'копеек']);

  return `${rubles.toLocaleString()} ${rublesLabel} ${penny} ${pennyLabel}`;
}

export default { humanCost };
