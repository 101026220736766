<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-list dense>
        <v-list-item dense two-line>
          <v-list-item-content>
            Текущий баланс
            <v-list-item-subtitle>Один бонус равняется одному рублю РФ</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>{{ getHumanBalance(userBalance) }}</v-list-item-action>
        </v-list-item>
      </v-list>

      <v-text-field
        v-model="amount"
        type="number"
        min="1"
        solo
        label="Сумма пополнения"
        hint="Минимальная сумма пополнения - 500 рублей"
        persistent-hint
        class="mb-5"
      />

      <v-btn
        color="primary"
        :disabled="amount < 500"
        :loading="isLoading"
        class="mb-5"
        @click="createPayment"
      >
        Пополнить
      </v-btn>

      <v-alert v-if="isError" dark color="red lighten-2" class="mb-5">
        Произошла ошибка во время создания платежа. Попробуйте ещё раз.
      </v-alert>

      <template v-if="isSuccess">
        <v-alert dark color="green" class="mb-5">
          Платёж создан. Сейчас Вы будете перенаправлены на страницу банка для оплаты.
        </v-alert>

        <div class="text-center">
          <v-btn color="primary" :href="paymentUrl">Оплатить</v-btn>
        </div>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import UserAccountService from '@/packages/user-account/services/user-account.service';
import PaymentService from '@/packages/payment/services/payment.service';
import { mapGetters } from 'vuex';
import CostHelper from '@/common/helpers/cost.helper';

export default {
  name: 'UserAccountRefill',

  data() {
    return {
      isLoading: false,

      amount: '',

      paymentUrl: null,
      errorCode: null,
      errorMessage: null,
    };
  },

  computed: {
    ...mapGetters(['userBalance']),

    isSuccess() {
      return this.paymentUrl !== null;
    },

    isError() {
      return this.errorCode !== null;
    },
  },

  mounted() {
    this.loadBalance();
  },

  methods: {
    createPayment() {
      this.isLoading = true;
      this.paymentUrl = null;
      this.errorCode = null;
      this.errorMessage = null;

      PaymentService.createPayment(this.amount)
        .then((response) => {
          if ('paymentUrl' in response) {
            this.paymentUrl = response.paymentUrl;

            setTimeout(() => {
              document.location.href = response.paymentUrl;
            }, 2000);
          }

          if ('error' in response) {
            this.errorCode = response.error.code;
            this.errorMessage = response.error.message;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    loadBalance() {
      UserAccountService.fetchBalance().then((balance) => {
        UserAccountService.storeBalance(balance.amount);
      });
    },

    getHumanBalance(cost) {
      return CostHelper.humanBalance(cost);
    },
  },
};
</script>

<style scoped></style>
