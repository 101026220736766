<script>
import PermissionService from '@/packages/permission/services/permission.service';
import OrderPermissionEnum from '@/packages/permission/enums/order-permission.enum';

export default {
  name: 'OrderPermissionMixin',

  computed: {
    isUserCanOrdersGetAll() {
      return PermissionService.can(OrderPermissionEnum.ORDERS_GET_ALL);
    },

    isUserCanOrdersCreateForOtherUsers() {
      return PermissionService.can(OrderPermissionEnum.ORDERS_CREATE_FOR_OTHER_USER);
    },

    isUserCanChangePaymentStatus() {
      return PermissionService.can(OrderPermissionEnum.ORDERS_CHANGE_PAYMENT_STATUS);
    },

    isUserCanChangePaymentMethod() {
      return PermissionService.can(OrderPermissionEnum.ORDERS_CHANGE_PAYMENT_METHOD);
    },

    isUserCanSetUserSpecifiedCost() {
      return PermissionService.can(OrderPermissionEnum.ORDERS_SET_USER_SPECIFIED_COST);
    },

    isUserCanSetAvailableTo() {
      return PermissionService.can(OrderPermissionEnum.ORDERS_SET_AVAILABLE_TO);
    },
  },
};
</script>

<style scoped></style>
