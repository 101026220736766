<template>
  <v-card>
    <v-card-title>Регистрация</v-card-title>

    <v-card-text class="mb-0">
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-mask="'+# (###) ###-##-##'"
            :value="phone"
            disabled
            label="Номер телефона"
            name="phone"
            placeholder="+7 (999) 999-99-99"
            readonly
            type="text"
          />
        </v-col>
      </v-row>

      <PhoneVerification :phone="phone" @confirmed="updatePhoneConfirmationStatus">
        <template v-slot:alert>
          Для регистрации Вам необходимо подтвердить Ваш номер телефона. Чтобы подтвердить номер,
          Вам необходимо позвонить на определённый номер. Получите номер нажав на кнопку "Получить
          номер" и позвоните на полученый номер. Ваш звонок сбросится автоматически. Это
          <strong>бесплатно для Вас</strong>.
        </template>

        <template v-slot:description>
          <p>После подтверждения номера Вы сможете зарегистрироваться.</p>
        </template>
      </PhoneVerification>
    </v-card-text>

    <form v-if="phoneConfirmed" @submit.prevent="register">
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="$v.formData.email.$model"
              :error-messages="emailErrors"
              :readonly="isLoading"
              :loading="isLoading"
              label="E-mail *"
              placeholder="E-mail"
              type="email"
              @blur="$v.formData.email.$touch()"
              @focus="$v.formData.email.$reset()"
              @input="inputEmail"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="$v.formData.lastName.$model"
              :error-messages="lastNameErrors"
              :readonly="isLoading"
              :loading="isLoading"
              label="Фамилия"
              placeholder="Фамилия"
              @blur="$v.formData.lastName.$touch()"
              @focus="$v.formData.lastName.$reset()"
              @input="$v.formData.lastName.$touch()"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="$v.formData.firstName.$model"
              :error-messages="firstNameErrors"
              :readonly="isLoading"
              :loading="isLoading"
              label="Имя"
              placeholder="Имя"
              @blur="$v.formData.firstName.$touch()"
              @focus="$v.formData.firstName.$reset()"
              @input="$v.formData.firstName.$touch()"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="formData.middleName"
              :readonly="isLoading"
              :loading="isLoading"
              label="Отчество"
              placeholder="Отчество"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="$v.formData.password.$model"
              :error-messages="passwordErrors"
              :readonly="isLoading"
              :loading="isLoading"
              label="Пароль"
              placeholder="Пароль"
              type="password"
              @blur="$v.formData.password.$touch()"
              @focus="$v.formData.password.$reset()"
              @input="$v.formData.password.$touch()"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="$v.formData.passwordConfirmation.$model"
              :error-messages="passwordConfirmationErrors"
              :readonly="isLoading"
              :loading="isLoading"
              label="Подтверждение пароля"
              placeholder="Подтверждение пароля"
              type="password"
              @blur="$v.formData.passwordConfirmation.$touch()"
              @focus="$v.formData.passwordConfirmation.$reset()"
              @input="$v.formData.passwordConfirmation.$touch()"
            />
          </v-col>
        </v-row>

        <v-row dense class="text-left">
          <v-col cols="12">
            <div class="d-flex align-center">
              <div>
                <v-switch v-model="formData.isConfirmed" inset dense />
              </div>

              <div>
                Я принимаю
                <a href="https://www.fasti.us/termsofuse" target="_blank"
                  >пользовательское соглашение</a
                >
                и подтверждаю, что ознакомлен и согласен с
                <a href="https://www.fasti.us/privacy" target="_blank"
                  >политикой конфиденциальности</a
                >
                данного сайта. Также я принимаю условия
                <a href="https://lucestudio.ru/lucestudio-OF" target="_blank">договора-оферты</a>.
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn color="blue darken-1" small text @click="$emit('back')"> Назад </v-btn>

        <v-spacer />

        <v-btn
          color="primary"
          small
          type="submit"
          :disabled="isRegisterButtonDisabled"
          :loading="isLoading"
        >
          Продолжить
        </v-btn>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
import { minLength, required, sameAs, email } from 'vuelidate/lib/validators';
import UserService from '@/packages/user/services/user.service';
import AuthService from '@/packages/auth/services/auth.service';
import Echo from '@/plugins/echo';
import SystemMessageService from '@/packages/system-message/services/system-message.service';
import PhoneVerification from '@/packages/phone-verification/components/phone-verification';

// TODO: Добавить проверку номера

export default {
  name: 'Register',

  components: { PhoneVerification },

  props: {
    phone: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      formData: {
        email: '',
        firstName: '',
        middleName: '',
        lastName: '',
        password: '',
        passwordConfirmation: '',
      },

      apiErrors: {
        email: [],
      },

      phoneConfirmed: false,

      isLoading: false,
    };
  },

  validations() {
    return {
      formData: {
        email: {
          required,
          email,
        },
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        password: {
          required,
          minLength: minLength(8),
        },
        passwordConfirmation: {
          required,
          sameAsPassword: sameAs('password'),
        },
      },
    };
  },

  computed: {
    isRegisterButtonDisabled() {
      return this.$v.$invalid || this.isLoading;
    },

    emailErrors() {
      const errors = [];

      if (!this.$v.formData.password.$dirty) {
        return errors;
      }

      if (!this.$v.formData.email.required) {
        errors.push('Введите e-mail');
      } else if (!this.$v.formData.email.email) {
        errors.push('Введён некорректный e-mail');
      }

      this.apiErrors.email.forEach((error) => {
        errors.push(error);
      });

      return errors;
    },

    firstNameErrors() {
      const errors = [];

      if (!this.$v.formData.firstName.$dirty) {
        return errors;
      }

      if (!this.$v.formData.firstName.required) {
        errors.push('Введите имя');
      }

      return errors;
    },

    lastNameErrors() {
      const errors = [];

      if (!this.$v.formData.lastName.$dirty) {
        return errors;
      }

      if (!this.$v.formData.lastName.required) {
        errors.push('Введите фамилию');
      }

      return errors;
    },

    passwordErrors() {
      const errors = [];

      if (!this.$v.formData.password.$dirty) {
        return errors;
      }

      if (!this.$v.formData.password.required) {
        errors.push('Введите пароль');
      } else if (!this.$v.formData.password.minLength) {
        errors.push('Пароль должен содержать минимум 8 символов');
      }

      return errors;
    },

    passwordConfirmationErrors() {
      const errors = [];

      if (!this.$v.formData.passwordConfirmation.$dirty) {
        return errors;
      }

      if (!this.$v.formData.passwordConfirmation.required) {
        errors.push('Введите подтверждение пароля');
      } else if (!this.$v.formData.passwordConfirmation.sameAsPassword) {
        errors.push('Введённые пароли не совпадают');
      }

      return errors;
    },
  },

  methods: {
    inputEmail() {
      this.$v.formData.email.$touch();
      this.clearApiErrors();
    },

    updatePhoneConfirmationStatus() {
      this.phoneConfirmed = true;
    },

    async register() {
      this.isLoading = true;
      this.clearApiErrors();

      try {
        await UserService.register({
          phone: this.phone,
          email: this.formData.email,
          firstName: this.formData.firstName,
          lastName: this.formData.lastName,
          middleName: this.formData.middleName,
          password: this.formData.password,
        });

        await AuthService.login(this.phone, this.formData.password);

        Echo.init();

        SystemMessageService.addNotify('Вы успешно зарегистрировались', 'success');

        this.$emit('registered');
      } catch (error) {
        this.setApiErrors(error);
      } finally {
        this.isLoading = false;
      }
    },

    setApiErrors(error) {
      if (error.response && error.response.status && error.response.status === 422) {
        this.apiErrors.email = error.response.data.errors.email || [];
      }
    },

    clearApiErrors() {
      this.apiErrors.email = [];
    },
  },
};
</script>

<style scoped></style>
