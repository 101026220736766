<template>
  <v-card :loading="isLoading" flat>
    <v-card-text v-if="isLoaded">
      <v-row>
        <v-col cols="12" sm="8">
          <v-list dense>
            <v-list-item :two-line="!!user.middleName">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ user.firstName }} {{ user.lastName }}</v-list-item-title>

                <v-list-item-subtitle v-if="!!user.middleName">
                  {{ user.middleName }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-phone</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <a :href="`tel:+${user.phone}`">{{ humanPhoneNumber }}</a>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-email</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <a :href="`mailto:${user.email}`">{{ user.email }}</a>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon>mdi-calendar-account-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Дата регистрации</v-list-item-title>

                <v-list-item-subtitle>
                  {{ registrationDate }}

                  <span class="text--secondary">{{ registrationTime }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" sm="4">
          <v-divider v-if="$vuetify.breakpoint.xsOnly" class="my-3" />

          <v-list dense class="fasti__user-stat mt-0 pt-0 px-0" flat>
            <v-list-item two-line dense class="pa-0">
              <v-list-item-content class="mt-0 pt-0">
                <v-list-item-title>Бронирования</v-list-item-title>

                <v-list-item-subtitle>{{ orderStatistics.visits }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line dense class="pa-0">
              <v-list-item-content class="mt-0 pt-0">
                <v-list-item-title>Продано</v-list-item-title>

                <v-list-item-subtitle>
                  {{ getHumanCost(orderStatistics.sold) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line dense class="pa-0">
              <v-list-item-content class="mt-0 pt-0">
                <v-list-item-title>Оплачено</v-list-item-title>

                <v-list-item-subtitle>
                  {{ getHumanCost(orderStatistics.paid) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line dense class="pa-0">
              <v-list-item-content class="mt-0 pt-0">
                <v-list-item-title>Баланс</v-list-item-title>

                <v-list-item-subtitle>{{ getHumanBalance(balance) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import StringHelper from '@/common/helpers/string.helper';
import { DateTime } from 'luxon';
import DateHelper from '@/common/helpers/date.helper';
import CostHelper from '@/common/helpers/cost.helper';
import UserAccountHelper from '@/packages/user-account/helpers/user-account.helper';
import UserService from '@/packages/user/services/user.service';
import SystemMessageService from '@/packages/system-message/services/system-message.service';
import UserStatisticsService from '@/packages/user/services/user-statistics.service';

export default {
  name: 'UserInfoCard',

  props: {
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      isLoaded: false,

      user: {},

      registeredAt: '',

      balance: 0,

      orderStatistics: {
        visits: 0,
        sold: 0,
        paid: 0,
      },
    };
  },

  computed: {
    humanPhoneNumber() {
      return StringHelper.humanPhone(this.user.phone);
    },

    registrationDateTime() {
      return DateTime.fromISO(this.registeredAt, { zone: 'utc' }).toLocal();
    },

    registrationDate() {
      return DateHelper.humanDate(this.registrationDateTime);
    },

    registrationTime() {
      return this.registrationDateTime.toFormat('HH:mm');
    },
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData() {
      this.isLoading = true;

      try {
        await this.loadUser();
        await this.loadOrderStatistics();

        this.isLoaded = true;
      } catch (error) {
        SystemMessageService.addNotify(`Ошибка получения информации о клиенте: ${error}`, 'error');
      } finally {
        this.isLoading = false;
      }
    },

    async loadUser() {
      const response = await UserService.fetchUser(this.userId);

      this.user = response.user;
      this.registeredAt = response.registeredAt;
      this.balance = response.balance.amount;
    },

    async loadOrderStatistics() {
      const response = await UserStatisticsService.fetchOrdersStatistics(this.userId);

      this.orderStatistics.visits = response.count;
      this.orderStatistics.sold = response.sold;
      this.orderStatistics.paid = response.paid;
    },

    getHumanCost(cost) {
      return CostHelper.humanCost(cost);
    },

    getHumanBalance(balance) {
      return UserAccountHelper.humanBalance(balance);
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
