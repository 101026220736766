<template>
  <users-list />
</template>

<script>
import UsersList from '@/packages/user/components/list/index';

export default {
  name: 'UsersListView',

  components: { UsersList },
};
</script>

<style scoped></style>
