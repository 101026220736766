<template>
  <place-form
    v-if="place.id"
    :id="place.id"
    :name="place.name"
    :phone="place.phone"
    :state-id="place.state.id"
    :city-id="place.city.id"
    :street="place.address.line1"
    :address="place.address.line2"
    :time-from="place.timeFrom"
    :time-to="place.timeTo"
  />
</template>

<script>
import PlaceForm from '@/packages/place/components/form/index.component';
import PlaceService from '@/packages/place/services/place.service';

export default {
  name: 'PlaceEdit',

  components: { PlaceForm },

  data() {
    return {
      place: {},
    };
  },

  mounted() {
    PlaceService.fetchPlace(this.$route.params.id).then((response) => {
      this.place = response.place;
    });
  },
};
</script>

<style scoped></style>
