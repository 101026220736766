<template>
  <div v-if="isReady">
    <payment-filter :date-from="dateFrom" :date-to="dateTo" @change-dates="changeDates" />

    <status-filter
      :items="payments"
      :selected-statuses="selectedStatuses"
      @select="selectedStatuses = $event"
    />

    <payment-summary :items="filteredPayments" class="mt-2" />

    <payment-table
      :items="filteredPayments"
      :show-user="!userId"
      :loading="isLoading"
      @order-view="showOrderView"
    />

    <!--
    <order-view
      v-if="orderViewState.isActive"
      :order="orderViewState.order"
      @cancel="hideOrderView"
      @order-approve="showApproveOrderModal"
      @order-cancel="showCancelOrderModal"
      @order-prolong="showProlongOrderModal"
      @order-edit="showEditOrderForm"
    />

    <order-edit
      v-if="orderEditState.isActive"
      :order="orderEditState.order"
      @cancel="hideEditOrderForm"
      @updated="hideEditOrderForm"
    />

    <order-approve
      v-if="orderApproveState.isActive"
      :order="orderApproveState.order"
      @cancel="hideApproveOrderModal"
      @approved="hideApproveOrderModal"
    />

    <order-cancel
      v-if="orderCancelState.isActive"
      :order="orderCancelState.order"
      @cancel="hideCancelOrderModal"
      @canceled="hideCancelOrderModal"
    />

    <order-prolong
      v-if="orderProlongState.isActive"
      :order="orderProlongState.order"
      @cancel="hideProlongOrderModal"
      @prolonged="hideProlongOrderModal"
    />
    -->
  </div>
</template>

<script>
import PaymentService from '@/packages/payment/services/payment.service';
import PaymentFilter from '@/packages/payment/components/list/payment-filter';
import { DateTime } from 'luxon';
import PaymentTable from '@/packages/payment/components/list/table';
import StatusFilter from '@/packages/payment/components/list/status-filter';
import PaymentSummary from '@/packages/payment/components/list/summary';
import OrderActionsMixin from '@/packages/order/mixins/order-actions.mixin';

export default {
  name: 'PaymentsList',

  components: {
    PaymentSummary,
    StatusFilter,
    PaymentTable,
    PaymentFilter,
  },

  mixins: [OrderActionsMixin],

  props: {
    userId: {
      type: [String, null],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isReady: false,
      dateFrom: null,
      dateTo: null,

      isLoading: false,
      payments: [],

      selectedStatuses: [],
    };
  },

  computed: {
    filteredPayments() {
      if (this.selectedStatuses.length === 0) {
        return this.payments;
      }

      return this.payments.filter((payment) =>
        this.selectedStatuses.includes(payment.localStatus.uid)
      );
    },
  },

  mounted() {
    this.initDates();

    this.loadPayments();

    this.isReady = true;
  },

  methods: {
    initDates() {
      const now = DateTime.local();

      let dateFrom;
      let dateTo;

      if (now.day === 1) {
        dateFrom = now.minus({ months: 1 }).startOf('month').toISODate();
        dateTo = now.minus({ months: 1 }).endOf('month').toISODate();
      } else {
        dateFrom = now.startOf('month').toISODate();
        dateTo = now.toISODate();
      }

      this.dateFrom = dateFrom;
      this.dateTo = dateTo;
    },

    changeDates(dates) {
      this.dateFrom = dates[0];
      this.dateTo = dates[1];

      this.loadPayments();
    },

    loadPayments() {
      this.isLoading = true;

      const params = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      };

      if (this.userId !== null) {
        params.userId = this.userId;
      }

      PaymentService.fetchPayments(params)
        .then((payments) => {
          this.payments = payments;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
