import axios from '@/plugins/axios';
import { DateTime } from 'luxon';

function fetchOrders(params) {
  return axios.get('/order', { params }).then((response) =>
    response.data.orders.map((order) => {
      order.dateFrom = DateTime.fromSQL(order.dateFrom, { zone: 'UTC' }).setZone(
        order.place.timezone
      );

      order.dateTo = DateTime.fromSQL(order.dateTo, { zone: 'UTC' }).setZone(order.place.timezone);

      if (order.dateTo.toFormat('HH:mm') === '00:00') {
        order.dateTo = order.dateTo.minus({ days: 1 }).endOf('day');
      }

      return order;
    })
  );
}

function fetchWidgetOrders(params) {
  return axios
    .get(`/widget/order`, {
      params: {
        ...params,
        dateFrom: params.dateFrom.toUTC().toISODate(),
        dateTo: params.dateTo.toUTC().toISODate(),
      },
    })
    .then((response) =>
      response.data.orders.map((order) => {
        order.dateFrom = DateTime.fromSQL(order.dateFrom, { zone: 'UTC' }).setZone(
          order.place.timezone
        );

        order.dateTo = DateTime.fromSQL(order.dateTo, { zone: 'UTC' }).setZone(
          order.place.timezone
        );

        if (order.dateTo.toFormat('HH:mm') === '00:00') {
          order.dateTo = order.dateTo.minus({ days: 1 }).endOf('day');
        }

        return order;
      })
    );
}

function fetchOrder(orderId) {
  return axios.get(`/order/${orderId}`).then((response) => {
    const order = response.data.order;

    order.createdAt = DateTime.fromSQL(order.createdAt, { zone: 'UTC' }).toLocal();

    if (order.availableTo !== null) {
      order.availableTo = DateTime.fromSQL(order.availableTo, { zone: 'UTC' }).toLocal();
    }

    order.period.from = DateTime.fromSQL(order.period.from, { zone: 'UTC' }).setZone(
      order.place.timezone
    );

    order.period.to = DateTime.fromSQL(order.period.to, { zone: 'UTC' }).setZone(
      order.place.timezone
    );

    return order;
  });
}

function cancel(id) {
  return axios.patch(`/order/${id}/cancel`).then((response) => {
    return response.data;
  });
}

function fetchVersions(id) {
  return axios.get(`/order/${id}/history`).then((response) => {
    return response.data.versions;
  });
}

function createOrder(formData) {
  return axios.post('/order', formData).then((response) => response.data);
}

function payOrder(id) {
  return axios.get(`/order/${id}/pay`).then((response) => {
    return response.data;
  });
}

function approveOrder(id) {
  return axios.patch(`/order/${id}/approve`).then((response) => {
    return response.data;
  });
}

function updateOrder(orderId, formData) {
  return axios.patch(`/order/${orderId}`, formData).then((response) => response.data);
}

export default {
  fetchOrders,
  fetchWidgetOrders,
  fetchOrder,
  cancel,
  fetchVersions,
  createOrder,
  payOrder,
  approveOrder,
  updateOrder,
};
