<template>
  <v-dialog :value="true" :max-width="maxWidth">
    <v-card>
      <v-card-title>
        <slot name="title" />
      </v-card-title>

      <v-card-text>
        <slot name="text" />
      </v-card-text>

      <v-card-actions>
        <v-btn color="blue darken-1" small text @click="$emit('cancel')">
          {{ cancelText }}
        </v-btn>

        <v-spacer />

        <v-btn
          color="red"
          :dark="!disabled"
          small
          :disabled="disabled"
          :loading="isLoading"
          @click="$emit('confirm')"
        >
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    cancelText: {
      type: String,
      default: 'Закрыть',
    },

    confirmText: {
      type: String,
      required: true,
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    maxWidth: {
      type: [Number, String],
      default: 600,
    },
  },
};
</script>
