<template>
  <confirmation-modal
    confirm-text="Подтвердить бронирование"
    :is-loading="approvingState.isLoading"
    :disabled="isConfirmationDisabled"
    max-width="600px"
    @cancel="$emit('cancel')"
    @confirm="approveOrder"
  >
    <template #title>Подтверждение бронирования</template>

    <template #text>
      <p>
        {{
          $t('order.approveConfirmation', {
            name: order.room.name,
            date: orderDate,
            time: orderTime,
          })
        }}
      </p>

      <v-switch
        v-model="formData.confirmed"
        inset
        dense
        label="Я подтверждаю подтверждение бронирования."
      />
    </template>
  </confirmation-modal>
</template>

<script>
import ConfirmationModal from '@/common/components/confirmation-modal';
import { DateTime } from 'luxon';
import DateHelper from '@/common/helpers/date.helper';
import { mapGetters } from 'vuex';
import OrderService from '@/packages/order/services/order.service';
import SystemMessageService from '@/packages/system-message/services/system-message.service';

export default {
  name: 'OrderApprove',

  components: { ConfirmationModal },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      formData: {
        confirmed: false,
      },

      approvingState: {
        isLoading: false,
      },
    };
  },

  computed: {
    ...mapGetters(['currentUserId', 'isPaymentEnabled']),

    isConfirmationDisabled() {
      return !this.formData.confirmed;
    },

    orderDate() {
      return DateHelper.humanDate(this.order.period.from);
    },

    orderTime() {
      return DateHelper.humanDate(this.order.period.from, DateTime.TIME_SIMPLE);
    },
  },

  methods: {
    approveOrder() {
      this.approvingState.isLoading = true;

      OrderService.approveOrder(this.order.id)
        .then(() => {
          SystemMessageService.addNotify(`Бронирование подтверждено`, 'success');

          this.$emit('approved');
        })
        .catch((e) => {
          SystemMessageService.addNotify(`Ошибка подтверждения бронирования: ${e}`, 'error');

          this.approvingState.isLoading = false;
        });
    },
  },
};
</script>
