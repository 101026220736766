export default {
  // Создание бронирований для себя.
  ORDERS_CREATE: 'ORDERS_CREATE',

  // Создание бронирований для других.
  ORDERS_CREATE_FOR_OTHER_USER: 'ORDERS_CREATE_FOR_OTHER_USER',

  // Получение своих бронирований.
  ORDERS_GET: 'ORDERS_GET',

  // Получение всех бронирований.
  ORDERS_GET_ALL: 'ORDERS_GET_ALL',

  // Возможность редактирования за 24 часа до начала своего бронирования.
  ORDERS_EDIT: 'ORDERS_EDIT',

  // Возможность редактирования за 24 часа до начала любого бронирования.
  ORDERS_EDIT_ALL: 'ORDERS_EDIT_ALL',

  // Возможность редактирования вплоть до начала бронирования.
  ORDERS_EDIT_ANY_TIME: 'ORDERS_EDIT_ANY_TIME',

  // Возможность продления уже начатого своего бронирования.
  ORDERS_PROLONG: 'ORDERS_PROLONG',

  // Возможность продления уже начатого любого бронирования.
  ORDERS_PROLONG_ALL: 'ORDERS_PROLONG_ALL',

  // Возможность отменя за 24 часа до начала своего бронирования.
  ORDERS_CANCEL: 'ORDERS_CANCEL',

  // Возможность отменя за 24 часа до начала любого бронирования.
  ORDERS_CANCEL_ALL: 'ORDERS_CANCEL_ALL',

  // Возможность отмены вплоть до начала бронирования.
  ORDERS_CANCEL_ANY_TIME: 'ORDERS_CANCEL_ANY_TIME',

  // Возможность подтверждения бронирования.
  ORDERS_APPROVE: 'ORDERS_APPROVE',

  // Возможность изменения статуса оплаты для наличной оплаты.
  ORDERS_CHANGE_PAYMENT_STATUS: 'ORDERS_CHANGE_PAYMENT_STATUS',

  ORDERS_GET_USER_INFO: 'ORDERS_GET_USER_INFO',

  // Возможность изменить стоимость бронирования.
  ORDERS_SET_USER_SPECIFIED_COST: 'ORDERS_SET_USER_SPECIFIED_COST',

  // Возможность изменить метод бронирования.
  ORDERS_CHANGE_PAYMENT_METHOD: 'ORDERS_CHANGE_PAYMENT_METHOD',

  // Возможность установить время, до которого можно оплатить бронирование.
  ORDERS_SET_AVAILABLE_TO: 'ORDERS_SET_AVAILABLE_TO',
};
