var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"loading-text":"Загружаем список платежей","no-data-text":"Платежи не найдены","sort-by":"createdAt","sort-desc":"","item-key":"id","calculate-widths":"","loading":_vm.loading,"footer-props":{
    itemsPerPageText: 'Платежей на страницу',
    itemsPerPageAllText: 'Все',
  }},scopedSlots:_vm._u([{key:"item.publicId",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.publicId)+" "),_c('br'),_c('small',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.getHumanDate(item.createdAt))+" "+_vm._s(_vm.getTime(item.createdAt))+" ")])]}},{key:"item.operation",fn:function(ref){
  var item = ref.item;
return [(item.entity)?[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('order-view', item.entity.id)}}},[_vm._v("Оплата бронирования")])]:[_vm._v(" Пополнение счёта ")]]}},(_vm.isAdmin && _vm.showUser)?{key:"item.user",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'user-view', params: { id: item.user.id } }}},[_vm._v(" "+_vm._s(item.user.firstName)+" "+_vm._s(item.user.lastName)+" ")]),_c('br'),_c('a',{attrs:{"href":("tel:+" + (item.user.phone))}},[_vm._v(_vm._s(_vm.getHumanPhone(item.user.phone)))])]}}:null,{key:"item.status",fn:function(ref){
  var item = ref.item;
return [_c('span',{style:({ color: _vm.getStatusColor(item.localStatus.uid) })},[_vm._v(" "+_vm._s(_vm.getStatusLabel(item.localStatus.uid))+" ")]),(_vm.isFailed(item.localStatus.uid))?_c('div',{staticClass:"text--secondary"},[_c('small',[_vm._v(_vm._s(item.errorMessage))])]):_vm._e()]}},{key:"item.amount",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount.toLocaleString())+" "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" mdi-currency-rub ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }