<template>
  <div>
    <template v-if="!requestNumberState.isLoaded">
      <v-alert color="orange" type="info">
        <slot name="alert" />
      </v-alert>

      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn color="primary" small :loading="requestNumberState.isLoading" @click="getNumber">
            Получить номер
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <v-row v-if="requestNumberState.isLoaded && !confirmationState.isConfirmed">
      <v-col cols="12" class="text-center">
        <p class="mb-0">Номер для подтверждения:</p>

        <p class="text-h5">
          <a :href="`tel:+${requestNumberState.number}`">
            {{ humanizePhone(requestNumberState.number) }}
          </a>
        </p>

        <p class="mb-1">Данный номер доступен для подтвержения в течение 15-ти минут.</p>

        <slot name="description" />

        <v-progress-circular indeterminate color="primary" />
        <p>Ожидаем подтверждение номера</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CallStrategyService from '@/packages/phone-verification/services/call-strategy.service';
import StringHelper from '@/common/helpers/string.helper';
import SystemMessageService from '@/packages/system-message/services/system-message.service';

export default {
  name: 'PhoneVerification',

  props: {
    phone: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      requestNumberState: {
        isLoaded: false,
        isLoading: false,
        number: '',
      },

      confirmationState: {
        isConfirmed: false,
        intervalId: null,
      },
    };
  },

  methods: {
    getNumber() {
      this.requestNumberState.isLoading = true;

      CallStrategyService.getPhoneNumber(this.phone)
        .then((response) => {
          this.requestNumberState.number = response.phone;
          this.requestNumberState.isLoaded = true;

          this.runConfirmationCheck();
        })
        .finally(() => {
          this.requestNumberState.isLoading = false;
        });
    },

    runConfirmationCheck() {
      this.confirmationState.isConfirmed = false;
      this.confirmationState.isLoading = true;
      this.confirmationState.intervalId = null;

      setTimeout(() => {
        this.confirmationState.intervalId = setInterval(() => {
          this.checkStatus();
        }, 5000);
      }, 20000);
    },

    checkStatus() {
      CallStrategyService.checkStatus(this.phone).then((response) => {
        if (response.status === 1) {
          clearInterval(this.confirmationState.intervalId);
          this.confirmationState.intervalId = null;
          this.confirmationState.isConfirmed = true;

          SystemMessageService.addNotify(`Номер подтверждён`, 'success');

          this.$emit('confirmed', {
            accessToken: response.accessToken || null,
          });
        }
      });
    },

    humanizePhone(phone) {
      return StringHelper.humanPhone(phone);
    },
  },
};
</script>

<style scoped></style>
