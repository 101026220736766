<template>
  <confirmation-modal
    confirm-text="Отменить бронирование"
    :is-loading="cancellingState.isLoading"
    :disabled="isConfirmationDisabled"
    max-width="600px"
    @cancel="$emit('cancel')"
    @confirm="cancelOrder"
  >
    <template #title> Отмена бронирования </template>

    <template #text>
      <p>
        {{
          $t('order.cancelConfirmation', {
            name: order.room.name,
            date: orderDate,
            time: orderTime,
          })
        }}
      </p>

      <div v-if="refundOptionsState.isLoading" class="cancel-order__loader">
        <loader />
      </div>

      <template v-if="canRefund">
        <v-switch
          v-if="canRefundToCard"
          v-model="formData.needRefund"
          inset
          dense
          label="Вернуть средства на карту"
        />

        <v-alert type="success">
          {{ successMessage }}
        </v-alert>
      </template>

      <v-alert v-if="isTimeExceeded" type="warning">
        <p>
          <strong>Средства не будут возвращены на персональный счёт</strong>
        </p>

        <p>
          Средства возвращаются в полном объёме при отмене за 24 часа и ранее на персональный счёт
          или карту. Отмена бронирования не может быть произведена менее чем за 24 часа, средства не
          возвращаются и удерживаются в полном объеме за оказанную услугу.
        </p>
      </v-alert>

      <v-switch
        v-model="formData.confirmed"
        inset
        dense
        label="Я подтверждаю отмену бронирования."
      />
    </template>
  </confirmation-modal>
</template>

<script>
import ConfirmationModal from '@/common/components/confirmation-modal';
import { DateTime } from 'luxon';
import DateHelper from '@/common/helpers/date.helper';
import CostHelper from '@/packages/order/helpers/cost.helper';
import UserAccountHelper from '@/packages/user-account/helpers/user-account.helper';
import { mapGetters } from 'vuex';
import CancelOrderService from '@/packages/order/services/cancel-order.service';
import Loader from '@/common/components/loader';
import SystemMessageService from '@/packages/system-message/services/system-message.service';

export default {
  name: 'OrderCancel',

  components: { Loader, ConfirmationModal },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      formData: {
        needRefund: false,
        confirmed: false,
      },

      refundOptionsState: {
        isLoading: false,
      },

      cancellingState: {
        isLoading: false,
      },

      isTimeExceeded: false,
      canRefund: false,
      canRefundToCard: false,
    };
  },

  computed: {
    ...mapGetters(['currentUserId', 'isPaymentEnabled']),

    isConfirmationDisabled() {
      return !this.formData.confirmed || this.refundOptionsState.isLoading;
    },

    orderDate() {
      return DateHelper.humanDate(this.order.period.from);
    },

    orderTime() {
      return DateHelper.humanDate(this.order.period.from, DateTime.TIME_SIMPLE);
    },

    humanCost() {
      return CostHelper.humanCost(this.order.cost.amount);
    },

    humanBonuses() {
      return UserAccountHelper.humanBalance(this.order.cost.amount);
    },

    isOwner() {
      return this.currentUserId === this.order.user.id;
    },

    successMessage() {
      let amount = this.humanBonuses;
      let destination = 'Ваш персональный счёт';

      if (!this.isOwner) {
        destination = 'персональный счёт клиента';
      }

      if (this.formData.needRefund) {
        amount = this.humanCost;
        destination = 'банковскую карту, с помощью которой была произведена оплата';

        if (!this.isOwner) {
          destination = 'банковскую карту клиента';
        }
      }

      return `${amount} будут возвращены на ${destination}.`;
    },
  },

  mounted() {
    if (this.isPaymentEnabled === true) {
      this.loadRefundOptions();
    }
  },

  methods: {
    loadRefundOptions() {
      this.refundOptionsState.isLoading = true;

      CancelOrderService.fetchRefundOptions(this.order.id)
        .then((response) => {
          this.isTimeExceeded = response.isTimeExceeded;
          this.canRefund = response.canRefund;
          this.canRefundToCard = response.canRefundToCard;
        })
        .finally(() => {
          this.refundOptionsState.isLoading = false;
        });
    },

    cancelOrder() {
      this.cancellingState.isLoading = true;

      CancelOrderService.cancelOrder(this.order.id, this.formData.needRefund)
        .then(() => {
          SystemMessageService.addNotify(`Бронирование отменено`, 'success');

          this.$emit('canceled');
        })
        .catch((e) => {
          SystemMessageService.addNotify(`Ошибка отмены бронирования: ${e}`, 'error');

          this.cancellingState.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.cancel-order {
  &__loader {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
}
</style>
