<template>
  <v-container>
    <template v-if="isLoading">
      <div class="text-center">
        <p class="mb-5">Ожидаем ответ от банка</p>

        <v-progress-circular :size="100" color="primary" indeterminate />
      </div>
    </template>

    <template v-else>
      <template v-if="hasOrder">
        <h2 class="ml-1 mb-3">Бронирование оплачено</h2>

        <v-alert type="success"> Ваше бронирование успешно оплачено </v-alert>

        <order-info
          :cost="order.cost"
          :date-from="order.dateFrom"
          :date-to="order.dateTo"
          :place-name="order.place.name"
          :place-timezone="order.place.timezone"
          :room-name="order.room.name"
          :visitors="order.visitors"
        />

        <v-row>
          <v-col class="text-center text-sm-left" sm="6" xs="12">
            <v-btn v-if="fromSite" :href="order.room.url" color="primary" small>
              > Вернуться на сайт
            </v-btn>

            <v-btn v-else :to="{ name: 'order' }" color="primary" small> Вернуться на сайт </v-btn>
          </v-col>

          <v-col class="text-center text-sm-right" sm="6" xs="12">
            <v-btn :to="{ name: 'order' }" color="primary" small> Посмотреть бронирования </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-else>
        <h2 class="ml-1 mb-3">Баланс пополнен</h2>

        <div>Вы успешно пополнили личный счёт.</div>

        <v-list dense>
          <v-list-item dense>
            <v-list-item-content>Сумма пополнения</v-list-item-content>

            <v-list-item-action>{{ getHumanCost(amount) }}</v-list-item-action>
          </v-list-item>

          <v-list-item dense two-line>
            <v-list-item-content>
              Текущий баланс
              <v-list-item-subtitle>Один бонус равняется одному рублю РФ</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>{{ getHumanBalance(user.balance) }}</v-list-item-action>
          </v-list-item>

          <!--
          <v-list-item dense>
            <v-list-item-content>Неоплаченные услуги</v-list-item-content>

            <v-list-item-action>500 бонусов</v-list-item-action>
          </v-list-item>
          -->
        </v-list>

        <!--
        <v-divider />

        <v-list dense>
          <v-subheader>Неоплаченные услуги</v-subheader>

          <v-list-item dense three-line>
            <v-list-item-content>
              <v-list-item-title>Бронирование</v-list-item-title>
              <v-list-item-subtitle>10 января 2021 19:00</v-list-item-subtitle>
              <v-list-item-subtitle>500 бонусов</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn color="primary" small>Оплатить</v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-list-item dense three-line>
            <v-list-item-content>
              <v-list-item-title>Бронирование</v-list-item-title>
              <v-list-item-subtitle>10 января 2021 19:00</v-list-item-subtitle>
              <v-list-item-subtitle>500 бонусов</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn color="primary" small>Оплатить</v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        -->
      </template>
    </template>
  </v-container>
</template>

<script>
import CurrentUserService from '@/packages/user/services/current-user.service';
import PaymentService from '@/packages/payment/services/payment.service';
import CostHelper from '@/common/helpers/cost.helper';
import { mapGetters } from 'vuex';
import OrderInfo from '@/packages/order/components/common/order-info';

export default {
  name: 'UserAccountStatusSuccess',
  components: { OrderInfo },
  data() {
    return {
      isLoading: true,

      status: 'NEW',
      amount: 0,
      order: null,
      fromSite: false,

      intervalId: null,
    };
  },

  computed: {
    ...mapGetters(['user']),

    hasOrder() {
      return this.order !== null;
    },
  },

  mounted() {
    this.loadPayment();

    this.intervalId = setInterval(() => {
      this.loadPayment();
    }, 10000);
  },

  methods: {
    loadPayment() {
      PaymentService.fetchPaymentInfo(this.$route.params.id).then((payment) => {
        if (payment.status.uid === 'PAID') {
          clearInterval(this.intervalId);
          this.intervalId = null;
          this.isLoading = false;

          CurrentUserService.fetchBalance().then((balance) => {
            CurrentUserService.storeBalance(balance.amount);
          });
        }

        this.status = payment.status.uid;
        this.amount = payment.amount;
        this.fromSite = payment.fromSite;

        if ('order' in payment) {
          this.order = payment.order;
        }
      });
    },

    getHumanCost(cost) {
      return CostHelper.humanCost(cost);
    },

    getHumanBalance(cost) {
      return CostHelper.humanBalance(cost);
    },
  },
};
</script>

<style scoped></style>
