<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-progress-circular indeterminate color="primary" :size="50" />
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'MainLoader',
};
</script>

<style scoped></style>
