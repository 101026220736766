import axios from '@/plugins/axios';
import store from '@/store';

function fetchCustomerInfo() {
  return axios.get('/customer').then((response) => response.data.customer);
}

function storeCustomer(customer) {
  store.dispatch('customer/setCustomer', {
    id: customer.id,
    isEnabled: customer.isEnabled,
    settings: {
      payment: {
        isEnabled: customer.settings.payment.isEnabled,
        methods: customer.settings.payment.methods,
      },
    },
  });
}

export default { fetchCustomerInfo, storeCustomer };
