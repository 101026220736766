import cookies from 'vue-cookies';

function get(key) {
  return cookies.get(key);
}

function set(key, value) {
  cookies.set(
    key,
    value,
    60 * 60 * 24 * 30,
    '/',
    process.env.VUE_APP_COOKIE_FASTI_DOMAIN,
    process.env.VUE_APP_ENV === 'production',
    process.env.VUE_APP_ENV === 'production' ? 'None, Secure' : 'None'
  );
  cookies.set(key, value, 60 * 60 * 24 * 30, '/');
}

function clear(key) {
  cookies.remove(key, '/', process.env.VUE_APP_COOKIE_FASTI_DOMAIN);
  cookies.remove(key, '/');
}

export default {
  get,
  set,
  clear,
};
