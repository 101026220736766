<template>
  <v-row>
    <v-col cols="7">
      Текущий баланс

      <br />

      <small>Один бонус равняется одному рублю РФ</small>
    </v-col>

    <v-col cols="5" class="text-right">
      {{ humanBalance }}

      <div v-if="showRefillButton" class="mt-2">
        <v-btn small color="primary" :to="{ name: 'user-account.refill' }">Пополнить</v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CostHelper from '@/common/helpers/cost.helper';

export default {
  name: 'UserAccountSummary',

  props: {
    balance: {
      type: Number,
      required: false,
      default: 0,
    },

    showRefillButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    humanBalance() {
      return CostHelper.humanBalance(this.balance);
    },
  },
};
</script>

<style scoped></style>
