<template>
  <v-row v-if="places.length > 1" dense>
    <v-col cols="12" sm="4" class="ma-auto mb-1">
      <div class="text-center">
        <v-select
          :items="places"
          :value="selectedPlaceId"
          :full-width="false"
          item-text="name"
          item-value="id"
          :label="$t('place.selectLabel')"
          class="pb-0 mb-0"
          hide-details
          @change="$emit('change', $event)"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'PlaceSelect',

  props: {
    selectedPlaceId: {
      type: String,
      required: true,
    },

    places: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped></style>
