<template>
  <div>
    <v-card class="mb-5">
      <v-card-title>Основные данные</v-card-title>

      <v-card-text>
        <v-row v-if="places.length > 0" dense>
          <v-col cols="12">
            <v-select
              v-model="$v.formData.placeId.$model"
              :items="places"
              :label="$t('place.label')"
              item-text="name"
              item-value="id"
              menu-props="auto"
              :return-object="false"
              :loading="placesLoading"
              persistent-hint
              :disabled="places.length === 1"
              :error-messages="placeErrors"
              @blur="$v.formData.placeId.$touch()"
              @change="$v.formData.placeId.$touch()"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model.trim="$v.formData.name.$model"
              label="Название"
              :counter="150"
              persistent-hint
              :error-messages="nameErrors"
              @blur="$v.formData.name.$touch()"
              @input="$v.formData.name.$touch()"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model.trim="$v.formData.visitors.$model"
              type="number"
              label="Максимальное количество посетителей"
              persistent-hint
              :error-messages="visitorsErrors"
              @blur="$v.formData.visitors.$touch()"
              @input="$v.formData.visitors.$touch()"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model.trim="$v.formData.url.$model"
              label="Ссылка на страницу с формой бронирования на сайте"
              :counter="250"
              persistent-hint
              hint="Необходима для перехода на страницу с календарём из личного кабинета"
              :error-messages="urlErrors"
              @blur="$v.formData.url.$touch()"
              @input="$v.formData.url.$touch()"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model.trim="$v.formData.picture.$model"
              label="Ссылка на изображение"
              :counter="250"
              persistent-hint
              hint="Отображется в форме бронирования"
              :error-messages="pictureErrors"
              @blur="$v.formData.picture.$touch()"
              @input="$v.formData.picture.$touch()"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mb-5">
      <v-card-title>Настройки бронирования</v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-switch
              v-model="formData.isActive"
              dense
              label="Доступно для бронирования"
              hint="Не отображается в календаре пока недоступно."
              persistent-hint
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-subtitle>Шаг времени начала бронирования</v-card-subtitle>

      <v-card-text>
        <v-row dense>
          <v-col cols="6" sm="3">
            <v-select
              v-model="formData.startTimeStep"
              :items="startTimeStepItems"
              item-text="label"
              item-value="value"
              label="Минуты"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-subtitle>Минимальное время бронирования</v-card-subtitle>

      <v-card-text>
        <v-row dense>
          <v-col cols="6" sm="3">
            <v-select
              v-model="formData.minimalOrderTime.hours"
              :items="hourItems"
              item-text="label"
              item-value="value"
              label="Часы"
              :error="!isMinimalTimeValid"
            />
          </v-col>

          <v-col cols="6" sm="3">
            <v-select
              v-model="formData.minimalOrderTime.minutes"
              :items="minuteItems"
              item-text="label"
              item-value="value"
              label="Минуты"
              :error="!isMinimalTimeValid"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-subtitle>Шаг времени бронирования</v-card-subtitle>

      <v-card-text>
        <v-row dense>
          <v-col cols="6" sm="3">
            <v-select
              v-model="formData.orderTimeStep.hours"
              :items="hourItems"
              item-text="label"
              item-value="value"
              label="Часы"
              :error="!isOrderStepValid"
            />
          </v-col>

          <v-col cols="6" sm="3">
            <v-select
              v-model="formData.orderTimeStep.minutes"
              :items="minuteItems"
              item-text="label"
              item-value="value"
              label="Минуты"
              :error="!isOrderStepValid"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-switch
              v-model="formData.allowDailyOrder"
              dense
              label="Возможно бронирование на целый день"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-switch
              v-model="formData.allowManyDaysOrder"
              dense
              label="Возможно бронирование на несколько дней"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-subtitle>Дополнительное время</v-card-subtitle>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-switch
              v-model="formData.serviceTimeEnabled"
              dense
              label="Добавить время к бронированию"
            />
          </v-col>
        </v-row>

        <template v-if="formData.serviceTimeEnabled">
          <v-row dense>
            <v-col cols="6" sm="3">
              <v-select
                v-model="formData.serviceTimeValue"
                :items="serviceTimeItems"
                item-text="label"
                item-value="value"
                label="Время"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-switch
                v-model="formData.serviceTimeFree"
                dense
                label="Дополнительное время бесплатно для клиента"
              />
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>

    <v-card tile flat class="mb-5">
      <v-card-title> Стоимость бронирования</v-card-title>

      <hourly-price-view
        v-if="hourlyCosts.length > 0"
        :items="hourlyCosts"
        class="mb-2"
        @delete="deleteCost"
        @edit="editCost"
      />

      <daily-price-view
        v-if="dailyCosts.length > 0"
        :items="dailyCosts"
        class="mb-2"
        @delete="deleteCost"
        @edit="editCost"
      />

      <many-days-price-view
        v-if="manyDaysCost.id && manyDaysCost.values"
        :cost-values="manyDaysCost.values"
        class="mb-2"
        @delete="deleteCost(manyDaysCost.id)"
        @edit="editCost(manyDaysCost.id)"
      />

      <v-card-actions>
        <v-btn
          small
          color="blue"
          :dark="!!formData.placeId"
          :disabled="!formData.placeId"
          @click="openCostModal"
        >
          Добавить стоимость
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mb-5">
      <v-card-title>Настройки календаря</v-card-title>

      <v-card-text>
        <p class="pt-3">Цвет события в календаре</p>
        <v-color-picker v-model="formData.color" hide-mode-switch mode="hexa" />
      </v-card-text>
    </v-card>

    <v-card tile flat>
      <v-card-actions>
        <v-btn color="blue darken-1" small text :to="{ name: 'customer-room' }">Назад</v-btn>

        <v-spacer></v-spacer>

        <v-btn color="primary" small :loading="isLoading" :disabled="!isFormValid" @click="save">
          {{ saveButtonTitle }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <price-form
      v-if="selectedPlace && costModal.isActive"
      :cost-id="costModal.costId"
      :is-edit="costModal.isEdit"
      :time-from="selectedPlace.timeFrom"
      :time-to="selectedPlace.timeTo"
      :days-allowed="formData.allowDailyOrder"
      :many-days-allowed="formData.allowManyDaysOrder"
      :minimal-order-time="minimalOrderTimeInMinutes"
      :existing-items="formData.costs"
      @close="closePriceModal"
      @save="saveCost"
    />
  </div>
</template>

<script>
import {
  helpers,
  maxLength,
  maxValue,
  minValue,
  numeric,
  required,
} from 'vuelidate/lib/validators';
import PlaceService from '@/packages/place/services/place.service';
import DayHelper from '@/common/helpers/day.helper';
import TimeHelper from '@/common/helpers/time.helper';
import { isWebUri } from 'valid-url';
import RoomService from '@/packages/room/services/room.service';
import SystemMessageService from '@/packages/system-message/services/system-message.service';
import StringHelper from '@/common/helpers/string.helper';
import PriceForm from '@/packages/room/components/form/price-form';
import HourlyPriceView from '@/packages/room/components/form/hourly-price-view';
import CostConstants from '@/packages/order/inventory/cost.constants';
import DailyPriceView from '@/packages/room/components/form/daily-price-view';
import ManyDaysPriceView from '@/packages/room/components/form/many-days-price-view';

const mustBeUrl = helpers.withParams(
  { type: 'url' },
  (value) => !helpers.req(value) || !!isWebUri(value)
);

const mustBeColor = (value) => {
  return /#[0-9a-zA-Z]{6}/.test(value);
};

export default {
  name: 'RoomForm',

  components: { ManyDaysPriceView, DailyPriceView, HourlyPriceView, PriceForm },

  props: {
    roomId: {
      type: String,
      required: false,
      default: '',
    },

    placeId: {
      type: String,
      required: false,
      default: '',
    },

    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },

    name: {
      type: String,
      required: false,
      default: '',
    },

    visitors: {
      type: Number,
      required: false,
      default: 2,
    },

    costs: {
      type: Array,
      required: false,
      default: () => [],
    },

    url: {
      type: String,
      required: false,
      default: '',
    },

    color: {
      type: String,
      required: false,
      default: '#00BCD4',
    },

    minimalOrderTime: {
      type: Number,
      required: false,
      default: 60,
    },

    orderStepTime: {
      type: Number,
      required: false,
      default: 30,
    },

    allowDailyOrder: {
      type: Boolean,
      required: false,
      default: false,
    },

    allowManyDaysOrder: {
      type: Boolean,
      required: false,
      default: false,
    },

    startTimeStep: {
      type: Number,
      required: false,
      default: 30,
    },

    serviceTimeEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    serviceTimeValue: {
      type: Number,
      required: false,
      default: 0,
    },

    serviceTimeFree: {
      type: Boolean,
      required: false,
      default: true,
    },

    picture: {
      type: [String, null],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      formData: {
        placeId: null,
        isActive: false,
        name: '',
        visitors: 2,
        costs: [],
        url: '',
        color: '#00BCD4',
        picture: null,

        minimalOrderTime: {
          hours: 1,
          minutes: 0,
        },

        orderTimeStep: {
          hours: 0,
          minutes: 30,
        },

        allowDailyOrder: false,
        allowManyDaysOrder: false,

        startTimeStep: 30,

        serviceTimeEnabled: false,
        serviceTimeValue: 0,
        serviceTimeFree: true,
      },

      places: [],
      placesLoading: true,

      isLoading: false,

      days: DayHelper.getDays(),

      costModal: {
        isActive: false,
        costId: null,
        isEdit: false,
      },

      showPriceModal: false,
    };
  },

  validations() {
    const rules = {
      formData: {
        name: {
          required,
          maxLength: maxLength(150),
        },
        visitors: {
          required,
          numeric,
          minValue: minValue(1),
        },
        costs: {
          required,
          $each: {
            day: {
              minValue: minValue(0),
              maxValue: maxValue(9),
            },
            values: {
              required,
              $each: {
                starts: {
                  required,
                },
              },
            },
          },
        },
        url: {
          required,
          maxLength: maxLength(250),
          mustBeUrl,
        },
        picture: {
          mustBeUrl,
        },
        color: {
          required,
          mustBeColor,
        },
      },
    };

    if (this.places.length > 0) {
      rules.formData.placeId = {
        required,
      };
    }

    return rules;
  },

  computed: {
    selectedPlace() {
      return this.places.find((place) => place.id === this.formData.placeId);
    },

    startTimes() {
      let start = '00:00';
      let end = '00:00';

      if (this.selectedPlace && this.selectedPlace.id) {
        start = this.selectedPlace.timeFrom;
        end = this.selectedPlace.timeTo;
      }

      return TimeHelper.generateForTimePicker({ start, end });
    },

    endTimes() {
      let start = '00:00';
      let end = '00:00';

      if (this.selectedPlace && this.selectedPlace.id) {
        start = this.selectedPlace.timeFrom;
        end = this.selectedPlace.timeTo;
      }

      return TimeHelper.generateForTimePicker({ start, end });
    },

    saveButtonTitle() {
      return this.roomId ? 'Сохранить' : 'Добавить';
    },

    hourItems() {
      const items = 6;
      return new Array(items).fill(1).map((_value, index) => {
        const label = StringHelper.declOfNum(index, ['час', 'часа', 'часов']);

        return {
          value: index,
          label: `${index} ${label}`,
        };
      });
    },

    minuteItems() {
      const items = 60 / 15;

      return new Array(items).fill(1).map((_value, index) => {
        const minute = index * 15;
        return {
          value: minute,
          label: `${minute} минут`,
        };
      });
    },

    startTimeStepItems() {
      return [
        {
          value: 15,
          label: '15 минут',
        },
        {
          value: 30,
          label: '30 минут',
        },
        {
          value: 60,
          label: '1 час',
        },
      ];
    },

    serviceTimeItems() {
      return [
        {
          value: 0,
          label: '0 минут',
        },
        {
          value: 15,
          label: '15 минут',
        },
        {
          value: 30,
          label: '30 минут',
        },
        {
          value: 45,
          label: '45 минут',
        },
        {
          value: 60,
          label: '1 час',
        },
      ];
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.formData.name.$dirty) return errors;

      if (!this.$v.formData.name.required) {
        errors.push('Введите название');
      }

      if (!this.$v.formData.name.maxLength) {
        errors.push('Название слишком длинное');
      }

      return errors;
    },

    placeErrors() {
      const errors = [];
      if (!this.$v.formData.placeId.$dirty) return errors;

      if (!this.$v.formData.placeId.required) {
        errors.push(this.$t('place.selectLabel'));
      }

      return errors;
    },

    visitorsErrors() {
      const errors = [];
      if (!this.$v.formData.visitors.$dirty) return errors;

      if (!this.$v.formData.visitors.required) {
        errors.push('Укажите количество посетителей');
      }

      if (!this.$v.formData.visitors.minValue) {
        errors.push('Укажите количество посетителей');
      }

      return errors;
    },

    urlErrors() {
      const errors = [];
      if (!this.$v.formData.url.$dirty) return errors;

      if (!this.$v.formData.url.required) {
        errors.push('Укажите ссылку');
      }

      if (!this.$v.formData.url.mustBeUrl) {
        errors.push('Неправильный формат ссылки');
      }

      if (!this.$v.formData.url.maxLength) {
        errors.push('Ссылка слишком длинная');
      }

      return errors;
    },

    pictureErrors() {
      const errors = [];
      if (!this.$v.formData.picture.$dirty) return errors;

      if (!this.$v.formData.picture.mustBeUrl) {
        errors.push('Неправильный формат ссылки');
      }

      return errors;
    },

    isMinimalTimeValid() {
      return this.formData.minimalOrderTime.hours > 0 || this.formData.minimalOrderTime.minutes > 0;
    },

    isOrderStepValid() {
      return this.formData.orderTimeStep.hours > 0 || this.formData.orderTimeStep.minutes > 0;
    },

    isFormValid() {
      return !this.$v.$invalid && this.isMinimalTimeValid && this.isOrderStepValid;
    },

    minimalOrderTimeInMinutes() {
      return this.formData.minimalOrderTime.hours * 60 + this.formData.minimalOrderTime.minutes;
    },

    hourlyCosts() {
      return this.formData.costs.filter((item) => item.costType === CostConstants.TYPE_HOURLY);
    },

    dailyCosts() {
      return this.formData.costs.filter((item) => item.costType === CostConstants.TYPE_DAILY);
    },

    manyDaysCost() {
      return (
        this.formData.costs.find((item) => item.costType === CostConstants.TYPE_MANY_DAYS) || {
          values: [],
        }
      );
    },
  },

  mounted() {
    this.initValues();

    this.loadPlaces();
  },

  methods: {
    initValues() {
      this.formData.placeId = this.placeId;
      this.formData.isActive = this.isActive;
      this.formData.name = this.name;
      this.formData.visitors = this.visitors;
      this.formData.url = this.url;
      this.formData.color = this.color;
      this.formData.picture = this.picture;

      if (this.placeId) {
        this.formData.costs = this.costs;
      }

      this.formData.minimalOrderTime.hours = Math.floor(this.minimalOrderTime / 60);
      this.formData.minimalOrderTime.minutes =
        this.minimalOrderTime - Math.floor(this.minimalOrderTime / 60) * 60;

      this.formData.orderTimeStep.hours = Math.floor(this.orderStepTime / 60);
      this.formData.orderTimeStep.minutes =
        this.orderStepTime - Math.floor(this.orderStepTime / 60) * 60;

      this.formData.allowDailyOrder = this.allowDailyOrder;
      this.formData.allowManyDaysOrder = this.allowManyDaysOrder;
      this.formData.startTimeStep = this.startTimeStep;
      this.formData.serviceTimeEnabled = this.serviceTimeEnabled;
      this.formData.serviceTimeValue = this.serviceTimeValue;
      this.formData.serviceTimeFree = this.serviceTimeFree;
    },

    loadPlaces() {
      this.placesLoading = true;
      PlaceService.fetchPlaces()
        .then((places) => {
          this.places = places;

          if (this.places.length === 1) {
            this.formData.placeId = this.places[0].id;
          }
        })
        .finally(() => {
          this.placesLoading = false;
        });
    },

    save() {
      this.isLoading = true;

      if (this.roomId) {
        this.updateRoom();
      } else {
        this.createRoom();
      }
    },

    createRoom() {
      RoomService.createRoom(this.prepareRequestData())
        .then(() => {
          SystemMessageService.addNotify(
            this.$t('room.successfulAdded', { name: this.formData.name }),
            'success'
          );

          this.$router.push({ name: 'customer-room' });
        })
        .catch((error) => {
          let message;
          let code = 0;

          if (error.response && error.response.data) {
            message = error.response.data.message || 'Unknown';
          }

          if (error.response && error.response.status) {
            code = error.response.status;
          }

          SystemMessageService.addNotify(`Ошибка добавления: ${message} (${code})`, 'error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    updateRoom() {
      RoomService.updateRoom(this.roomId, this.prepareRequestData())
        .then(() => {
          SystemMessageService.addNotify(
            this.$t('room.successfulSaved', { name: this.formData.name }),
            'success'
          );

          this.$router.push({ name: 'customer-room' });
        })
        .catch((error) => {
          let message;
          let code = 0;

          if (error.response && error.response.data) {
            message = error.response.data.message || 'Unknown';
          }

          if (error.response && error.response.status) {
            code = error.response.status;
          }

          SystemMessageService.addNotify(`Ошибка сохранения: ${message} (${code})`, 'error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    prepareRequestData() {
      return {
        placeId: this.formData.placeId,
        isActive: this.formData.isActive,
        name: this.formData.name,
        visitors: this.formData.visitors,
        url: this.formData.url,
        color: this.formData.color,
        costs: this.formData.costs,
        picture: this.formData.picture,
        settings: {
          minimalOrderTime: this.minimalOrderTimeInMinutes,
          orderStepTime:
            this.formData.orderTimeStep.hours * 60 + this.formData.orderTimeStep.minutes,
          allowDailyOrder: this.formData.allowDailyOrder,
          allowManyDaysOrder: this.formData.allowManyDaysOrder,
          startTimeStep: this.formData.startTimeStep,
          serviceTimeEnabled: this.formData.serviceTimeEnabled,
          serviceTimeValue: this.formData.serviceTimeValue,
          serviceTimeFree: this.formData.serviceTimeFree,
        },
      };
    },

    saveCost(costItem) {
      if (!Number.isInteger(costItem.id)) {
        costItem.id = this.getTemporaryId();
        this.formData.costs.push(costItem);
      } else {
        const index = this.formData.costs.findIndex((item) => item.id === costItem.id);

        if (index >= 0) {
          this.formData.costs.splice(index, 1, costItem);
        }
      }

      this.closePriceModal();
    },

    getTemporaryId() {
      let id = -1;

      this.formData.costs.forEach((item) => {
        if (item.id <= id) {
          id = item.id - 1;
        }
      });

      return id;
    },

    openCostModal() {
      this.costModal.isActive = true;
    },

    editCost(costId) {
      this.costModal.isEdit = true;
      this.costModal.costId = costId;
      this.costModal.isActive = true;
    },

    closePriceModal() {
      this.costModal.isEdit = false;
      this.costModal.costId = null;
      this.costModal.isActive = false;
    },

    deleteCost(costId) {
      const index = this.formData.costs.findIndex((item) => item.id === costId);

      if (index >= 0) {
        this.formData.costs.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped></style>
