<template>
  <v-dialog :value="true" max-width="600px" persistent scrollable>
    <v-card>
      <v-card-title>Просмотр бронирования</v-card-title>

      <v-card-text>
        <template v-if="isUserCanOrdersGetAll">
          <v-row dense>
            <v-col cols="12" sm="8">
              <user-info
                :email="order.user.email"
                :first-name="order.user.firstName"
                :last-name="order.user.lastName"
                :phone-number="order.user.phone"
              />
            </v-col>

            <v-col cols="12" sm="4">
              <template v-if="$vuetify.breakpoint.xsOnly">
                <v-divider class="my-3" />
              </template>

              <user-statistics
                :is-loading="userStatisticsState.isLoading"
                :paid="userStatistics.paid"
                :sold="userStatistics.sold"
                :visits="userStatistics.visits"
              />
            </v-col>
          </v-row>

          <v-divider class="my-3" />
        </template>

        <v-row v-if="humanAvailableTo && !isOrderCanceled" dense>
          <v-col sm="12">
            <v-alert type="warning">
              Вам необходимо оплатить бронирование до {{ humanAvailableTo }}.
            </v-alert>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="8">
            <order-info :order="order" />
          </v-col>

          <v-col cols="12" sm="4">
            <v-divider v-if="$vuetify.breakpoint.xsOnly" class="my-3" />

            <order-actions-menu :order="order" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn class="ma-auto" color="blue darken-1" small text @click="$emit('cancel')">
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OrderInfo from '@/packages/order/components/view/order-info';
import UserInfo from '@/packages/order/components/view/user-info';
import PermissionMixin from '@/packages/permission/mixins/permission.mixin';
import UserStatisticsService from '@/packages/user/services/user-statistics.service';
import UserStatistics from '@/packages/order/components/view/user-statistics';
import DateHelper from '@/common/helpers/date.helper';
import { DateTime } from 'luxon';
import OrderActionsMenu from '@/packages/order/components/view/order-actions-menu';
import OrderStatusEnum from '@/packages/order/enums/order-status.enum';

export default {
  name: 'OrderView',

  components: {
    OrderActionsMenu,
    UserStatistics,
    UserInfo,
    OrderInfo,
  },

  mixins: [PermissionMixin],

  props: {
    order: {
      type: Object,
      required: true,
    },

    userId: {
      type: [String, null],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      viewModalState: {
        active: true,
      },

      orderState: {
        isLoading: true,
        isLoaded: false,
      },

      userStatisticsState: {
        isLoading: true,
        isLoaded: false,
      },

      userStatistics: {
        visits: 0,
        sold: 0,
        paid: 0,
      },
    };
  },

  computed: {
    humanAvailableTo() {
      if (!this.order.availableTo) {
        return '';
      }

      const date = DateHelper.humanDate(this.order.availableTo, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      const time = DateHelper.humanDate(this.order.availableTo, DateTime.TIME_24_WITH_SECONDS);

      return `${date} ${time}`;
    },

    isOrderCanceled() {
      return this.order.status.uid === OrderStatusEnum.CANCELED;
    },
  },

  mounted() {
    if (this.isUserCanOrdersGetAll) {
      this.loadUserStat();
    }
  },

  methods: {
    loadUserStat() {
      this.userStatisticsState.isLoading = true;

      UserStatisticsService.fetchOrdersStatistics(this.order.user.id)
        .then((data) => {
          this.userStatistics = {
            visits: data.count,
            sold: data.sold,
            paid: data.paid,
          };

          this.userStatisticsState.isLoaded = true;
        })
        .finally(() => {
          this.userStatisticsState.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
