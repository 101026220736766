<template>
  <v-container>
    <h2 class="ml-1 mb-3">Ошибка пополнения</h2>

    <user-account-status-fail />
  </v-container>
</template>

<script>
import UserAccountStatusFail from '@/packages/user-account/components/status/fail';

export default {
  name: 'StatusFailView',

  components: { UserAccountStatusFail },
};
</script>

<style scoped></style>
