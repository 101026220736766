import axios from '@/plugins/axios';

function fetchStates() {
  return axios.get('/geo/states').then((response) => {
    return response.data;
  });
}

function fetchCountries(stateId) {
  return axios
    .get('/geo/cities', {
      params: {
        stateId,
      },
    })
    .then((response) => {
      return response.data;
    });
}

export default { fetchStates, fetchCountries };
