<template>
  <v-card>
    <v-card-title>Восстановление пароля</v-card-title>

    <form @submit.prevent="setPassword">
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-mask="'+# (###) ###-##-##'"
              :value="phone"
              disabled
              label="Номер телефона"
              name="phone"
              placeholder="+7 (999) 999-99-99"
              readonly
              type="text"
            />
          </v-col>
        </v-row>

        <PhoneVerification :phone="phone" @confirmed="updatePhoneConfirmationStatus">
          <template v-slot:alert>
            Для восстановления пароля Вам необходимо подтвердить Ваш номер телефона. Чтобы
            подтвердить номер, Вам необходимо позвонить на определённый номер. Получите номер нажав
            на кнопку "Получить номер" и позвоните на полученый номер. Ваш звонок сбросится
            автоматически. Это
            <strong>бесплатно для Вас</strong>.
          </template>

          <template v-slot:description>
            <p>После подтверждения номера Вы сможете изменить пароль.</p>
          </template>
        </PhoneVerification>

        <template v-if="phoneConfirmed">
          <v-row dense>
            <v-col cols="12">
              <div class="text-h6">Задайте новый пароль</div>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="$v.formData.password.$model"
                :error-messages="passwordErrors"
                :readonly="isLoading"
                :loading="isLoading"
                label="Пароль"
                placeholder="Пароль"
                type="password"
                @blur="$v.formData.password.$touch()"
                @focus="$v.formData.password.$reset()"
                @input="$v.formData.password.$touch()"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="$v.formData.passwordConfirmation.$model"
                :error-messages="passwordConfirmationErrors"
                :readonly="isLoading"
                :loading="isLoading"
                label="Подтверждение пароля"
                placeholder="Подтверждение пароля"
                type="password"
                @blur="$v.formData.passwordConfirmation.$touch()"
                @focus="$v.formData.passwordConfirmation.$reset()"
                @input="$v.formData.passwordConfirmation.$touch()"
              />
            </v-col>
          </v-row>
        </template>
      </v-card-text>

      <v-card-actions v-if="phoneConfirmed">
        <v-spacer />

        <v-btn :disabled="!canSetPassword" :loading="isLoading" color="primary" small type="submit">
          Сохранить пароль
        </v-btn>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import PhoneVerification from '@/packages/phone-verification/components/phone-verification';
import UserService from '@/packages/user/services/user.service';
import SystemMessageService from '@/packages/system-message/services/system-message.service';
import AuthService from '@/packages/auth/services/auth.service';
import Echo from '@/plugins/echo';

export default {
  name: 'ResetPassword',

  components: { PhoneVerification },

  props: {
    phone: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      formData: {
        password: '',
        passwordConfirmation: '',
      },

      phoneConfirmed: false,

      isLoading: false,

      accessToken: null,
    };
  },

  validations() {
    return {
      formData: {
        password: {
          required,
          minLength: minLength(8),
        },
        passwordConfirmation: {
          required,
          sameAsPassword: sameAs('password'),
        },
      },
    };
  },

  computed: {
    canSetPassword() {
      return !this.$v.$invalid;
    },

    passwordErrors() {
      const errors = [];

      if (!this.$v.formData.password.$dirty) {
        return errors;
      }

      if (!this.$v.formData.password.required) {
        errors.push('Введите пароль');
      } else if (!this.$v.formData.password.minLength) {
        errors.push('Пароль должен содержать минимум 8 символов');
      }

      return errors;
    },

    passwordConfirmationErrors() {
      const errors = [];

      if (!this.$v.formData.passwordConfirmation.$dirty) {
        return errors;
      }

      if (!this.$v.formData.passwordConfirmation.required) {
        errors.push('Введите подтверждение пароля');
      } else if (!this.$v.formData.passwordConfirmation.sameAsPassword) {
        errors.push('Введённые пароли не совпадают');
      }

      return errors;
    },
  },

  methods: {
    updatePhoneConfirmationStatus({ accessToken }) {
      this.phoneConfirmed = true;
      this.accessToken = accessToken;
    },

    async setPassword() {
      this.isLoading = true;

      try {
        await UserService.updatePassword(this.formData.password, this.accessToken);
        await AuthService.login(this.phone, this.formData.password);

        Echo.init();

        SystemMessageService.addNotify('Пароль обновлён', 'success');

        this.$emit('changed');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
