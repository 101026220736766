import store from '@/store';
import OrderService from '@/packages/order/services/order.service';
import SystemMessageService from '@/packages/system-message/services/system-message.service';

async function loadOrder(orderId) {
  await store.dispatch('orderActionsStore/setViewStateIsLoading', true);

  try {
    const order = await OrderService.fetchOrder(orderId);

    await store.dispatch('orderActionsStore/setViewStateIsLoaded', true);

    return order;
  } catch (e) {
    SystemMessageService.addNotify(`Ошибка загрузки бронирования: ${e}`, 'error');

    return null;
  } finally {
    await store.dispatch('orderActionsStore/setViewStateIsLoading', false);
  }
}

async function showOrderView(orderId) {
  const order = await loadOrder(orderId);

  if (order !== null) {
    await store.dispatch('orderActionsStore/showOrderView', { order });
  }
}

async function showOrderEdit(orderId) {
  const order = await loadOrder(orderId);

  if (order !== null) {
    await store.dispatch('orderActionsStore/hideOrderView');
    await store.dispatch('orderActionsStore/showEditView', { order });
  }
}

async function showOrderCancel(orderId) {
  const order = await loadOrder(orderId);

  if (order !== null) {
    await store.dispatch('orderActionsStore/hideOrderView');
    await store.dispatch('orderActionsStore/showCancelView', { order });
  }
}

async function showOrderApprove(orderId) {
  const order = await loadOrder(orderId);

  if (order !== null) {
    await store.dispatch('orderActionsStore/hideOrderView');
    await store.dispatch('orderActionsStore/showApproveView', { order });
  }
}

async function showOrderProlong(orderId) {
  const order = await loadOrder(orderId);

  if (order !== null) {
    await store.dispatch('orderActionsStore/hideOrderView');
    await store.dispatch('orderActionsStore/showProlongView', { order });
  }
}

async function showOrderPay(orderId) {
  const order = await loadOrder(orderId);

  if (order !== null) {
    await store.dispatch('orderActionsStore/hideOrderView');
    await store.dispatch('orderActionsStore/showPayView', { order });
  }
}

export default {
  showOrderView,
  showOrderEdit,
  showOrderCancel,
  showOrderApprove,
  showOrderProlong,
  showOrderPay,
};
