<template>
  <v-data-table
    :headers="tableHeaders"
    :items="items"
    loading-text="Загружаем список платежей"
    no-data-text="Платежи не найдены"
    sort-by="createdAt"
    sort-desc
    item-key="id"
    calculate-widths
    :loading="loading"
    :footer-props="{
      itemsPerPageText: 'Платежей на страницу',
      itemsPerPageAllText: 'Все',
    }"
  >
    <template v-slot:item.publicId="{ item }">
      {{ item.publicId }}
      <br />

      <small class="text--secondary">
        {{ getHumanDate(item.createdAt) }} {{ getTime(item.createdAt) }}
      </small>
    </template>

    <template v-slot:item.operation="{ item }">
      <template v-if="item.entity">
        <a href="#" @click.prevent="$emit('order-view', item.entity.id)">Оплата бронирования</a>
      </template>

      <template v-else> Пополнение счёта </template>
    </template>

    <template v-if="isAdmin && showUser" v-slot:item.user="{ item }">
      <router-link :to="{ name: 'user-view', params: { id: item.user.id } }">
        {{ item.user.firstName }} {{ item.user.lastName }}
      </router-link>

      <br />

      <a :href="`tel:+${item.user.phone}`">{{ getHumanPhone(item.user.phone) }}</a>
    </template>

    <template v-slot:item.status="{ item }">
      <span :style="{ color: getStatusColor(item.localStatus.uid) }">
        {{ getStatusLabel(item.localStatus.uid) }}
      </span>

      <div v-if="isFailed(item.localStatus.uid)" class="text--secondary">
        <small>{{ item.errorMessage }}</small>
      </div>
    </template>

    <template v-slot:item.amount="{ item }">
      {{ item.amount.toLocaleString() }}

      <v-icon x-small> mdi-currency-rub </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { DateTime } from 'luxon';
import DateHelper from '@/common/helpers/date.helper';
import UserPermissionService from '@/packages/user/services/user-permission.service';
import * as Permissions from '@/packages/user/permissions';
import StringHelper from '@/common/helpers/string.helper';
import PaymentStatusMixin from '@/packages/payment/mixins/payment-status.mixin';

export default {
  name: 'PaymentTable',

  mixins: [PaymentStatusMixin],

  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    showUser: {
      type: Boolean,
      required: false,
      default: false,
    },

    linkFrom: {
      type: [String, null],
      required: false,
      default: null,
    },
  },

  data() {
    return {};
  },

  computed: {
    isAdmin() {
      return UserPermissionService.can(Permissions.CUSTOMERS_MANAGE_ROOMS);
    },

    tableHeaders() {
      let headers = [
        {
          text: 'Номер',
          align: 'start',
          sortable: false,
          value: 'publicId',
        },
        {
          text: 'Операция',
          align: 'start',
          sortable: false,
          value: 'operation',
        },
      ];

      if (this.isAdmin && this.showUser) {
        headers.push({
          text: 'Клиент',
          align: 'start',
          sortable: false,
          value: 'user',
        });
      }

      headers = headers.concat([
        {
          text: 'Статус',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Сумма',
          align: 'start',
          sortable: false,
          value: 'amount',
        },
      ]);

      return headers;
    },
  },

  methods: {
    getHumanDate(date) {
      return DateHelper.humanDate(DateTime.fromSQL(date), {
        month: 'long',
        day: 'numeric',
      });
    },

    getTime(date) {
      return DateTime.fromSQL(date).toLocaleString(DateTime.TIME_24_SIMPLE);
    },

    getHumanPhone(phone) {
      return StringHelper.humanPhone(phone);
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
