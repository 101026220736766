import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import vuelidate from '@/plugins/vuelidate';
import VueMask from 'v-mask';
import VueI18n from 'vue-i18n';
import App from './App';

Vue.config.productionTip = false;

Vue.use(VueMask);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'ru', // установка локализации по умолчанию
  messages: {}, // установка сообщений локализаций
});

new Vue({
  router,
  store,
  vuetify,
  vuelidate,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
