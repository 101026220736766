import axios from '@/plugins/axios';

/**
 * @return {Promise<{paymentUrl: string}>}
 */
function createPayment(amount, entityType = null, entityId = null) {
  const params = {
    amount,
  };

  if (entityType) {
    params.entityType = entityType;
  }

  if (entityId) {
    params.entityId = entityId;
  }

  return axios.post('/payment', params).then((response) => {
    return response.data;
  });
}

function fetchPaymentInfo(id) {
  return axios.get(`/payment/${id}`).then((response) => response.data.payment);
}

function fetchPayments(params) {
  return axios.get('/payment', { params }).then((response) => response.data.payments);
}

export default {
  createPayment,
  fetchPaymentInfo,
  fetchPayments,
};
