import store from '@/store';
import AccessTokenService from '@/common/services/access-token.service';

function authHeader() {
  if (AccessTokenService.hasAccessToken()) {
    return { Authorization: `Bearer ${AccessTokenService.getAccessToken()}` };
  }
  return {};
}

function appCredentials() {
  const appId = store.getters['app/id'];
  const appSecret = store.getters['app/appSecret'];

  if (appId && appSecret) {
    return {
      'Fasti-App-ID': appId,
      'Fasti-App-Secret': appSecret,
    };
  }

  return {};
}

function customerIdHeader() {
  const customerId = store.getters['app/customerId'];

  if (customerId) {
    return {
      'Fasti-Customer-ID': customerId,
    };
  }

  return {};
}

export default { authHeader, appCredentials, customerIdHeader };
