<template>
  <place-create />
</template>

<script>
import PlaceCreate from '@/packages/place/components/create/index.component';

export default {
  name: 'PlaceCreateView',

  components: { PlaceCreate },
};
</script>

<style scoped></style>
