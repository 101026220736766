import ListView from '@/packages/payment/views/list.view';

const routes = [
  {
    path: '/payment',
    name: 'payment.list',
    component: ListView,
  },
];

export default routes;
