<template>
  <v-container>
    <h2 class="ml-1 mb-3">{{ $t('place.menuLabel') }}</h2>

    <v-row>
      <v-col class="mb-3 text-center text-md-left">
        <v-btn small color="primary" dark :to="{ name: 'customer-place-create' }">
          {{ $t('place.addLabel') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="table.headers"
      :items="places"
      loading-text="Загружаем список"
      no-data-text="Записи не найдены"
      sort-by="name"
      calculate-widths
      :loading="loading"
      :hide-default-footer="places.length <= 5"
      :footer-props="{
        itemsPerPageText: 'Записей на страницу',
        itemsPerPageAllText: 'Все',
      }"
    >
      <template v-slot:item.times="{ item }">
        <div class="text--secondary">{{ item.timeFrom }} - {{ item.timeTo }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <v-btn
            text
            small
            color="primary"
            :to="{ name: 'customer-place-edit', params: { id: item.id } }"
          >
            <v-icon small class="mr-2">fas fa-pencil-alt</v-icon>

            <template v-if="!$vuetify.breakpoint.mobile">Изменить</template>
          </v-btn>

          <v-btn text small color="red lighten-3" @click="showDeletionDialog(item)">
            <v-icon small class="mr-1">fas fa-trash-alt</v-icon>

            <template v-if="!$vuetify.breakpoint.mobile">Удалить</template>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-toolbar color="warning lighten-2" class="mb-3 text--secondary" flat>
          <v-toolbar-title>Удалить "{{ deletingPlace.name }}"?</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <p>Данное действие нельзя будет отменить.</p>
          <p>Данные о бронированиях будут сохранены.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="blue darken-1" small text @click="deleteDialog = false"> Закрыть</v-btn>

          <v-spacer></v-spacer>

          <v-btn color="red" dark small @click="deletePlace(deletingPlace.id)">Удалить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PlaceService from '@/packages/place/services/place.service';
import SystemMessageService from '@/packages/system-message/services/system-message.service';

export default {
  name: 'PlaceList',

  data() {
    return {
      places: [],

      loading: false,

      table: {
        headers: [
          {
            text: 'Название',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Телефон',
            align: 'start',
            sortable: true,
            value: 'phone',
          },
          {
            text: 'Время работы',
            align: 'start',
            sortable: false,
            value: 'times',
          },
          {
            text: 'Действия',
            align: 'end',
            sortable: false,
            value: 'actions',
          },
        ],
      },

      deleteDialog: false,
      deletingPlace: {},
    };
  },

  mounted() {
    this.loading = true;
    PlaceService.fetchPlaces()
      .then((places) => {
        this.places = places;
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    showDeletionDialog(place) {
      this.deletingPlace = place;
      this.deleteDialog = true;
    },

    deletePlace(id) {
      PlaceService.deletePlace(id).then(() => {
        const index = this.places.findIndex((place) => place.id === id);

        if (index < 0) {
          return;
        }

        const place = this.places[index];
        this.places.splice(index, 1);

        SystemMessageService.addNotify(
          this.$t('place.successfulDeleted', { name: place.name }),
          'success'
        );

        this.deletingPlace = {};
        this.deleteDialog = false;
      });
    },
  },
};
</script>

<style scoped></style>
