import TimeHelper from '@/common/helpers/time.helper';

/**
 *
 * @param dateTime {DateTime}
 * @param time {String}
 *
 * @return {DateTime}
 */
function appendTime(dateTime, time) {
  if (time === '00:00') {
    return dateTime.plus({ days: 1 }).startOf('day');
  }

  const t = TimeHelper.parseTime(time);

  return dateTime
    .set({
      hours: t.hour,
      minutes: t.minute,
    })
    .startOf('minute');
}

export default { appendTime };
