export default {
  namespaced: true,

  state: {
    balance: 0,
  },

  actions: {
    clear({ commit }) {
      commit('clear');
    },

    setBalance({ commit }, balance) {
      commit('setBalance', balance);
    },
  },

  mutations: {
    setBalance(state, balance) {
      state.balance = balance;
    },

    clear(state) {
      state.balance = 0;
    },
  },
};
