import axios from '@/plugins/axios';
import md5 from 'md5';

function fetchUsers() {
  return axios.get('/user').then((response) => response.data.users);
}

/**
 * @param id
 * @return {Promise<{balance: {amount: number}, registeredAt: string, user: {}}>}
 */
function fetchUser(id) {
  return axios.get(`/user/${id}`).then((response) => response.data);
}

function fetchUserOrders(id) {
  return axios.get(`/user/${id}/orders`).then((response) => response.data.orders);
}

function searchUser(params) {
  return axios.post('/user/search', params).then((response) => {
    return response.data;
  });
}

function checkUser(phoneNumber) {
  return axios.post('/user/check', { phone: phoneNumber }).then((response) => {
    return response.data;
  });
}

async function updatePassword(password, accessToken) {
  const config = {};

  if (accessToken) {
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  const response = await axios.post('/me/set-password', { password: md5(password) }, config);

  return response.data;
}

function register({ phone, email, firstName, lastName, middleName, password }) {
  return axios
    .post('/user/register', {
      phone,
      email,
      firstName,
      lastName,
      middleName,
      password: md5(password),
    })
    .then((response) => {
      return response.data;
    });
}

export default {
  fetchUsers,
  fetchUser,
  fetchUserOrders,
  searchUser,
  checkUser,
  updatePassword,
  register,
};
