<template>
  <v-card class="price-view">
    <v-card-title class="price-view__title">Бронирование на день</v-card-title>

    <v-card-text>
      <v-row v-for="(item, periodIndex) in sortedItems" :key="`cost_period_${periodIndex}`" dense>
        <v-col cols="11">
          <v-row dense>
            <v-col cols="6" sm="3" md="2">День недели:</v-col>

            <v-col cols="6" sm="9" md="10">{{ getDayName(item.day) }}</v-col>
          </v-row>

          <v-row
            v-for="(costValue, costValueIndex) in item.values"
            :key="`cost_${costValueIndex}`"
            dense
          >
            <v-col cols="6" sm="3" md="2">Стоимость за день</v-col>

            <v-col cols="6" sm="9" md="10">
              {{ getHumanAmount(costValue) }}
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="1">
          <v-btn icon color="primary" class="mb-2" @click="$emit('edit', item.id)">
            <v-icon small class="mr-1">fas fa-pencil-alt</v-icon>
          </v-btn>

          <v-btn icon color="red lighten-3" @click="$emit('delete', item.id)">
            <v-icon small class="mr-1">fas fa-trash-alt</v-icon>
          </v-btn>
        </v-col>

        <v-col v-if="periodIndex < items.length - 1" cols="12">
          <v-divider />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import DayHelper from '@/common/helpers/day.helper';
import CostHelper from '@/common/helpers/cost.helper';

export default {
  name: 'DailyPriceView',

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  computed: {
    sortedItems() {
      const items = [...this.items];
      return items.sort((a, b) => a.day - b.day);
    },
  },

  methods: {
    getDayName(dayId) {
      return DayHelper.getDayName(dayId);
    },

    getHumanAmount(costValue) {
      return CostHelper.humanCost(costValue.amount);
    },
  },
};
</script>

<style scoped lang="scss">
.price-view {
  &__title {
    font-size: 1rem;
    padding-bottom: 0;
  }
}
</style>
