<template>
  <v-container>
    <h2 class="ml-1 mb-3">Локализация</h2>

    <loader v-if="loadingState.isLoading" />

    <template v-if="loadingState.isLoaded">
      <v-card v-for="(group, index) in settings" :key="`group_${index}`" class="mb-3">
        <v-card-title>{{ group.name }}</v-card-title>

        <v-card-subtitle v-if="group.description">{{ group.description }}</v-card-subtitle>

        <v-card-text>
          <v-text-field
            v-for="item in group.items"
            :key="item.key"
            v-model="formData[group.key][item.key]"
            :label="item.name"
            :placeholder="item.default"
            :hint="getHint(item)"
            persistent-hint
            class="mb-3"
            autocomplete="off"
            clearable
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn color="primary" small :loading="loadingState.isLoading" @click="saveSettings"
            >Сохранить</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import Loader from '@/common/components/loader';
import LocalizationService from '@/packages/localization/services/localization.service';
import SystemMessageService from '@/packages/system-message/services/system-message.service';

export default {
  name: 'LocalizationEditor',

  components: { Loader },

  data() {
    return {
      loadingState: {
        isLoading: false,
        isLoaded: false,
      },

      savingState: {
        isLoading: false,
        isLoaded: false,
      },

      formData: {},

      settings: [],
    };
  },

  mounted() {
    this.loadSettings();
  },

  methods: {
    loadSettings() {
      this.loadingState.isLoading = true;
      this.loadingState.isLoaded = false;

      LocalizationService.loadSettings()
        .then((settings) => {
          const formData = {};

          settings.forEach((group) => {
            if (!formData[group.key]) {
              formData[group.key] = {};
            }

            group.items.forEach((item) => {
              formData[group.key][item.key] = item.value;
            });
          });

          this.formData = formData;

          this.settings = settings;

          this.loadingState.isLoaded = true;
        })
        .finally(() => {
          this.loadingState.isLoading = false;
        });
    },

    saveSettings() {
      this.loadingState.isLoading = true;

      const settings = [];

      Object.keys(this.formData).forEach((groupKey) => {
        Object.keys(this.formData[groupKey]).forEach((itemKey) => {
          if (this.formData[groupKey][itemKey]) {
            settings.push({
              groupKey,
              itemKey,
              value: this.formData[groupKey][itemKey],
            });
          }
        });
      });

      LocalizationService.saveSettings(settings)
        .then(() => {
          LocalizationService.load().then((messages) => {
            LocalizationService.init(this.$i18n, messages);
          });

          SystemMessageService.addNotify('Настройки сохранены', 'success');
        })
        .finally(() => {
          this.loadingState.isLoading = false;
        });
    },

    getHint(item) {
      let hint = `Значение по умолчанию: "${item.default}"`;

      if (item.description) {
        hint += `. ${item.description}`;
      }

      return hint;
    },
  },
};
</script>

<style scoped></style>
