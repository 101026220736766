import axios from '@/plugins/axios';
import store from '@/store';

function fetchApp() {
  return axios.get(process.env.VUE_APP_INFO_URL).then((response) => {
    return response.data.app;
  });
}

function storeApp(app) {
  store.dispatch('app/setApp', {
    id: app.id,
    customerId: app.customerId,
    isEnabled: app.isEnabled,
    appUrl: app.appUrl,
    apiUrl: app.apiUrl,
    name: app.name,
  });
}

function fetchUserCustomers() {
  return axios.get('/me/customers').then((response) => response.data.customers);
}

function storeCustomerId(customerId) {
  store.dispatch('app/setCustomerId', customerId);
}

export default { fetchApp, storeApp, fetchUserCustomers, storeCustomerId };
