<template>
  <v-container>
    <h2 class="ml-1 mb-3">Пополнение баланса</h2>

    <user-account-refill />
  </v-container>
</template>

<script>
import UserAccountRefill from '@/packages/user-account/components/refill';

export default {
  name: 'RefillView',

  components: { UserAccountRefill },
};
</script>

<style scoped></style>
