<template>
  <v-card class="price-view">
    <v-card-title class="price-view__title">Бронирование на несколько дней</v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col cols="11">
          <v-row v-for="(costValue, index) in costValues" :key="`cost_${index}`" dense>
            <v-col cols="6" sm="3" md="2">{{ getCostLabel(index, costValue) }}</v-col>

            <v-col cols="6" sm="9" md="10">
              {{ getHumanAmount(costValue) }}

              <small v-if="isAmountPercentType(costValue.amountType)" class="text--secondary">
                ({{ getAmountHint(costValue.cost) }}/день)
              </small>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="1">
          <v-btn icon color="primary" class="mb-2" @click="$emit('edit')">
            <v-icon small class="mr-1">fas fa-pencil-alt</v-icon>
          </v-btn>

          <v-btn icon color="red lighten-3" @click="$emit('delete')">
            <v-icon small class="mr-1">fas fa-trash-alt</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CostHelper from '@/common/helpers/cost.helper';
import CostConstants from '@/packages/order/inventory/cost.constants';
import StringHelper from '@/common/helpers/string.helper';

export default {
  name: 'ManyDaysPriceView',

  props: {
    costValues: {
      type: Array,
      required: true,
    },
  },

  methods: {
    getCostLabel(index, costValue) {
      if (index === 0) {
        return 'Стоимость в день';
      }

      const label = StringHelper.declOfNum(costValue.starts, ['дня', 'дней', 'дней']);

      return `от ${costValue.starts} ${label}`;
    },

    getHumanAmount(costValue) {
      if (costValue.amountType === CostConstants.AMOUNT_TYPE_SUM) {
        return CostHelper.humanCost(costValue.amount);
      }

      const label = StringHelper.declOfNum(costValue.amount, ['процент', 'процента', 'процентов']);

      return `${costValue.amount} ${label}`;
    },

    isAmountPercentType(amountType) {
      return amountType === CostConstants.AMOUNT_TYPE_PERCENT;
    },

    getAmountHint(cost) {
      return CostHelper.humanCost(cost);
    },
  },
};
</script>

<style scoped lang="scss">
.price-view {
  &__title {
    font-size: 1rem;
    padding-bottom: 0;
  }
}
</style>
