<script>
import PermissionService from '@/packages/permission/services/permission.service';
import WidgetPermissionEnum from '@/packages/permission/enums/widget-permission.enum';

export default {
  name: 'WidgetPermissionMixin',

  computed: {
    isUserCanWidgetShowPrevPeriods() {
      return PermissionService.can(WidgetPermissionEnum.WIDGET_SHOW_PREV_PERIODS);
    },
  },
};
</script>

<style scoped></style>
