<template>
  <LocalizationEditor />
</template>

<script>
import LocalizationEditor from '@/packages/localization/components/editor';

export default {
  name: 'ManageLocalizationView',

  components: { LocalizationEditor },
};
</script>

<style scoped></style>
