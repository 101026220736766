function declOfNum(n, titles) {
  return titles[
    // eslint-disable-next-line no-nested-ternary
    n % 10 === 1 && n % 100 !== 11
      ? 0
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 1
      : 2
  ];
}

function humanPhone(phone) {
  const countryCode = phone[0];
  const code = phone.slice(1, 4);
  const first = phone.slice(4, 7);
  const second = phone.slice(7, 9);
  const third = phone.slice(9, 11);
  return `+${countryCode} (${code}) ${first}-${second}-${third}`;
}

function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function clearPhoneNumber(phoneNumber) {
  return phoneNumber.replace(/[^\d]/g, '');
}

export default { declOfNum, humanPhone, escapeRegExp, clearPhoneNumber };
