export default {
  namespaced: true,

  state: {
    viewState: {
      isActive: false,
      isLoading: false,
      isLoaded: false,
      order: null,
    },

    editState: {
      isActive: false,
      order: null,
    },

    approveState: {
      isActive: false,
      order: null,
    },

    cancelState: {
      isActive: false,
      order: null,
    },

    prolongState: {
      isActive: false,
      order: null,
    },

    payState: {
      isActive: false,
      order: null,
    },
  },

  actions: {
    /*
    View
     */
    setViewStateIsLoading({ commit }, isLoading) {
      commit('setViewStateIsLoading', isLoading);
    },

    setViewStateIsLoaded({ commit }, isLoaded) {
      commit('setViewStateIsLoaded', isLoaded);
    },

    showOrderView({ commit }, { order }) {
      commit('showOrderView', { order });
    },

    hideOrderView({ commit }) {
      commit('hideOrderView');
    },

    /*
    Edit
     */
    showEditView({ commit }, { order }) {
      commit('showEditView', { order });
    },

    hideEditView({ commit }) {
      commit('hideEditView');
    },

    /*
    Cancel
     */
    showCancelView({ commit }, { order }) {
      commit('showCancelView', { order });
    },

    hideCancelView({ commit }) {
      commit('hideCancelView');
    },

    /*
    Approve
     */
    showApproveView({ commit }, { order }) {
      commit('showApproveView', { order });
    },

    hideApproveView({ commit }) {
      commit('hideApproveView');
    },

    /*
    Prolong
     */
    showProlongView({ commit }, { order }) {
      commit('showProlongView', { order });
    },

    hideProlongView({ commit }) {
      commit('hideProlongView');
    },

    /*
    Pay
     */
    showPayView({ commit }, { order }) {
      commit('showPayView', { order });
    },

    hidePayView({ commit }) {
      commit('hidePayView');
    },
  },

  mutations: {
    /*
    View
     */
    setViewStateIsLoading(state, isLoading) {
      state.viewState.isLoading = isLoading;
    },

    setViewStateIsLoaded(state, isLoaded) {
      state.viewState.isLoaded = isLoaded;
    },

    showOrderView(state, { order }) {
      state.viewState.order = order;
      state.viewState.isActive = true;
    },

    hideOrderView(state) {
      state.viewState = {
        isActive: false,
        isLoading: false,
        isLoaded: false,
        order: null,
      };
    },

    /*
    Edit
     */
    showEditView(state, { order }) {
      state.editState.order = order;
      state.editState.isActive = true;
    },

    hideEditView(state) {
      state.editState = {
        isActive: false,
        order: null,
      };
    },

    /*
    Cancel
     */
    showCancelView(state, { order }) {
      state.cancelState.order = order;
      state.cancelState.isActive = true;
    },

    hideCancelView(state) {
      state.cancelState = {
        isActive: false,
        order: null,
      };
    },

    /*
    Approve
     */
    showApproveView(state, { order }) {
      state.approveState.order = order;
      state.approveState.isActive = true;
    },

    hideApproveView(state) {
      state.approveState = {
        isActive: false,
        order: null,
      };
    },

    /*
    Prolong
     */
    showProlongView(state, { order }) {
      state.prolongState.order = order;
      state.prolongState.isActive = true;
    },

    hideProlongView(state) {
      state.prolongState = {
        isActive: false,
        order: null,
      };
    },

    /*
    Pay
     */
    showPayView(state, { order }) {
      state.payState.order = order;
      state.payState.isActive = true;
    },

    hidePayView(state) {
      state.payState = {
        isActive: false,
        order: null,
      };
    },
  },

  getters: {
    viewState: (state) => state.viewState,
    editState: (state) => state.editState,
    cancelState: (state) => state.cancelState,
    approveState: (state) => state.approveState,
    prolongState: (state) => state.prolongState,
    payState: (state) => state.payState,
  },
};
