<template>
  <v-card>
    <v-card-title>Выбор клиента</v-card-title>

    <v-card-text class="pb-0">
      <v-row dense>
        <v-col cols="12">
          <v-switch v-model="formData.forOtherUser" inset dense label="Забронировать для клиента" />
        </v-col>
      </v-row>
    </v-card-text>

    <v-expand-transition>
      <div v-if="formData.forOtherUser">
        <v-card-subtitle class="pt-0">Поиск клиента</v-card-subtitle>

        <v-card-text class="mb-0 pb-0">
          <v-text-field
            v-model="$v.formData.phoneNumber.$model"
            v-mask="'+7 (###) ###-##-##'"
            placeholder="+7 (999) 999-99-99"
            label="Номер телефона клиента"
            name="phone"
            type="text"
            solo
            dense
            append-icon="mdi-account-search"
            :error-messages="phoneNumberErrors"
            :loading="isSearchLoading"
            :disabled="isSearchLoading"
            @blur="$v.formData.phoneNumber.$touch()"
            @input="$v.formData.phoneNumber.$touch()"
            @click:append="searchUser"
          />
        </v-card-text>
      </div>
    </v-expand-transition>

    <template v-if="formData.forOtherUser">
      <v-expand-transition>
        <div v-if="isSearchRequestSent">
          <template v-if="isUserFound">
            <v-card-subtitle class="pt-0">Клиент найден</v-card-subtitle>

            <v-card-text>
              <v-list dense>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    {{ formData.firstName }} {{ formData.lastName }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-email</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    {{ formData.email }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </template>

          <template v-else>
            <v-card-subtitle>Новый клиент</v-card-subtitle>

            <v-card-text class="mb-0 pb-0">
              <v-text-field :value="formData.phoneNumber" label="Номер телефона" disabled />

              <v-text-field
                v-model="$v.formData.firstName.$model"
                label="Имя клиента"
                type="text"
                :error-messages="firstNameErrors"
                @blur="$v.formData.firstName.$touch()"
                @input="$v.formData.firstName.$touch()"
              />

              <v-text-field
                v-model="$v.formData.lastName.$model"
                label="Фамилия клиента"
                type="text"
                :error-messages="lastNameErrors"
                @blur="$v.formData.lastName.$touch()"
                @input="$v.formData.lastName.$touch()"
              />

              <v-text-field
                v-model="$v.formData.email.$model"
                label="E-mail клиента"
                type="text"
                :error-messages="emailErrors"
                :loading="isValidateEmailLoading"
                @blur="$v.formData.email.$touch()"
                @input="$v.formData.email.$touch()"
              />
            </v-card-text>
          </template>
        </div>
      </v-expand-transition>
    </template>

    <v-card-actions>
      <v-btn color="blue darken-1" small text @click="back"> Назад </v-btn>

      <v-spacer />

      <v-btn :disabled="nextButtonDisabled" color="primary" small @click="confirm">
        Продолжить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import UserService from '@/packages/user/services/user.service';
import StringHelper from '@/common/helpers/string.helper';

export default {
  name: 'UserSelect',

  props: {
    autoSearch: {
      type: Boolean,
      required: false,
      default: false,
    },

    forOtherUser: {
      type: Boolean,
      required: false,
      default: false,
    },

    userId: {
      type: [String, null],
      required: false,
      default: null,
    },

    phoneNumber: {
      type: String,
      required: false,
      default: '',
    },

    email: {
      type: String,
      required: false,
      default: '',
    },

    firstName: {
      type: String,
      required: false,
      default: '',
    },

    lastName: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      isReady: false,

      formData: {
        forOtherUser: false,
        id: null,
        phoneNumber: '',
        email: '',
        firstName: '',
        lastName: '',
      },

      isValid: false,

      isSearchLoading: false,
      isSearchRequestSent: false,

      isUserFound: false,

      isAutoSearchEnabled: true,

      isValidateEmailLoading: false,
      isUsingEmail: false,
    };
  },

  validations() {
    if (this.isSearchRequestSent && !this.isUserFound) {
      return {
        formData: {
          phoneNumber: {
            required,
          },
          firstName: {
            required,
          },
          lastName: {
            required,
          },
          email: {
            required,
            email,
          },
        },
      };
    }

    return {
      formData: {
        phoneNumber: { required },
      },
    };
  },

  computed: {
    nextButtonDisabled() {
      if (!this.formData.forOtherUser) {
        return false;
      }

      if (!this.isSearchRequestSent || this.isValidateEmailLoading || this.isUsingEmail) {
        return true;
      }

      if (this.isSearchRequestSent && this.isUserFound) {
        return false;
      }

      return this.$v.$invalid;
    },

    cleanPhoneNumber() {
      return StringHelper.clearPhoneNumber(this.formData.phoneNumber);
    },

    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.formData.phoneNumber.$dirty) {
        return errors;
      }

      if (this.$v.formData.phoneNumber.$invalid) {
        errors.push('Введите номер телефона');
      }

      if (this.cleanPhoneNumber.length !== 11) {
        errors.push('Некорректный номер');
      }

      return errors;
    },

    firstNameErrors() {
      const errors = [];
      if (!this.$v.formData.firstName.$dirty) {
        return errors;
      }

      if (this.$v.formData.firstName.$invalid) {
        errors.push('Укажите имя клиента');
      }

      return errors;
    },

    lastNameErrors() {
      const errors = [];
      if (!this.$v.formData.lastName.$dirty) {
        return errors;
      }

      if (this.$v.formData.lastName.$invalid) {
        errors.push('Укажите фамилию клиента');
      }

      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.formData.email.$dirty) {
        return errors;
      }

      if (!this.$v.formData.email.required) {
        errors.push('Укажите email клиента');
      }

      if (!this.$v.formData.email.email) {
        errors.push('Некорректный email клиента');
      }

      if (this.isUsingEmail) {
        errors.push('Данный email уже используется');
      }

      return errors;
    },
  },

  watch: {
    'formData.phoneNumber': function () {
      if (this.phoneNumberErrors.length === 0) {
        this.searchUser();
      }
    },

    'formData.email': function () {
      if (this.formData.forOtherUser && !this.isUserFound && !this.$v.formData.email.$invalid) {
        this.validateEmail();
      }
    },
  },

  mounted() {
    this.initDefaults();
  },

  methods: {
    initDefaults() {
      this.formData.forOtherUser = this.forOtherUser;
      this.formData.id = this.userId;
      this.formData.phoneNumber = this.phoneNumber;
      this.formData.email = this.email;
      this.formData.firstName = this.firstName;
      this.formData.lastName = this.lastName;

      this.isUserFound = !!this.userId;
      this.isSearchRequestSent = this.isUserFound || !!this.formData.phoneNumber;
      this.isAutoSearchEnabled = this.autoSearch;
    },

    searchUser() {
      if (!this.isAutoSearchEnabled) {
        this.isAutoSearchEnabled = true;
        return;
      }

      this.isSearchLoading = true;
      this.isSearchRequestSent = false;

      this.formData.id = null;
      this.formData.firstName = '';
      this.formData.lastName = '';
      this.formData.email = '';

      UserService.searchUser({ phone: this.cleanPhoneNumber })
        .then((response) => {
          this.isUserFound = response.userFound;

          if (this.isUserFound) {
            this.formData.id = response.user.id;
            this.formData.firstName = response.user.firstName;
            this.formData.lastName = response.user.lastName;
            this.formData.email = response.user.email;
          }

          this.isValid = this.formData.id !== null;

          this.isSearchRequestSent = true;
        })
        .finally(() => {
          this.isSearchLoading = false;
        });
    },

    validateEmail() {
      this.isValidateEmailLoading = true;

      UserService.searchUser({ email: this.formData.email })
        .then((response) => {
          this.isUsingEmail = response.userFound;
        })
        .finally(() => {
          this.isValidateEmailLoading = false;
        });
    },

    back() {
      this.$emit('back', this.formData);
    },

    confirm() {
      this.$emit('confirm', this.formData);
    },
  },
};
</script>

<style scoped></style>
