export default {
  namespaced: true,

  state: {
    // TODO: Перенести в auth
    isLoggedIn: false,

    id: null,
    phone: null,
    email: null,
    firstName: '',
    lastName: '',
    middleName: '',
    emailVerifiedAt: null,
    balance: 0,
  },

  actions: {
    clear({ commit }) {
      commit('clear');
    },

    setLoggedIn({ commit }, status) {
      commit('setLoggedIn', status);
    },

    setBalance({ commit }, balance) {
      commit('setBalance', balance);
    },

    setUserInfo({ commit }, userInfo) {
      commit('setUserInfo', userInfo);
    },
  },

  mutations: {
    setLoggedIn(state, status) {
      state.isLoggedIn = status;
    },

    setBalance(state, balance) {
      state.balance = balance;
    },

    setUserInfo(state, userInfo) {
      state.id = userInfo.id;
      state.phone = userInfo.phone;
      state.email = userInfo.email;
      state.firstName = userInfo.firstName;
      state.lastName = userInfo.lastName;
      state.middleName = userInfo.middleName;
      state.emailVerifiedAt = userInfo.emailVerifiedAt;
      state.balance = userInfo.balance;
    },

    clear(state) {
      state.isLoggedIn = false;

      state.id = null;
      state.phone = null;
      state.email = null;
      state.firstName = '';
      state.lastName = '';
      state.middleName = '';
      state.emailVerifiedAt = null;
      state.balance = 0;
    },
  },

  getters: {
    name: (state) => `${state.firstName || ''} ${state.lastName || ''}`,
    firstName: (state) => state.firstName || '',
    lastName: (state) => state.lastName || '',

    balance: (state) => state.balance,
  },
};
