<template>
  <room-edit />
</template>

<script>
import RoomEdit from '@/packages/room/components/edit/index.component';

export default {
  name: 'RoomEditView',

  components: { RoomEdit },
};
</script>
