import ProfileFormView from '@/packages/user/views/user-profile-form.view';
import UsersListView from '@/packages/user/views/users-list';
import UserInfoView from '@/packages/user/views/user-info';
import UserPayments from '@/packages/user/views/user-payments';

const routes = [
  {
    path: '/me/profile',
    name: 'user-profile-edit',
    component: ProfileFormView,
  },
  {
    path: '/me/payments',
    name: 'user.payments',
    component: UserPayments,
  },
  {
    path: '/users',
    name: 'user-list',
    component: UsersListView,
  },
  {
    path: '/users/:id',
    name: 'user-view',
    component: UserInfoView,
  },
];

export default routes;
