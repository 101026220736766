<template>
  <v-card>
    <v-card-title>Пожалуйста, представьтесь</v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-mask="'+# (###) ###-##-##'"
            :value="phone"
            readonly
            disabled
            label="Номер телефона"
            name="phone"
            placeholder="+7 (999) 999-99-99"
            type="text"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="$v.formData.password.$model"
            type="password"
            label="Пароль"
            :error-messages="passwordErrors"
            :readonly="isLoading"
            :loading="isLoading"
            @focus="$v.formData.password.$reset()"
            @blur="$v.formData.password.$touch()"
            @input="inputPassword"
          />
        </v-col>
      </v-row>

      <p class="text-right">
        <a href="#" @click.prevent="$emit('reset-password')">Не помню пароль</a>
      </p>
    </v-card-text>

    <v-card-actions>
      <v-btn color="blue darken-1" small text @click="$emit('back')"> Назад </v-btn>

      <v-spacer />

      <v-btn
        color="primary"
        small
        :disabled="isLoginButtonDisabled"
        :loading="isLoading"
        @click="login"
      >
        Войти и продолжить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators';
import AuthService from '@/packages/auth/services/auth.service';
import Echo from '@/plugins/echo';

export default {
  name: 'Login',

  props: {
    phone: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      formData: {
        password: '',
      },

      apiErrors: {
        password: [],
      },

      isLoading: false,
    };
  },

  validations() {
    return {
      formData: {
        password: {
          required,
          minLength: minLength(8),
        },
      },
    };
  },

  computed: {
    isLoginButtonDisabled() {
      return this.$v.$invalid || this.isLoading;
    },

    passwordErrors() {
      const errors = [];

      if (!this.$v.formData.password.$dirty) {
        return errors;
      }

      if (this.$v.formData.password.$invalid) {
        errors.push('Введите пароль');
      }

      this.apiErrors.password.forEach((error) => {
        errors.push(error);
      });

      return errors;
    },
  },

  methods: {
    login() {
      this.isLoading = true;
      this.clearApiErrors();

      AuthService.login(this.phone, this.formData.password)
        .then(() => {
          Echo.init();

          this.$emit('logged-in');
        })
        .catch((error) => {
          this.setApiErrors(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    setApiErrors(error) {
      if (error.response && error.response.status && error.response.status === 422) {
        this.apiErrors.password = error.response.data.errors.password || [];
      }
    },

    clearApiErrors() {
      this.apiErrors.password = [];
    },

    inputPassword() {
      this.$v.formData.password.$touch();
      this.clearApiErrors();
    },
  },
};
</script>

<style scoped></style>
