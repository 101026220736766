<template>
  <div class="fasti__order-payment">
    <template v-if="!payment.isPaid">
      <v-alert v-if="humanAvailableTo" type="warning">
        Вам необходимо оплатить бронирование до {{ humanAvailableTo }}.
      </v-alert>

      <template v-if="balance.isLoaded">
        <template v-if="isBalanceShown">
          <v-list>
            <v-list-item dense two-line class="py-0 my-0">
              <v-list-item-content>
                Текущий баланс
                <v-list-item-subtitle>
                  <small>Один бонус равняется одному рублю РФ</small>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>{{ getHumanBonuses(userBalance) }}</v-list-item-action>
            </v-list-item>

            <v-list-item dense two-line class="py-0 my-0">
              <v-list-item-content>Сумма к оплате</v-list-item-content>

              <v-list-item-action>{{ getHumanCost(cost) }}</v-list-item-action>
            </v-list-item>

            <v-list-item v-if="canPayByAccount" dense class="py-0 my-0">
              <v-list-item-content>
                <v-switch
                  v-model="payment.payByUserAccount"
                  inset
                  dense
                  label="Оплатить с личного счёта"
                  class="ml-2 mt-0"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>

        <v-alert v-if="showBalanceAlert" type="warning">
          Недостаточно средств для оплаты. Оплатите бронирование картой или
          <a :href="`${appUrl}account/refill`" target="_blank">пополните личный счёт</a>. Не
          забудьте оплатить бронирование после пополнения счёта.
        </v-alert>

        <v-alert v-if="payment.paymentUrl" type="success" class="mb-5">
          Сейчас Вы будете перенаправлены на страницу банка для оплаты. Если этого не произошло, то
          <a :href="payment.paymentUrl" class="text--white">перейдите к оплате</a>
          самостоятельно.
        </v-alert>

        <v-alert v-if="isPaymentFailed" dark color="red lighten-2" class="mb-5">
          Произошла ошибка во время создания платежа. Попробуйте ещё раз.
        </v-alert>

        <v-row>
          <v-col cols="3">
            <v-btn color="blue darken-1" small text @click="$emit('cancel')"> Закрыть </v-btn>
          </v-col>

          <v-col cols="9">
            <div v-if="isPayButtonShown" class="text-right">
              <v-btn color="primary" small :loading="payment.isLoading" @click="payOrder">
                <template v-if="payment.payByUserAccount"> Оплатить бонусами </template>

                <template v-else> Оплатить картой </template>
              </v-btn>

              <v-btn
                v-if="showBalanceAlert"
                :href="`${appUrl}account/refill`"
                target="_blank"
                color="primary"
                class="ml-0 ml-sm-3 mt-3 mt-sm-0"
                small
              >
                Пополнить счёт
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </template>
    </template>

    <v-alert v-if="payment.isPaid" type="success"> Ваше бронирование оплачено. </v-alert>

    <div v-if="balance.isLoading" class="text-center mt-2">
      <v-progress-circular color="primary" indeterminate />

      <div class="mt-2">Получаем данные для оплаты бронирования</div>
    </div>
  </div>
</template>

<script>
import CostHelper from '@/common/helpers/cost.helper';
import UserAccountHelper from '@/packages/user-account/helpers/user-account.helper';
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import DateHelper from '@/common/helpers/date.helper';
import UserAccountService from '@/packages/user-account/services/user-account.service';
import OrderService from '@/packages/order/services/order.service';
import PaymentEntityTypeEnum from '@/packages/user-account/enums/payment-entity-type.enum';

export default {
  name: 'OrderPayment',

  props: {
    cost: {
      type: Number,
      required: true,
    },

    availableTo: {
      type: String,
      required: false,
      default: null,
    },

    orderId: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      balance: {
        isLoaded: false,
        isLoading: true,
      },

      payment: {
        isLoading: false,
        isPaid: false,
        payByUserAccount: true,
        paymentUrl: null,
        errorCode: null,
      },
    };
  },

  computed: {
    ...mapGetters(['user', 'userBalance', 'appUrl']),

    humanAvailableTo() {
      const dateTime = DateTime.fromSQL(this.availableTo, { zone: 'UTC' }).setZone('local');

      if (!this.availableTo || !dateTime.isLuxonDateTime) {
        return '';
      }

      const date = DateHelper.humanDate(dateTime, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      const time = DateHelper.humanDate(dateTime, DateTime.TIME_24_WITH_SECONDS);

      return `${date} ${time}`;
    },

    isPayButtonShown() {
      return !this.payment.paymentUrl && !this.payment.isPaid;
    },

    isBalanceShown() {
      return this.userBalance > 0 || this.isNotEnoughFunds;
    },

    isNotEnoughFunds() {
      return this.payment.errorCode === 'NOT_ENOUGH_FUNDS';
    },

    canPayByAccount() {
      return this.userBalance >= this.cost;
    },

    isPaymentFailed() {
      return this.payment.errorCode === 'PAYMENT_FAILED';
    },

    showBalanceAlert() {
      return (this.userBalance > 0 && !this.canPayByAccount) || this.isNotEnoughFunds;
    },
  },

  mounted() {
    this.loadBalance();
  },

  methods: {
    loadBalance() {
      this.balance.isLoading = true;

      UserAccountService.fetchBalance()
        .then((response) => {
          UserAccountService.storeBalance(response.amount);

          if (response.amount < this.cost) {
            this.payment.payByUserAccount = false;
          }

          this.balance.isLoaded = true;
        })
        .finally(() => {
          this.balance.isLoading = false;
        });
    },

    getHumanCost(cost) {
      return CostHelper.humanCost(cost);
    },

    getHumanBonuses(bonuses) {
      return UserAccountHelper.humanBalance(bonuses);
    },

    payOrder() {
      this.payment.isLoading = true;
      this.payment.paymentUrl = null;
      this.payment.errorCode = null;

      if (this.payment.payByUserAccount && this.userBalance >= this.cost) {
        OrderService.payOrder(this.orderId)
          .then(() => {
            this.payment.isPaid = true;
            this.$emit('paid');
          })
          .catch((error) => {
            const response = error.response || {};

            if (response.status === 400) {
              const data = response.data;
              this.payment.errorCode = data.error.code;

              if (this.payment.errorCode === 'WRONG_PAYMENT_STATUS') {
                this.payment.isPaid = true;
              }

              if (this.payment.errorCode === 'NOT_ENOUGH_FUNDS') {
                UserAccountService.storeBalance(data.balance.amount);
                this.payment.payByUserAccount = false;
              }
            }
          })
          .finally(() => {
            this.payment.isLoading = false;
          });
      } else {
        // Создать платёж на стоимость бронирования

        UserAccountService.createPayment(this.cost, PaymentEntityTypeEnum.ORDER, this.orderId)
          .then((response) => {
            this.payment.paymentUrl = response.paymentUrl;

            setTimeout(() => {
              document.location.href = response.paymentUrl;
            }, 2000);
          })
          .catch((error) => {
            const response = error.response || {};

            if (response.status === 400) {
              const data = response.data;
              this.payment.errorCode = data.error.code;
            }
          })
          .finally(() => {
            this.payment.isLoading = false;
          });
      }
    },
  },
};
</script>

<style>
.fasti__order-payment .v-list-item {
  min-height: 10px !important;
}
</style>
