<template>
  <div>
    <v-row dense>
      <v-col cols="5" sm="4"> Статус </v-col>

      <v-col cols="7" sm="8">
        <template v-if="isOrderNew">
          <v-chip color="orange" label dark small> В обработке </v-chip>
        </template>

        <template v-if="isOrderApproved">
          <v-chip color="green darken-1" label dark small> Подтверждено </v-chip>
        </template>

        <template v-if="isOrderCanceled">
          <v-chip color="red darken-1" label dark small> Отменено </v-chip>
        </template>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="5" sm="4"> Оплата </v-col>

      <v-col cols="7" sm="8">
        <template v-if="isPaymentNew">
          <v-chip color="orange" label dark small> Не оплачено </v-chip>
        </template>

        <template v-if="isPaymentPaid">
          <v-chip color="green darken-1" label dark small> Оплачено </v-chip>
        </template>

        <template v-if="isPaymentPartiallyPaid">
          <v-chip color="orange" label dark small> Частично оплачено </v-chip>
        </template>

        <template v-if="isPaymentProcessing">
          <v-chip color="orange" label dark small> Оплачивается </v-chip>
        </template>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="5" sm="4">
        {{ $t('place.label') }}
      </v-col>
      <v-col cols="7" sm="8">
        {{ order.place.name }}
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="5" sm="4">
        {{ $t('room.label') }}
      </v-col>
      <v-col cols="7" sm="8">
        {{ order.room.name }}
      </v-col>
    </v-row>

    <template v-if="isHourlyType">
      <v-row dense>
        <v-col cols="5" sm="4"> Дата </v-col>
        <v-col cols="7" sm="8">
          {{ getHumanDate(order.period.from) }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="5" sm="4"> Время </v-col>
        <v-col cols="7" sm="8">
          {{ getTime(order.period.from) }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="5" sm="4"> Длительность </v-col>
        <v-col cols="7" sm="8">
          {{ durationInMinutes }}
        </v-col>
      </v-row>

      <v-row v-if="order.serviceTime.amount > 0" dense>
        <v-col cols="5" sm="4"> Дополнительное время </v-col>
        <v-col cols="7" sm="8">
          {{ serviceTimeLabel }}
        </v-col>
      </v-row>
    </template>

    <template v-if="isDailyType">
      <v-row dense>
        <v-col cols="5" sm="4"> Дата </v-col>
        <v-col cols="7" sm="8">
          {{ getHumanDate(order.period.from) }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="5" sm="4"> Длительность </v-col>
        <v-col cols="7" sm="8"> Целый день </v-col>
      </v-row>
    </template>

    <template v-if="isManyDaysType">
      <v-row dense>
        <v-col cols="5" sm="4"> Начало </v-col>
        <v-col cols="7" sm="8">
          {{ getHumanDate(order.period.from) }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="5" sm="4"> Окончание </v-col>
        <v-col cols="7" sm="8">
          {{ getHumanDate(manyDaysDateTo) }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="5" sm="4"> Длительность </v-col>
        <v-col cols="7" sm="8">
          {{ durationInDays }}
        </v-col>
      </v-row>
    </template>

    <v-row dense>
      <v-col cols="5" sm="4"> Посетители </v-col>
      <v-col cols="7" sm="8">
        {{ order.visitors }}
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="5" sm="4"> Стоимость </v-col>
      <v-col cols="7" sm="8">
        {{ humanCost }}
      </v-col>
    </v-row>

    <v-row v-if="isPaymentPartiallyPaid" dense>
      <v-col cols="5" sm="4"> Оплачено </v-col>
      <v-col cols="7" sm="8">
        {{ humanPaidCost }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DateHelper from '@/common/helpers/date.helper';
import TimeHelper from '@/common/helpers/time.helper';
import CostHelper from '@/packages/order/helpers/cost.helper';
import OrderStatusEnum from '@/packages/order/enums/order-status.enum';
import OrderPaymentStatusEnum from '@/packages/order/enums/order-payment-status.enum';
import CostTypeEnum from '@/packages/order/enums/cost-type.enum';
import StringHelper from '@/common/helpers/string.helper';

export default {
  name: 'OrderInfo',

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  computed: {
    durationInMinutes() {
      const minutes = this.order.period.to
        .minus({ minute: this.order.serviceTime.amount })
        .diff(this.order.period.from, 'minutes').minutes;

      return TimeHelper.humanTimeFromMinutes(minutes);
    },

    durationInDays() {
      const days = Math.ceil(this.this.order.period.to.diff(this.order.period.from, 'days').days);

      const label = StringHelper.declOfNum(days, ['день', 'дня', 'дней']);

      return `${days} ${label}`;
    },

    humanCost() {
      return CostHelper.humanCost(this.order.cost.amount);
    },

    humanPaidCost() {
      return CostHelper.humanCost(this.order.cost.paid);
    },

    isOrderNew() {
      return this.order.status.uid === OrderStatusEnum.NEW;
    },

    isOrderApproved() {
      return this.order.status.uid === OrderStatusEnum.APPROVED;
    },

    isOrderCanceled() {
      return this.order.status.uid === OrderStatusEnum.CANCELED;
    },

    isPaymentNew() {
      return this.order.payment.status.uid === OrderPaymentStatusEnum.NEW;
    },

    isPaymentProcessing() {
      return this.order.payment.status.uid === OrderPaymentStatusEnum.PROCESSING;
    },

    isPaymentPartiallyPaid() {
      return this.order.payment.status.uid === OrderPaymentStatusEnum.PARTIALLY_PAID;
    },

    isPaymentPaid() {
      return this.order.payment.status.uid === OrderPaymentStatusEnum.PAID;
    },

    isHourlyType() {
      return this.order.cost.type === CostTypeEnum.TYPE_HOURLY;
    },

    isDailyType() {
      return this.order.cost.type === CostTypeEnum.TYPE_DAILY;
    },

    isManyDaysType() {
      return this.order.cost.type === CostTypeEnum.TYPE_MANY_DAYS;
    },

    manyDaysDateTo() {
      if (this.order.period.to.hour === 0) {
        return this.order.period.to.minus({ days: 1 }).endOf('day');
      }

      return this.order.period.to;
    },

    serviceTimeLabel() {
      if (this.order.serviceTime.amount === 60) {
        return '1 час';
      }

      return `${this.order.serviceTime.amount} минут`;
    },
  },

  methods: {
    getHumanDate(dateTime) {
      return DateHelper.humanDate(dateTime);
    },

    getTime(dateTime) {
      return dateTime.toFormat('HH:mm');
    },
  },
};
</script>

<style>
.fasti__user-stat.v-list--dense .v-list-item.v-list-item--two-line,
.fasti__user-stat .v-list-item--dense.v-list-item--two-line {
  min-height: 30px;
}
</style>
