<template>
  <v-card class="price-view">
    <v-card-title class="price-view__title">Почасовое бронирование</v-card-title>

    <v-card-text>
      <v-row v-for="(group, groupIndex) in sortedItems" :key="`cost_period_${groupIndex}`" dense>
        <v-col cols="11">
          <v-row dense>
            <v-col cols="6" sm="3" md="2">День недели:</v-col>

            <v-col cols="6" sm="9" md="10">{{ getDayName(group.day) }}</v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6" sm="3" md="2">Период:</v-col>

            <v-col cols="6" sm="9" md="10">{{ group.timeFrom }}-{{ group.timeTo }}</v-col>
          </v-row>

          <v-row
            v-for="(costValue, costValueIndex) in group.values"
            :key="`cost_${costValueIndex}`"
            dense
          >
            <v-col cols="6" sm="3" md="2">{{ getCostLabel(costValueIndex, costValue) }}</v-col>

            <v-col cols="6" sm="9" md="10">
              {{ getHumanAmount(costValue) }}

              <small v-if="isAmountPercentType(costValue.amountType)" class="text--secondary">
                ({{ getAmountHint(costValue.cost) }}/час)
              </small>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="1">
          <v-btn icon color="primary" class="mb-2" @click="$emit('edit', group.id)">
            <v-icon small class="mr-1">fas fa-pencil-alt</v-icon>
          </v-btn>

          <v-btn icon color="red lighten-3" @click="$emit('delete', group.id)">
            <v-icon small class="mr-1">fas fa-trash-alt</v-icon>
          </v-btn>
        </v-col>

        <v-col v-if="groupIndex < items.length - 1" cols="12">
          <v-divider />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CostConstants from '@/packages/order/inventory/cost.constants';
import DayHelper from '@/common/helpers/day.helper';
import DateTimeConstants from '@/common/constants/datetime.constants';
import StringHelper from '@/common/helpers/string.helper';
import CostHelper from '@/common/helpers/cost.helper';
import TimeHelper from '@/common/helpers/time.helper';

export default {
  name: 'HourlyPriceView',

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  computed: {
    sortedItems() {
      const items = [...this.items];

      items.sort((a, b) => {
        return a.day - b.day || TimeHelper.timeToInt(a.timeFrom) - TimeHelper.timeToInt(b.timeFrom);
      });

      return items;
    },

    isHourlyType() {
      return this.costType === CostConstants.TYPE_HOURLY;
    },

    isDailyType() {
      return this.costType === CostConstants.TYPE_DAILY;
    },

    isManyDaysType() {
      return this.costType === CostConstants.TYPE_MANY_DAYS;
    },
  },

  methods: {
    getDayName(dayId) {
      return DayHelper.getDayName(dayId);
    },

    getCostLabel(index, costValue) {
      if (index === 0) {
        return 'Стоимость в час';
      }

      const hours = costValue.starts / DateTimeConstants.MINUTES_IN_HOUR;

      const label = StringHelper.declOfNum(hours, ['часа', 'часов', 'часов']);

      return `от ${hours} ${label}`;
    },

    getHumanAmount(costValue) {
      if (costValue.amountType === CostConstants.AMOUNT_TYPE_SUM) {
        return CostHelper.humanCost(costValue.amount);
      }

      const label = StringHelper.declOfNum(costValue.amount, ['процент', 'процента', 'процентов']);

      return `${costValue.amount} ${label}`;
    },

    isAmountPercentType(amountType) {
      return amountType === CostConstants.AMOUNT_TYPE_PERCENT;
    },

    getAmountHint(cost) {
      return CostHelper.humanCost(cost);
    },
  },
};
</script>

<style scoped lang="scss">
.price-view {
  &__title {
    font-size: 1rem;
    padding-bottom: 0;
  }
}
</style>
