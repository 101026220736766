<script>
import OrderActionsService from '@/packages/order/services/order-actions.service';
import EventBus from '@/common/lib/event-bus';

export default {
  name: 'OrderActionsMixin',

  data() {
    return {
      orderCreateState: {
        isActive: false,
        roomId: null,
        dateTime: null,
      },
    };
  },

  computed: {
    orderViewState() {
      return this.$store.getters['orderActionsStore/viewState'];
    },

    orderEditState() {
      return this.$store.getters['orderActionsStore/editState'];
    },

    orderCancelState() {
      return this.$store.getters['orderActionsStore/cancelState'];
    },

    orderApproveState() {
      return this.$store.getters['orderActionsStore/approveState'];
    },

    orderProlongState() {
      return this.$store.getters['orderActionsStore/prolongState'];
    },

    orderPayState() {
      return this.$store.getters['orderActionsStore/payState'];
    },
  },

  methods: {
    /*
    Create
     */
    async showOrderCreate({ roomId, dateTime }) {
      this.orderCreateState.roomId = roomId;
      this.orderCreateState.dateTime = dateTime;
      this.orderCreateState.isActive = true;
    },

    hideOrderCreate() {
      this.orderCreateState = {
        isActive: false,
        roomId: null,
        dateTime: null,
      };
    },

    /*
    View
     */
    async showOrderView(orderId) {
      await OrderActionsService.showOrderView(orderId);
    },

    async hideOrderView() {
      await this.$store.dispatch('orderActionsStore/hideOrderView');
    },

    /*
    Edit
     */
    async showEditOrderModal(orderId) {
      await OrderActionsService.showOrderEdit(orderId);
    },

    async hideEditOrderModal() {
      const orderId = this.orderEditState.order.id;
      await this.$store.dispatch('orderActionsStore/hideEditView');
      await OrderActionsService.showOrderView(orderId);
    },

    async hideEditOrderModalAndReloadOrders() {
      this.emitLoadOrdersEvent();

      await this.hideEditOrderModal();
    },

    /*
    Approve
     */
    async showApproveOrderModal(orderId) {
      await OrderActionsService.showOrderApprove(orderId);
    },

    async hideApproveOrderModal() {
      const orderId = this.orderApproveState.order.id;
      await this.$store.dispatch('orderActionsStore/hideApproveView');
      await OrderActionsService.showOrderView(orderId);
    },

    async hideApproveOrderModalAndReloadOrders() {
      this.emitLoadOrdersEvent();

      await this.hideApproveOrderModal();
    },

    /*
    Cancel
     */
    async showCancelOrderModal(orderId) {
      await OrderActionsService.showOrderCancel(orderId);
    },

    async hideCancelOrderModal() {
      const orderId = this.orderCancelState.order.id;
      await this.$store.dispatch('orderActionsStore/hideCancelView');
      await OrderActionsService.showOrderView(orderId);
    },

    async hideCancelOrderModalAndReloadOrders() {
      this.emitLoadOrdersEvent();

      await this.hideCancelOrderModal();
    },

    /*
    Prolong
     */
    async showProlongOrderModal(orderId) {
      await OrderActionsService.showOrderProlong(orderId);
    },

    async hideProlongOrderModal() {
      const orderId = this.orderProlongState.order.id;
      await this.$store.dispatch('orderActionsStore/hideProlongView');
      await OrderActionsService.showOrderView(orderId);
    },

    async hideProlongOrderModalAndReloadOrders() {
      this.emitLoadOrdersEvent();

      await this.hideProlongOrderModal();
    },

    /*
    Pay
     */
    async showPayOrderModal(orderId) {
      await OrderActionsService.showOrderPay(orderId);
    },

    async hidePayOrderModal() {
      const orderId = this.orderPayState.order.id;
      await this.$store.dispatch('orderActionsStore/hidePayView');
      await OrderActionsService.showOrderView(orderId);
    },

    async hidePayOrderModalAndReloadOrders() {
      this.emitLoadOrdersEvent();

      await this.hidePayOrderModal();
    },

    emitLoadOrdersEvent() {
      EventBus.$emit('orders-updated');
    },
  },
};
</script>
