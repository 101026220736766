export default {
  BONUS: 'BONUS',
  REFILL: 'REFILL',
  REFUND: 'REFUND',
  ORDER_CREATE: 'ORDER_CREATE',
  ORDER_CANCEL: 'ORDER_CANCEL',
  ORDER_INCREASE: 'ORDER_INCREASE',
  ORDER_DECREASE: 'ORDER_DECREASE',
  FINE: 'FINE',
  WITHDRAW: 'WITHDRAW',
  FEE: 'FEE',
};
