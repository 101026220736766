<template>
  <div>
    <p>Платежей: {{ items.length }} на сумму {{ amount }}</p>
  </div>
</template>

<script>
import CostHelper from '@/common/helpers/cost.helper';

export default {
  name: 'PaymentSummary',

  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    amount() {
      return CostHelper.humanCost(this.items.reduce((total, item) => total + item.amount, 0));
    },
  },
};
</script>
